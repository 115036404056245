import React from 'react';
import casestudybanner from '../../../assets/images/case-studies/case-study-main-banner.png';
import productabstract from '../../../assets/images/products/products-abstract.png';
import expense from '../../../assets/images/products/expence.png';

const Expenseinbox = () => {
  return (
    <div className="">
      <div
        className="d-flex justify-content-center align-items-center banner-image"
        style={{
          background: `url(${casestudybanner}) no-repeat center`,
          backgroundSize: 'cover',
          height: '430px',
        }}
      >
        <div className="d-flex flex-column justify-content-center align-items-center">
          <h3 className="text-white"style={{fontWeight:'bold'}}>EXPENSE iNBOX</h3>
        </div>
      </div>
      <div style={{ overflow: 'hidden', position: 'relative' }}>
        <img 
          src={productabstract} 
          alt="shape" 
          style={{ 
            position: 'absolute', 
            overflow: 'clip', 
            left: '-3%', 
            top: '0%' 
          }} 
        />
        <div className="mt-50 mb-50 container d-flex justify-content-start align-items-start">
          <div className="col-lg-4 col-md-12 justify-content-center align-items-center imgDiv">
            <img 
              src={expense} 
              className="img-fluid" 
              style={{ height: '23rem' }} 
            />
          </div>
          <div className="col-lg-8 col-md-12">
            <p
              className="text-dark text-justify mb-30"
              style={{ 
                lineHeight: '2.5rem', 
                fontWeight: 500 
              }}
            >
              EXPENSE iNBOX is a highly scalable, out of the box EXPENSE reporting
              solution that delivers powerful insight into company and departmental
              spending through automated data analysis. Designed and built by Boston
              Financial, EXPENSE iNBOX benefits from the vast experience and in-depth
              understanding of corporate accounts and reporting.
            </p>
            <div className="navbar-btn contactUs-btn cursor-pointer">
              <a 
                className="text-white btn d-sm-mobile rounded contactBtn" 
                style={{ background: '#072F5F' }} 
                href="/under-development"
              >
                Learn More
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Expenseinbox;
