import React from 'react';
import casestudy from '../../../assets/images/case-studies/case-study-main-banner.png';
import vc from '../../../assets/images/case-studies/vc.png';

const VCfirmcasestudy = () => (
  <>
    <style jsx>{`
      h4 {
        font-size: 18px;
      }
      h3 {
        font-size: 28px;
      }
      span {
        font-size: 16px;
      }
      p {
        font-size: 16px;
      }
      li {
        font-size: 16px;
        font-weight: 500;
      }
      .mainCard {
        height: 20rem;
      }
      .circle {
        width: 100px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        overflow: hidden;
      }
      i {
        color: #072f5f;
      }
      ul {
        padding-left: 17px;
      }
      @media (max-width: 574px) {
        .mTop {
          margin-top: 30px;
        }
        p {
          font-size: 18px;
        }
        .img-fluid {
          min-height: 100px;
        }
      }
      @media (min-width: 992px) {
        /* for banner image */
        .bmTop {
          margin-top: 130px;
        }
      }
    `}</style>

    <section className=""

    >
      <div
        className="d-flex justify-content-center align-items-center banner-image"
        style={{
          background: `url(${casestudy}) no-repeat center`,
          backgroundSize: 'cover',
          height: '430px'
        }}
      >
        <div className="d-flex flex-column justify-content-center align-items-center">
          <h3 className="text-white text-uppercase text-center"style={{fontWeight:'bold'}}>
            VC Firm Case <br />
            Study
          </h3>
        </div>
      </div>
      <div className="container-fluid">
        <div className="d-flex row justify-content-center align-items-start pl-4 pr-4" style={{ gap: '2rem' }}>
          <div className="col-lg-8 mt-50 mb-50 border shadow" style={{ padding: '2rem' }}>
            <div className="d-flex flex-column justify-content-start align-items-start">
              <img
                className="img-fluid"
                src={vc}
                alt=""
                style={{ marginBottom: '2rem' }}
              />
              <div className="d-flex flex-column justify-content-start align-items-start">
                <div
                  style={{
                    background: '#072f5f',
                    display: 'inline',
                    width: 'fit-content',
                    padding: '0.2rem 1rem 0.2rem 1rem'
                  }}
                >
                  <small className="text-uppercase text-white fw-bold" style={{ fontSize: '15px' }}>
                    Case Details
                  </small>
                </div>
                <div>
                  <div className="mt-15">
                    <span className="text-capitalize fw-bold" style={{ fontSize: '1.5rem', color: '#002249' }}>
                      Client's Challenges
                    </span>
                  </div>
                  <p className="text-dark servicep">
                    The challenges that were faced by the client were:
                  </p>
                  <ul className="text-dark servicep">
                    <li>Recruiting and retaining qualified resource</li>
                    <li>Timely & quality delivery</li>
                    <li>Administrative time & control mechanism</li>
                    <li>Providing value added services to the client</li>
                    <li>Messed up financials</li>
                    <li>Delay in making Business decisions by the Management</li>
                    <li>Investing more time in Accounting, bookkeeping, payroll & Tax return preparation</li>
                  </ul>
                </div>
                <div>
                  <div className="mt-15">
                    <span className="text-capitalize fw-bold" style={{ fontSize: '1.5rem', color: '#002249' }}>
                      Client
                    </span>
                  </div>
                  <p className="text-dark servicep">
                    Venture Capitalist Firm from Massachusetts
                  </p>
                </div>
                <div>
                  <div className="mt-15">
                    <span className="text-capitalize fw-bold" style={{ fontSize: '1.5rem', color: '#002249' }}>
                      BFAG Support Solution
                    </span>
                  </div>
                  <ul className="text-dark servicep">
                    <li>Accurate Books of Accounts</li>
                    <li>Effective use of technology</li>
                    <li>Help in Business diversification</li>
                    <li>Support for global Business expansion</li>
                    <li>Management information system helped to take smart & faster decision</li>
                  </ul>
                </div>
                <div>
                  <div className="mt-15 d-flex flex-column justify-content-start align-items-start">
                    <span className="text-capitalize fw-bold" style={{ fontSize: '1.5rem', color: '#002249' }}>
                      Impact on Client's Business
                    </span>
                  </div>
                  <ul className="text-dark servicep">
                    <li>No longer has the client had to worry about hiring and retaining the talent</li>
                    <li>Significant savings in payroll & administrative cost</li>
                    <li>Control mechanism is in place</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
);

export default VCfirmcasestudy;
