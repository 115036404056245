import React from "react";
import cpasolution1 from "../../../assets/images/CPA back-office/CPAsolutions1.png";
import tax1 from "../../../assets/images/CPA back-office/CPA2.png";

const Card = ({ text }) => {
  return (
    <div className="card-item" style={styles.card}>
      <span style={styles.icon}>🖉</span> {text}
    </div>
  );
};

const Taxreturnpreparation = () => {
  const cardData = [
    "Form 1040 U.S. Individual Tax Returns",
    "Foreign Partnership (Form 8865)",
    "Form 1065 U.S. Partnership Tax Returns",
    "Foreign Trust (Form 3520)",
    "Form 1120 U.S Corporate Tax Returns",
    "Form 5472 / 5471",
    "Form 1120S U.S. S Corporation Tax Returns",
    "Form 1116/2555",
    "Form 1041 U.S. Estate and Trusts Tax Returns",
    "FAS 109 and FIN 48",
    "Form 990 & 990EZ U.S. Exempt organizations Returns",
    "FinCEN 114 (FBAR)",
    "Foreign Corporation (Form 1120F)",
  ];

  return (
    <>
      <div
        className="d-flex justify-content-center align-items-center banner-image"
        style={{
          background: `url(${cpasolution1}) no-repeat center`,
          backgroundSize: "cover",
          height: "430px",
        }}
      >
        <div className="d-flex flex-column justify-content-center align-items-center">
          <h3
            className="text-white text-uppercase"
            style={{ fontWeight: "bold" }}
          >
            Tax Preparation
          </h3>
        </div>
      </div>

      <div className="container">
        <div className="row" style={{ marginTop: "5%", marginBottom: "4%" }}>
          <p
            className="text-dark"
            style={{
              marginBottom: "3%",
              padding: "0px 15px 0px",
              textAlign: "justify",
              fontWeight: "500",
            }}
          >
            We offer comprehensive back-office services specifically designed
            for Certified Public Accountants (CPAs) and accounting firms. Our
            goal is to support your practice by taking care of essential and
            routine tasks, allowing you to focus on providing high-quality
            services to your clients.
          </p>
          <div className="col-lg-5 justify-content-center align-items-center imgDiv">
            <div className="d-flex justify-content-start align-items-start">
              <img
                src={tax1}
                className="img-fluid"
                style={{ maxWidth: "400px" }}
              />
            </div>
          </div>
          <div style={styles.container}>
            <h2 style={styles.header}>Tax Compliance and Preparation</h2>

            <li style={styles.listItem}>
              <strong>Individual and business tax returns:</strong> We assist in
              the preparation and filing of tax returns for individuals,
              corporations, partnerships, and other entities.
            </li>
            <li style={styles.listItem}>
              <strong>Tax planning and consulting:</strong> Our experts provide
              tax planning strategies to optimize your clients' tax positions
              and minimize their tax liabilities.
            </li>
            <li style={styles.listItem}>
              <strong>IRS correspondence:</strong> We handle communication with
              the IRS on behalf of your clients, addressing inquiries, notices,
              and audits.
            </li>
          </div>
          <h4
            style={{
              marginTop: "3%",
              // marginBottom: "2%",
              paddingLeft: "1%",
              fontSize: "18px",
              fontWeight: "bold",
              color: "#072f5f",
            }}
          >
            Return Types
          </h4>
        </div>

        {/* Card Grid */}
        <div className="card-grid" style={styles.grid}>
          {cardData.map((text, index) => (
            <Card key={index} text={text} />
          ))}
        </div>
      </div>

      {/* Internal CSS styles */}
      <style>
        {`
          .imgBtn {
            z-index: 1;
            background: #21ac51;
            padding: 5px 20px;
            border-radius: 20px;
            position: absolute;
            top: 17rem;
          }

          .imgBtn-india {
            z-index: 1;
            background: #0951A4;
            padding: 5px 20px;
            border-radius: 20px;
            position: absolute;
            top: 17rem;
          }

          p {
            font-weight: 500 !important;
          }

          h1 {
            font-size: 48px !important;
          }

          h4 {
            font-size: 18px;
          }

          h3 {
            font-size: 28px;
          }

          .subSpan {
            font-size: 18px !important;
            color: #072F5F !important;
            font-weight: 600;
          }

          .fa-magic {
            padding-left: 2%;
            padding-right: 2%;
          }

          .faq-accordion-2 .accordion .card .card-header a.collapsed::before {
            content: '\\fff' !important;
          }

          @media (max-width: 576px) {
            .card-grid {
              flex-direction: column !important; /* Stack items vertically on mobile */
            }

            .card-item {
              flex: 1 1 100% !important; /* Full width on mobile */
              max-width: 100% !important; /* Full width on mobile */
            }
          }

          @media (min-width: 280px) {
            li {
              font-size: 16px;
            }
            p {
              font-size: 16px;
            }
            .col-lg-7 {
              padding-right: 4% !important;
            }
          }

          @media (min-width: 357px) {
            li {
              font-size: 16px;
            }
            p {
              font-size: 16px;
            }
            .col-lg-7 {
              padding-right: 4% !important;
            }
          }

          @media (min-width: 570px) {
            .mTop {
              margin-top: 100px;
            }
            li {
              font-size: 18px !important;
            }
            p {
              font-size: 18px !important;
            }
            .col-lg-7 {
              padding-right: 4% !important;
            }
          }

          @media (min-width: 767px) {
            .mTop {
              margin-top: 100px;
            }
            p {
              font-size: 18px !important;
            }
            li {
              font-size: 18px !important;
            }
          }

          @media (min-width: 976px) {
            .mTop {
              margin-top: 100px;
            }
            li {
              font-size: 16px !important;
            }
            p {
              font-size: 16px !important;
            }
          }

          @media (max-width: 997px) {
            .imgDiv {
              display: none;
            }
          }
        `}
      </style>
    </>
  );
};

const styles = {
  grid: {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
    justifyContent: "space-between",
    padding: "20px",
    backgroundColor: "#f0f8ff",
  },
  card: {
    flex: "1 1 45%", // Two cards in a row on desktop
    maxWidth: "45%",
    height: "50px",
    backgroundColor: "white",
    borderRadius: "10px",
    padding: "10px 20px",
    boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
    display: "flex",
    alignItems: "center",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#7a95b4",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  },
  icon: {
    marginRight: "10px",
  },
  container: {
    color: "#333",
    padding: "20px",
    maxWidth: "600px",
  },
  header: {
    fontSize: "21px",
    color: "#2A4365",
    fontWeight: "600",
  },
  list: {
    listStyleType: "disc",
    paddingLeft: "20px",
  },
  listItem: {
    marginBottom: "10px",
    fontSize: "18px",
    fontWeight: "normal",
  },
};

export default Taxreturnpreparation;
