import React from 'react'
import blogs from '../../assets/images/blogs/blog.png'
import videoBackground from "../../assets/video/boston baneer video.mp4";

const Endpointsecuritypage = () => {
  return (
    <>
    <style>{`
    .main-color {
        color: #343a40;
    }
    `}
</style>

    <div>
      {/* Inner Banner */}
      {/* <div
            className="banner-wrapper"
            style={{ position: "relative", height: "450px" }}
          >
            <video
              className="banner-video"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              src={videoBackground}
              autoPlay
              loop
              muted
            />
            <div
              className="banner-overlay"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <div className="container-fluid trdBannar h-100">
                <div className="row h-100 d-flex align-items-center">
                  <div className="col-lg-6">
                    <div className="banner-content text-white">
                      <h1
                        className="title"
                        style={{
                          animation: "fadeInLeft 0.5s",
                          animationDelay: "0.5s",
                          fontWeight: "bold",
                          color: "#fff",
                        }}
                      >
                        <a  style={{fontSize:'30px' , justifyContent:'center'}}>End Point security</a>
                        <br />
                      </h1>
                      <li
                        className="pt-5 "
                        style={{
                          listStyleType: "none",
                          animation: "fadeInLeft 1.3s",
                          animationDelay: "1.3s",
                        }}
                      >
                       
                      </li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

      <div
          className="d-flex justify-content-center align-items-center banner-image"
          style={{
            background: `url(${blogs}) no-repeat center`,
            backgroundSize: "cover",
            height: "430px",
          }}
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h3
              className="text-white text-uppercase"
              style={{ fontWeight: "bold" }}
            >
            END POINT SECURITY
            </h3>
          </div>
        </div>

      {/* Inner Banner End */}

      <div className="privacy-policy-area pt-100 pb-70">
        <div className="container">
          <div className="single-content main-color">
            
            <p>
              <strong>Disk Encryption</strong>
            </p>
            <p style={{fontWeight:'400'}}>
            To protect the confidentiality and integrity of information stored on all BFAG endpoints, BFAG mandates full-disk encryption. Additionally, we continuously monitor endpoint security signals to promptly identify and investigate any anomalous activity.            </p>
            
            {/* <p>&nbsp;</p> */}
            <p>
              <strong>DNS Filtering</strong>
            </p>
            <p style={{fontWeight:'400'}}>
            To enhance endpoint security, BFAG implements DNS filtering mechanisms that block access to malicious web traffic. This preventive measure is complemented by regular monitoring.
            </p>
            {/* <p>&nbsp;</p> */}
            <p>
              <strong>Endpoint Detection & Response</strong>
            </p>
            <p style={{fontWeight:'400'}}>
            All employee endpoints are protected with an advanced EDR solution. Endpoint security signals are monitored regularly for anomalous activity.
            </p>
            {/* <p>&nbsp;</p> */}
            <p>
              <strong>Threat Detection</strong>
            </p>
            <p style={{fontWeight:'400'}}>
            BFAG's IT Team actively monitors the environment for known attacker tactics, techniques, and procedures (TTPs), as well as known malicious binaries and other suspicious activities. These regular activities are complemented by periodic reviews and investigations into anomalous activities to discover unknown threats occurring on a regular cadence. 
            </p>
                 
            {/* <p>&nbsp;</p> */}

          </div>
        </div>
      </div>
      
    </div>
    </>
  )
}

export default Endpointsecuritypage