import React, { useState } from "react";
import casestudybanner from "../../../assets/images/case-studies/case-study-main-banner.png";
import productabstract from "../../../assets/images/products/products-abstract.png";
import Analytics from "../../../assets/images/products/analytics.png";
import expense from "../../../assets/images/products/expence.png";
import complience from "../../../assets/images/products/complience.png";
import { Link } from "react-router-dom";

const Analyticsinbox = () => {
  const [isHovered, setIsHovered] = useState(false);

  const buttonStyle = {
    background: isHovered ? "black" : "#072F5F",
    color: isHovered ? "white" : "white",
    transition: "background-color 0.3s ease",
    padding: "10px 20px",
    borderRadius: "5px",
    textDecoration: "none",
    textAlign: "center",
    display: "inline-block",
  };

  return (
    <div className="">
      <style>{`
        @media (max-width: 768px) {
          .content-section {
            display: flex;
            flex-direction: column; /* Stack content and image vertically */
          }

          .content-section img {
            margin-top: 15px; /* Add space between content and image */
          }

          .row {
            display: flex;
            flex-direction: column; /* Stack rows vertically in mobile */
          }
        }
      `}</style>
      <div
        className="d-flex justify-content-center align-items-center banner-image"
        style={{
          background: `url(${casestudybanner}) no-repeat center`,
          backgroundSize: "cover",
          height: "430px",
        }}
      >
        <div className="d-flex flex-column justify-content-center align-items-center">
          <h3
            className="text-white text-uppercase"
            style={{ fontWeight: "bold" }}
          >
            Products
          </h3>
        </div>
      </div>

      {/* First Section */}
      <div className="mt-50 mb-50 container content-section">
        <div className="row">
          <div className="col-lg-4 col-md-12 justify-content-center align-items-center imgDiv">
            <img
              src={complience}
              className="img-fluid"
              style={{ height: "23rem" }}
              alt="Analytics Image"
            />
          </div>
          <div className="col-lg-8 col-md-12">
            <p
              className="text-dark mb-30 text-justify"
              style={{
                lineHeight: "2.5rem",
                fontWeight: 500,
              }}
            >
              COMPLIANCE INBOX is an intuitive solution developed to address
              this very pressing need to simplify COMPLIANCE management across
              the organization. Developed by a team of practicing CAs, CPAs, and
              financial stalwarts from Boston Financial Advisory Group (BFAG),
              COMPLIANCE iNBOX brings to you the promise of timely and accurate
              COMPLIANCE without the typical cost and hassle associated with it.
            </p>
            <div className="navbar-btn contactUs-btn cursor-pointer">
              <Link
                to="https://complianceinbox.com/"
                target="_blank"
                className="text-white btn d-sm-mobile rounded contactBtn"
                style={buttonStyle}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Second Section */}
      <div className="container content-section">
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <p
              className="text-dark text-justify mb-30"
              style={{
                lineHeight: "2.5rem",
                fontWeight: 500,
              }}
            >
              EXPENSE INBOX is a highly scalable, out-of-the-box EXPENSE
              reporting solution that delivers powerful insight into company and
              departmental spending through automated data analysis. Designed
              and built by Boston Financial, EXPENSE iNBOX benefits from the
              vast experience and in-depth understanding of corporate accounts
              and reporting.
            </p>
            <div className="navbar-btn contactUs-btn cursor-pointer">
              <Link
                to="https://complianceinbox.com/"
                target="_blank"
                className="text-white btn d-sm-mobile rounded contactBtn"
                style={buttonStyle}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                Learn More
              </Link>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 d-flex justify-content-center align-items-start imgDiv">
            <img
              src={expense}
              className="img-fluid"
              style={{ height: "23rem" }}
              alt="Expense Image"
            />
          </div>
        </div>
      </div>

      {/* Third Section */}
      <div className="mt-30 mb-50 container content-section">
        <div className="row">
          <div className="col-lg-4 col-md-12 justify-content-center align-items-center imgDiv">
            <img
              src={Analytics}
              className="img-fluid"
              style={{ height: "23rem" }}
              alt="Complience Image"
            />
          </div>
          <div className="col-lg-8 col-md-12">
            <p
              className="text-dark mb-30 text-justify"
              style={{
                lineHeight: "2.5rem",
                fontWeight: 500,
              }}
            >
              ANALYTICS INBOX gives Information at the click of a button with 24
              / 7 availability from anywhere, Birds eye view of the financial
              position of the enterprise, Usual parameters such as receivables,
              parable's, statutory Dues, Segment wise profitability, Smartness
              built into it so that payment authorization can be done, Complete
              picture in one screen, Working Capital Management, Customizable to
              suit individual requirements.
            </p>
            <div className="navbar-btn contactUs-btn cursor-pointer">
              <Link
                to="https://complianceinbox.com/"
                target="_blank"
                className="text-white btn d-sm-mobile rounded contactBtn"
                style={buttonStyle}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analyticsinbox;
