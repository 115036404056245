import React from 'react';
import cpa from '../../../assets/images/case-studies/CPA.png'
import casestudy from '../../../assets/images/case-studies/case-study-main-banner.png'

const CPACaseStudy = () => {
  return (
    <section className=""
 
    >
      <div
        className="d-flex justify-content-center align-items-center banner-image"
        style={{
          background: `url(${casestudy}) no-repeat center`,
          backgroundSize: 'cover',
          height: '430px'
        }}
      >
        <div className="d-flex flex-column justify-content-center align-items-center">
          <h3 className="text-white text-uppercase text-center" style={{fontWeight:'bold'}}>
            CPA Case <br />
            Study
          </h3>
        </div>
      </div>
      <div className="container-fluid">
        <div className="d-flex row justify-content-center align-items-start pl-4 pr-4" style={{ gap: '2rem' }}>
          <div className="col-lg-8 mt-50 mb-50 border shadow" style={{ padding: '2rem' }}>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <img
                className="img-fluid"
                src={cpa}
                alt=""
                style={{ marginBottom: '2rem' }}
              />
              <div>
                <div
                  style={{
                    background: '#072f5f',
                    display: 'inline',
                    width: 'fit-content',
                    padding: '0.2rem 1rem 0.2rem 1rem'
                  }}
                >
                  <small className="text-uppercase text-white" style={{ fontWeight: 600, fontSize: '15px' }}>
                    case details
                  </small>
                </div>
                <div>
                  <div className="mt-15">
                    <span
                      className="text-capitalize"
                      style={{
                        fontSize: '1.5rem',
                        fontWeight: 700,
                        color: '#002249'
                      }}
                    >
                      overview
                    </span>
                  </div>
                  <p className="text-dark servicep" >
                    When a Business expands its operations, managing data & documents emerge as one of the major
                    problem. A Business also need to identify the areas that need to be reworked such as cutting the cost
                    in certain field and adding more to another & It is possible to establish the long-term goals in
                    Business when you outsource some of the back-end accounting operations. By outsourcing all your
                    routine accounting functions, you’re able to focus on managing and growing your business.
                  </p>
                </div>
                <div>
                  <div className="mt-15">
                    <span
                      className="text-capitalize"
                      style={{
                        fontSize: '1.5rem',
                        fontWeight: 700,
                        color: '#002249'
                      }}
                    >
                      Client
                    </span>
                  </div>
                  <ul className="text-dark servicep" style={{ listStyleType: 'disc' }}>
                    <li>CPA firm in Florida</li>
                  </ul>
                </div>
                <div>
                  <div className="mt-15">
                    <span
                      className="text-capitalize"
                      style={{
                        fontSize: '1.5rem',
                        fontWeight: 700,
                        color: '#002249'
                      }}
                    >
                      Client's Challenges
                    </span>
                  </div>
                  <ul className="text-dark servicep" style={{ listStyleType: 'disc' }}>
                    <li>Recruiting and retaining qualified resource for tax return preparation</li>
                    <li>Timely & quality delivery</li>
                    <li>Reducing the overall payroll cost</li>
                    <li>Administrative time & control mechanism</li>
                    <li>Providing value added services to the client</li>
                  </ul>
                </div>
                <div>
                  <div className="mt-15">
                    <span
                      className="text-capitalize"
                      style={{
                        fontSize: '1.5rem',
                        fontWeight: 700,
                        color: '#002249'
                      }}
                    >
                      BFAG Back Office Services
                    </span>
                  </div>
                  <ul className="text-dark servicep" style={{ listStyleType: 'disc' }}>
                    <li>Providing & maintaining a steady qualified resource pool</li>
                    <li>
                      Drafting & implementing a process for activities such as tax return preparation, accounting,
                      compilation of financial statements
                    </li>
                    <li>
                      Ensuring that a control mechanism is in place there by the client good exercise control from anywhere
                      & at all times
                    </li>
                  </ul>
                </div>
                <div>
                  <div className="mt-15">
                    <span
                      className="text-capitalize"
                      style={{
                        fontSize: '1.5rem',
                        fontWeight: 700,
                        color: '#002249'
                      }}
                    >
                      Impact on Client Business
                    </span>
                  </div>
                  <ul className="text-dark servicep" style={{ listStyleType: 'disc' }}>
                    <li>
                      No longer has the client had to worry about hiring and retaining the talent
                    </li>
                    <li>Significant savings in payroll & administrative cost</li>
                    <li>Timely & quality delivery</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CPACaseStudy;
