import React from 'react';
import { Button, Container, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom'; // Import Link for routing
import backgroundImage from './assets/images/banner/bs2.jpg';

const Tagline = () => {
  
  return (
    <Box
      sx={{
        position: 'relative',
        py: 5,
        textAlign: 'center',
        color: 'white',
        height: '30vh',
        marginBottom: '15px',
        marginTop:'15px',
        overflow: 'hidden',  // Ensure child elements don't overflow the parent
        '::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          opacity: 0.6, // Adjust opacity here
          zIndex: 1,
        },
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%', 
          backgroundColor: 'rgba(0, 0, 0, 0.6)',  // Adjust overlay opacity
          zIndex: 2,
        }}
      />
      
      <Container 
        maxWidth="md" 
        sx={{
          position: 'relative', 
          zIndex: 3
        }}
      >
        <Typography variant="h5" component="h4" sx={{ fontWeight: 'bold', fontSize: '20px', color: 'white' }}>
        {/* Innovative Financial Strategies */}
        </Typography>
        <Typography variant="h5" sx={{ mt: 2, mb: 3, fontSize: '22px', fontWeight:'bold' }}>
        Your Trusted Partner in Accounting Excellence
        </Typography>
        <Link to="/contactus" style={{ textDecoration: 'none' }}>
          <Button 
            variant="contained" 
            color="primary" 
            size="medium" 
            sx={{
              backgroundColor: 'rgb(7, 47, 95)',
              '&:hover': {
                backgroundColor: 'black',
                color: 'white'
              },
            }}
          >
            Contact us now
          </Button>
        </Link>
      </Container>
    </Box>
  );
};

export default Tagline;
