import React, { useState } from "react";
import casestudy from "../../assets/FlipImages/1.png";
import it from "../../assets/FlipImages/1.png";
import {
  TextField,
  Button,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import blog13 from "../../assets/images/blogs/blog13.jfif";
import latestblog from "../../assets/images/blogs/latestblogimg1.jpeg";
import blogee1 from '../../assets/images/blogs/blogee1.jpg'
import blogee2 from '../../assets/images/blogs/blogee2.jpg'
import blogee3 from '../../assets/images/blogs/blogee3.jpg'
import blogee4 from '../../assets/images/blogs/blogee4.jpg'
import blogee5 from '../../assets/images/blogs/blogee5.jpg'

const Supremecourtdefersfundamental = () => {
  const [comment, setComment] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [saveInfo, setSaveInfo] = useState(false);
  const [comments, setComments] = useState([]);

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleWebsiteChange = (event) => {
    setWebsite(event.target.value);
  };

  const handleSaveInfoChange = (event) => {
    setSaveInfo(event.target.checked);
  };

  const handleCommentSubmit = () => {
    if (comment.trim() && name.trim() && email.trim()) {
      setComments([...comments, { comment, name, email, website }]);
      setComment("");
      setName("");
      setEmail("");
      setWebsite("");
      setSaveInfo(false);
    }
  };

  return (
    <>
      <style jsx>{`
        h4 {
          font-size: 18px;
        }
        h3 {
          font-size: 28px;
        }
        span {
          font-size: 16px;
        }
        p {
          font-size: 16px;
        }
        li {
          font-size: 16px;
          font-weight: 500;
        }
        .mainCard {
          height: 20rem;
        }
        .circle {
          width: 100px;
          height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          overflow: hidden;
        }
        i {
          color: #072f5f;
        }
        ul {
          padding-left: 17px;
        }
        @media (max-width: 574px) {
          .mTop {
            margin-top: 30px;
          }
          p {
            font-size: 18px;
          }
          .img-fluid {
            min-height: 100px;
          }
        }
        @media (min-width: 992px) {
          /* for banner image */
          .bmTop {
            margin-top: 130px;
          }
        }
      `}</style>

      <section className="">
        <div
          className="d-flex justify-content-center align-items-center banner-image"
          style={{
            background: `url(${casestudy}) no-repeat center`,
            backgroundSize: "cover",
            height: "200px",
          }}
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h3
              className="text-white text-uppercase text-center"
              style={{ fontWeight: "bold" }}
            >
              Supreme Court Defers Fundamental Constitutional Question in
              Upholding <br /> Tax on Foreign Business Income
            </h3>
          </div>
        </div>
        <div className="container-fluid">
          <div
            className="d-flex row justify-content-center align-items-start pl-4 pr-4"
            style={{ gap: "2rem" }}
          >
            <div
              className="col-lg-8 mt-50 mb-50 border shadow"
              style={{ padding: "2rem" }}
            >
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div>
                  <div
                    style={{
                      background: "#072f5f",
                      display: "inline",
                      width: "fit-content",
                      padding: "0.2rem 1rem 0.2rem 1rem",
                    }}
                  >
                    <small
                      className="text-uppercase text-white"
                      style={{ fontWeight: "600", fontSize: "15px" }}
                    >
                      Introduction
                    </small>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "18px",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      ></span>
                    </div>
                    <p
                      className="text-dark servicep"
                      style={{ textAlign: "justify" }}
                    >
                      In a landmark decision, the U.S. Supreme Court today ruled
                      that the Mandatory Repatriation Tax (MRT) is
                      constitutional. However, it avoided addressing what many
                      observers believed to be an even more fundamental
                      constitutional question: what constitutes taxable income?
                    </p>
                    <p
                      className="text-dark servicep"
                      style={{ textAlign: "justify" }}
                    >
                      This ruling is based on the case of Charles G. Moore v.
                      United States, and the implications are huge for the U.S.
                      tax law and policy. MRT is a one-time tax on accumulated
                      but undistributed foreign earnings of certain U.S.-owned
                      foreign corporations enacted in the Tax Cuts and Jobs Act
                      of 2017.
                    </p>
                    <p
                      className="text-dark servicep"
                      style={{ textAlign: "justify" }}
                    >
                      The petitioners, Charles and Kathleen Moore, contended
                      that this tax offended the Sixteenth Amendment in that it
                      taxed income realized but not distributed. Judgment of the
                      Court.
                    </p>
                    <p
                      className="text-dark servicep"
                      style={{ textAlign: "justify" }}
                    >
                      The Supreme Court ruled 7-2 in favour of the Moores,
                      reversing the lower court’s judgment, but the opinion left
                      some questions unresolved.
                    </p>
                    <p
                      className="text-dark servicep"
                      style={{ textAlign: "justify" }}
                    >
                      Issues: Narrow Opinion - The majority opinion by Justice
                      Kavanaugh was careful not to determine whether the
                      Constitution requires income to be "realized" before it
                      can be taxed. Focus on Precedent. 
                    </p>
                    <p
                      className="text-dark servicep"
                      style={{ textAlign: "justify" }}
                    >
                     The Court referenced its
                      precedent, where an entity's realized and undistributed
                      income is subject to apportionment among shareholders for
                      taxation.
                    </p>
                  </div>

                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Comparison with Other Tax Forms:
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc" }}
                    >
                      <li>
                      The Court had to come up with analogies comparing the MRT with other forms of taxes that were well-known to the tax authorities. Those included taxation under partnership schemes and Subpart F income subjecting controlled foreign corporations. Implications and Future Considerations. As the Court resolved the question by determining that the MRT did not offend the Constitution, there arose fundamental questions as to what constituted constitutional restrictions in the sphere of income tax.
                      </li>
                    </ul>
                  </div>
                
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                       The unresolved Constitutional issue: 
                      </span>
                    </div>
                  </div>
                  <ul
                    className="text-dark servicep"
                    // style={{ listStyleType: "disc", textAlign: "justify" }}
                  >
                    <li>
                    The Court simply declined to raise the only question of substance-that realization is a constitutional prerequisite to tax. The door remained open for further future attacks to the form of tax used in taxing unrealized income. Potential Future Challenges Four justices wrote opinions expressing judicial acceptance of the requirement that realization be constitutionally enforceable. This suggests that future cases could potentially shift the understanding of Congress's taxing power. The ruling impacts tax policy in the sense that it preserves Congress's ability to enact certain forms of pass-through taxation; yet, it creates uncertainty around more novel forms of taxation, such as the proposed wealth taxes. 
                    </li>
                  </ul>

                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                       Conclusion:
                      </span>
                    </div>
                  </div>
                  <ul
                    className="text-dark servicep"
                    style={{ listStyleType: "disc", textAlign: "justify" }}
                  >
                    <li>
                    The Supreme Court's decision in Moore v. United States is a significant victory for the government in upholding the MRT. While thus avoiding truly more exhaustive constitutional analysis, though, the Court has thereby ensured that debate over congressional taxing power's reach continues. Tax lawyers, legislators and constitutional law specialists alike await decisions forth-coming by which questions in this connection remain undetermined. Foreign commerce and taxation will certainly henceforth continue; this decision lends special focus to the tension between legislation attempting to realize overseas-earned income and Constitutionally-guaranteed interdictions of taxation.
                    </li>
                  </ul>

                  <div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <li>
                      Only time will tell how the courts, in the future, might address these sorts of issues in an ever-growing internationalized economy.
                      </li>
                      <li>
                      Given these intricacies in tax changes, it is high time businesses were given the most current, extensive financial direction. Boston Financial Advisory Group (BFAG) has complete solutions that provide easy management and navigation in the complexities of this international tax arena and its financial management. 
                      </li>
                    </ul>
                  </div>
                  <img
                    src={blogee1}
                    style={{
                      display: "block",
                      margin: "1rem auto",
                      width: "500px",
                      height: "300px",
                    }}
                  />
                  
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                       So why choose BFAG? 
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <li>
                      Expert Tax Planning: We are abreast of all the latest tax laws and court decisions to further sharpen your tax planning. 
                     </li>
                     <li>
                     Global Business Expertise: Building on our experience with worldwide business, we guide you through complex international tax situations. 
                   </li>
                     <li>
                     All-Encompassing Financial Solutions: We offer the entire suite of financial solutions-from accounting to consulting, valuation, and due diligence.                     </li>
                     <li>
                     Personalized Services: Our tailored services ensure your unique business needs are precisely met with precision and care.                      </li>
                    </ul>
                  </div>

                  <p
                    className="text-dark servicep"
                    style={{ textAlign: "justify" }}
                  >
                   Take Control of Your Finances Today: Let complicated tax legislation and financial jargon be the least of your problems in running your business forward. Partner with Boston Financial Advisory Group to enhance your financial operations and reach your company's full potential
                  </p>
                 
                  <Box
                    mt="2rem"
                    p="2rem"
                    border="1px solid #ddd"
                    borderRadius="8px"
                  >
                    <Typography variant="h5" gutterBottom>
                      Leave a Comment
                    </Typography>
                    <TextField
                      label="Message"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={4}
                      value={comment}
                      onChange={handleCommentChange}
                      margin="normal"
                    />
                    <Box display="flex" gap="1rem">
                      <TextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        value={name}
                        onChange={handleNameChange}
                        margin="normal"
                      />
                      <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        value={email}
                        onChange={handleEmailChange}
                        margin="normal"
                      />
                    </Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={saveInfo}
                          onChange={handleSaveInfoChange}
                        />
                      }
                      label="Save my name, email, and website in this browser for the next time I comment."
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCommentSubmit}
                      style={{ backgroundColor: "rgb(0, 34, 73)" }}
                    >
                      Send Comment
                    </Button>
                  </Box>
                  <Box mt="2rem">
                    {comments.map((cmt, index) => (
                      <Box
                        key={index}
                        mt="1rem"
                        p="1rem"
                        border="1px solid #ddd"
                        borderRadius="8px"
                      >
                        <Typography variant="body1">{cmt.comment}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {cmt.name} - {cmt.email}
                          {cmt.website && ` - ${cmt.website}`}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Supremecourtdefersfundamental;
