import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Header.css';

const Header = () => {
  const location = useLocation();
  const selectCountryName = 'India';

  const redirectToOtherPage = () => {
    // Redirection logic
  };

  const openDialog = () => {
    // Dialog logic
  };

  return (
    <div className="header">
      <header className={selectCountryName === 'India' ? 'header-area header-area-2 header-area-4' : ''}>
        <div className="header-top pl-30 pr-30 black-bg">
          <div className="row align-items-center">
            <div className="col-md-8 col-sm-7">
              <div className="header-left-side text-center text-sm-left">
                <ul>
                  <li>
                    <a href="mailto:info@bostonfagroup.com"><i className="fal fa-envelope"></i> info@bostonfagroup.com</a>
                  </li>
                  <li>
                    <a href="tel:+ 1-408-533-1050"><i className="fal fa-phone"></i> + 1-408-533-1050</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 col-sm-5">
              <div className="header-right-social text-center text-sm-right">
                <ul>
                  <li>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/BostonFinancialAdvisoryGroup/"><i className="fab fa-facebook-f"></i></a>
                  </li>
                  <li>
                    <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/BostonfaGroup"><i className="fab fa-twitter"></i></a>
                  </li>
                  <li>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/boston-financial-advisory-group/"><i className="fab fa-linkedin-in"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="header-nav india">
          <div className="navigation">
            <nav className="navbar navbar-expand-lg navbar-light">
              <Link className="navbar-brand" to="/" style={{ maxWidth: '216px' }}>
                <img src="/assets/images/BFAG_logo.png" alt="Frontline ERP Solutions" />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="toggler-icon"></span>
                <span className="toggler-icon"></span>
                <span className="toggler-icon"></span>
              </button>
              <div className="indiaToggler collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto">
                  <li className={`nav-item indiaItem ${location.pathname === '/about-us' ? 'active' : ''}`}>
                    <Link className="nav-link indiaLink" to="/about-us">About Us</Link>
                    <ul className="sub-menu scroll-navbar-mob">
                      <li><Link className="sub-menu-link" to="/why-bfag">Why BFAG</Link></li>
                      <li><Link className="sub-menu-link" to="/our-team">Our Team</Link></li>
                      <li><Link className="sub-menu-link" to="/mission-and-vision">Mission and Vision</Link></li>
                      <li><Link className="sub-menu-link" to="/client-speaks">Client Speaks</Link></li>
                      <li><Link className="sub-menu-link" to="/how-we-do-it">How We Do It</Link></li>
                    </ul>
                  </li>
                  <li className={`nav-item indiaItem ${location.pathname === '/services' ? 'active' : ''}`}>
                    <a className="nav-link indiaLink" onClick={redirectToOtherPage}>Services</a>
                    <ul className="sub-menu scroll-navbar-mob">
                      <li><Link className="sub-menu-link" to="/accounting-and-transition-processing">Accounting & Transaction Processing</Link></li>
                      <li><Link className="sub-menu-link" to="/tax-regulatory-compliances">Tax & Regulatory Compliances</Link></li>
                      <li><Link className="sub-menu-link" to="/payroll-advisory">Payroll Advisory</Link></li>
                      <li><Link className="sub-menu-link" to="/consulting-and-advisory-services">Consulting & Advisory</Link></li>
                      <li><Link className="sub-menu-link" to="/startup-solution-services">Startup Solution</Link></li>
                    </ul>
                  </li>
                  <li className={`nav-item ${location.pathname === '/cpa-back-office' ? 'active' : ''}`}>
                    <Link className="nav-link" to="/cpa-back-office">CPA Back-Office</Link>
                  </li>
                  <li className={`nav-item indiaItem ${location.pathname === '/products' ? 'active' : ''}`}>
                    <Link className="nav-link indiaLink" to="/products">Products</Link>
                    <ul className="sub-menu scroll-navbar-mob">
                      <li><Link className="sub-menu-link" to="/compliance-inbox">COMPLIANCE iNBOX</Link></li>
                      <li><Link className="sub-menu-link" to="/expense-inbox">EXPENSE iNBOX</Link></li>
                      <li><Link className="sub-menu-link" to="/analytics-inbox">ANALYTICS iNBOX</Link></li>
                    </ul>
                  </li>
                  <li className={`nav-item indiaItem ${location.pathname === '/info-centre' ? 'active' : ''}`}>
                    <Link className="nav-link indiaLink" to="/info-centre">Info Centre</Link>
                    <ul className="sub-menu scroll-navbar-mob">
                      <li><Link className="sub-menu-link" to="/newsletter">Newsletters</Link></li>
                      <li><Link className="sub-menu-link" to="/case-studies">Case Studies</Link></li>
                      <li><Link className="sub-menu-link" to="/careers">Careers</Link></li>
                      <li><Link className="sub-menu-link" to="/media-hits">Media Hits</Link></li>
                      <li><Link className="sub-menu-link" to="/blogs">Blogs</Link></li>
                    </ul>
                  </li>
                  <li className={`nav-item ${location.pathname === '/contact-us' ? 'active' : ''}`}>
                    <Link className="nav-link" to="/contact-us">Contact</Link>
                  </li>
                </ul>
              </div>
              <div className="navbar-btn mr-100 contactUs-btn">
                <a onClick={openDialog} className="text-white btn ml-5 rounded d-sm-mobile" style={{ background: '#072f5f' }}>Request For Info</a>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
