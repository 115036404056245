import React from 'react';
import exemeth from '../../../../assets/images/how we do it/ExeMeth.jpg';
import menthod from '../../../../assets/images/how we do it/method.png'

const ExecutionMethodology = () => {
  return (
    <div className="">
      <div className="container-fluid divmart d-flex flex-column justify-content-center align-items-center">
        <div className="row d-flex flex-column justify-content-center align-items-center">
          <div className="col-lg-12 d-flex flex-column justify-content-center align-items-center mt-4">
            <div>
              <h3
                className="text-center"
                style={{ borderBottom: '4px solid #072F5F', display: 'inline-block', color:'#072f5f', fontWeight:'bold' }}
              >
                EXECUTION METHODOLOGY
              </h3>
            </div>
            <div className="mb-5" style={{ marginTop: '3rem' }}>
              <img
                className="img-fluid"
                style={{ borderRadius: '15px',maxHeight: '30rem' }}
                src={menthod}
                alt="execution_methodology"
              />
            </div>
          </div>
          <div className="col-lg-12">
            <p className="text-dark text-justify">
              Preferably BFAG works on two models ie Dedicated resource Model and Hourly Rate Model.
              Both these models are designed to increase delivery efficiency, result into cost
              reduction and at the same time ensure timely and quality delivery of our finance &
              accounts, compliance and payroll services. The type of model that best suits a clients
              requirement depends on the volume and complexity of business. But whatever model is
              chosen, we ensure flexibility, scalability at all times.
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid" >
        <div className="col-12 pt-3 pb-3 pl-0 pr-0 ml-0 mr-0" >
          <ul className="d-flex justify-content-center nav nav-pills mb-3" id="pills-tab" role="tablist" >
            <li className="nav-item col-lg-4 pl-0 pr-0" role="presentation">
              <button
                className="nav-link active border-0 text-white buttonInd mr-2"
                id="pills-home-tab"
                data-toggle="pill"
                data-target="#pills-home"
                type="button"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
                style={{ background: '#072F5F', borderRadius: 20, width: '100%' }}
              >
                WORKING IN CLOUD ENVIRONMENT
              </button>
            </li>
            <li className="nav-item col-lg-4 pl-0 pr-0" role="presentation">
              <button
                className="nav-link border-0 text-white buttonInd mr-2"
                id="pills-profile-tab"
                data-toggle="pill"
                data-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
                style={{ background: '#072F5F', borderRadius: 20, width: '100%', textDecoration:'none' }}
              >
                REMOTE DESKTOP
              </button>
            </li>
            <li className="nav-item col-lg-4 pl-0 pr-0" role="presentation" >
              <button
                className="nav-link border-0 text-white buttonInd mr-2"
                id="pills-contact-tab"
                data-toggle="pill"
                data-target="#pills-contact"
                type="button"
                role="tab"
                aria-controls="pills-contact"
                aria-selected="false"
                style={{ background: '#072F5F', borderRadius: 20, width: '100%' }}
              >
                WORK IN LAN ENVIRONMENT
              </button>
            </li>
          </ul>
          <div className="col-12 pl-0 ml-0 pr-0 mr-0 tab-content" id="pills-tabContent" >
            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" >
              <p className="text-dark" >
                In this case both client and Service provider access the same software Application with separate dedicated login access.
              </p>
            </div>
            <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
              <p className="text-dark">
                We use remote login application like Citrix, LogMeIn etc. to access the CPA server and carry out the work as if any other employee of CPA is working. We will use the Login ids provided to BFAG users, so all the data entered and processed would be tracked by CPA Supervisor.
              </p>
            </div>
            <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
              <p className="text-dark">
                In this case, we use our own License software for Accounting and for Tax we use license provided by Client and after processing the information and preparing the returns, soft copy is forwarded to the client for review.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExecutionMethodology;
