import React, { useState, useEffect } from "react";
import bfaglogo from "../assets/images/BFAG_logo.png";
import "./footer.css";
import ccpalogo from "../assets/new icon/CCPA.png";
import isosymbol from "../assets/new icon/ISO 27001.png";
import isoccpa from "../assets/new icon/ISO 9001 2022.png";
import SOC2TYPE2 from '../assets/new icon/SOC 2 TYPE 2.png'
import { Link } from "react-router-dom";

const Footer = () => {
  const [isIndia, setIsIndia] = useState(false);
  const [isUSA, setIsUSA] = useState(false);
  const [isUAE, setIsUAE] = useState(false);
  const [hover, setHover] = useState(false);

  useEffect(() => {
    const selectedCountry = localStorage.getItem("selectedCountry");
    setIsIndia(selectedCountry === "India");
    setIsUSA(selectedCountry === "USA");
    setIsUAE(selectedCountry === "UAE");
  }, []);

  return (
    <section>
      <footer className="footer-area footer-area-2 footer-area-1 bg_cover">
        <div className="footer-overlay" style={{ background: "#050605" }}>
          <div
            className="position-relative"
            style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
          >
            <div className="row" style={{ padding: "1rem" }}>
              <div className="col-lg-4 col-md-4" style={{ paddingLeft: "3%" }}>
                <div className="widget-item-1 mt-30">
                  <Link to="/">
                    <img
                      src={bfaglogo}
                      alt="BFAG"
                      style={{ maxWidth: "216px" }}
                    />
                  </Link>
                  <h5
                    className="text-white mt-25"
                    style={{
                      fontSize: "20px",
                      fontWeight: "600",
                      lineHeight: "1.9rem",
                    }}
                  >
                    Sound Finance Solutions for Successful People
                  </h5>
                  <h5
                    className="text-white"
                    style={{
                      fontSize: "15px",
                      lineHeight: "1.9rem",
                      fontWeight: "300",
                      textAlign: "justify",
                    }}
                  >
                    Boston Financial Advisory Group (BFAG) is a group of
                    passionate and committed professionals from diverse domains
                    such as finance, accounting, law, and compliance reporting.
                    We take it upon ourselves to manage all your accounting and
                    advisory needs so you can concentrate on what you do best –
                    grow your business.
                  </h5>

                  <Link to="/isopage">
                    <img
                      src={isoccpa}
                      alt="Image 2"
                      className="custom-img"
                      style={{
                        width: "62px",
                        height: "62px",
                        marginRight: "10px",
                      }}
                    />
                  </Link>
                  <Link to="/ccpapage">
                    <img
                      src={ccpalogo}
                      alt="Image 2"
                      className="custom-img"
                      style={{ width: "62px", height: "62px" }}
                    />
                  </Link>
                  <Link to="/isopage">
                    <img
                      src={isosymbol}
                      alt="Image 2"
                      className="custom-img"
                      style={{ width: "62px", height: "62px",marginLeft:'10px' }}
                    />
                  </Link>
                  <Link to="#">
                    <img
                      src={SOC2TYPE2}
                      alt="Image 2"
                      className="custom-img"
                      style={{ width: "62px", height: "62px",marginLeft:'10px' }}
                    />
                  </Link>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 ">
                <div className="widget-item-2 mt-30">
                  <h4
                    className="title text-white pl-3"
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      lineHeight: "1.9rem",
                    }}
                  >
                    Useful Links
                  </h4>
                  <div className="footer-list" style={{ lineHeight: "2.4rem" }}>
                    <ul>
                      <li
                        className="cursor-pointer text-white"
                        style={{ marginRight: "0px", fontSize: "15px" }}
                      >
                        <Link
                          to="/faqoncpasolution"
                          className="text-white"
                          style={{
                            fontWeight: "300",
                            textDecoration: "none",
                          }}
                        >
                          FAQ on CPA Solution
                        </Link>
                      </li>

                      {/* Conditional Rendering for India/USA Entity Setup FAQ */}
                      { !isUAE && !isUSA && (
                        <li
                          className="cursor-pointer"
                          style={{ marginRight: "0px", fontSize: "15px" }}
                        >
                          <Link
                            to="/faqindiaentitysetup"
                            className="text-white"
                            style={{
                              fontWeight: "300",
                              textDecoration: "none",
                            }}
                          >
                            FAQ India Entity Setup
                          </Link>
                        </li>
)}

{!isIndia && !isUAE && (
                        <li
                          className="cursor-pointer"
                          style={{ marginRight: "0px", fontSize: "15px" }}
                        >
                          <Link
                            to="/faq-usa-entity-setup"
                            className="text-white"
                            style={{
                              fontWeight: "300",
                              textDecoration: "none",
                            }}
                          >
                            FAQ USA Entity Setup
                          </Link>
                        </li>
                   )}

                        {!isIndia && !isUSA && (
                        <li
                          className="cursor-pointer"
                          style={{ marginRight: "0px", fontSize: "15px" }}
                        >
                          <Link
                            to="/faq-uae-entity-setup"
                            className="text-white"
                            style={{
                              fontWeight: "300",
                              textDecoration: "none",
                            }}
                          >
                            FAQ UAE Entity Setup
                          </Link>
                        </li>
                    )}

                      {!isUSA && (
                        <li
                          className="cursor-pointer"
                          style={{ marginRight: "0px", fontSize: "15px" }}
                        >
                          <Link
                            to="/faqodi"
                            className="text-white"
                            style={{
                              fontWeight: "300",
                              textDecoration: "none",
                            }}
                          >
                            FAQ ODI
                          </Link>
                        </li>
                      )}
                      <li
                        className="cursor-pointer"
                        style={{ marginRight: "0px", fontSize: "15px" }}
                      >
                        <Link
                          to="/faqfdi"
                          className="text-white"
                          style={{
                            fontWeight: "300",
                            textDecoration: "none",
                          }}
                        >
                          FAQ FDI
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-2">
                <div className="widget-item-2 mt-30">
                  <h4
                    className="title text-white pl-3"
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      lineHeight: "1.9rem",
                    }}
                  >
                    Quick Reference
                  </h4>
                  <div className="footer-list" style={{ lineHeight: "2.4rem" }}>
                    <ul>
                      <li style={{ marginRight: "0px", fontSize: "15px" }}>
                        <Link
                          to="/whybfg"
                          className="text-white"
                          style={{
                            fontWeight: "300",
                            textDecoration: "none",
                          }}
                        >
                          Why BFAG
                        </Link>
                      </li>
                      {/* Only show Business Setup in India when neither USA nor UAE is selected */}
                      {/* {!isUSA && !isUAE && (
  <li style={{ marginRight: "0px", fontSize: "15px" }}>
    <Link
      to="/business-setup"
      className="text-white"
      style={{
        fontWeight: "300",
        textDecoration: "none",
      }}
    >
      Business Setup in India
    </Link>
  </li>
)} */}

                      {!isUSA && (
                        <li style={{ marginRight: "0px", fontSize: "15px" }}>
                          <Link
                            to="/business-setup"
                            className="text-white"
                            style={{
                              fontWeight: "300",
                              textDecoration: "none",
                            }}
                          >
                            Business Setup in India
                          </Link>
                        </li>
                      )}

                      {!isIndia && (
                        <li style={{ marginRight: "0px", fontSize: "15px" }}>
                          <Link
                            to="/business-setup"
                            className="text-white"
                            style={{
                              fontWeight: "300",
                              textDecoration: "none",
                            }}
                          >
                            Business Setup in USA
                          </Link>
                        </li>
                      )}

                      <li style={{ marginRight: "0px", fontSize: "15px" }}>
                        <Link
                          to="/contactus"
                          className="text-white"
                          style={{
                            fontWeight: "300",
                            textDecoration: "none",
                          }}
                        >
                          Contact Us
                        </Link>
                      </li>
                      <li style={{ marginRight: "0px", fontSize: "15px" }}>
                        <Link
                          to="/privacypolicy"
                          className="text-white"
                          style={{
                            fontWeight: "300",
                            textDecoration: "none",
                          }}
                        >
                          Privacy Policy
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/trustcenter"
                          className="text-white"
                          style={{
                            fontSize: "15px",
                            fontWeight: "300",
                            textDecoration: "none",
                          }}
                        >
                          Trust Center
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/ccpapage"
                          className="text-white"
                          style={{
                            fontSize: "15px",
                            fontWeight: "300",
                            textDecoration: "none",
                          }}
                        >
                          Do Not Sell My Info
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-2">
                <div className="widget-item-2 mt-30" style={{ width: "316px" }}>
                  <h4
                    className="title text-white pr-1"
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      lineHeight: "1.9rem",
                    }}
                  >
                    Contact Info
                  </h4>
                  <div className="footer-list">
                    <ul
                      style={{
                        listStyleType: "none",
                        padding: 0,
                        lineHeight: "2.1rem",
                      }}
                    >
                      {!isUSA && !isUAE && (
                        <li
                          style={{
                            paddingRight: "0px",
                            marginRight: "0px",
                            fontWeight: "300",
                            fontSize: "14px",
                          }}
                        >
                          <i
                            className="fas fa-map-marker-alt pr-3 pt-2 text-white"
                            style={{ paddingRight: "0px" }}
                          ></i>{" "}
                          Boston Financial Advisory Group Inc.# 54, 3rd Floor,
                          13th Main 17th Cross.Sector 6, Behind BDA Shopping
                          Complex, HSR Layout, Bangalore 560 102.
                        </li>
                      )}


                      {!isUSA && !isUAE && (
                        <li
                          style={{
                            paddingRight: "0px",
                            marginRight: "0px",
                            fontWeight: "300",
                            fontSize: "14px",
                            // lineHeight:'1.5rem'
                          }}
                        >
                          <i
                            className="fas fa-map-marker-alt pr-3 pt-2 text-white"
                            style={{ paddingRight: "10px" }}
                          ></i>{" "}
                          Boston Financial Advisory Group Inc.Office No.
                          203/204, 2nd Floor, Crystal Empire, Near Karnataka
                          Bank, Behind D-Mart, Baner, Pune 411045.
                        </li>
                      )}

                      {!isIndia && !isUAE && (
                        <li
                          style={{
                            paddingRight: "0px",
                            marginRight: "0px",
                            fontWeight: "300",
                            fontSize: "14px",
                            // lineHeight:'1.5rem'
                          }}
                        >
                          <i
                            className="fas fa-map-marker-alt pr-3 pt-2 text-white"
                            style={{ paddingRight: "10px" }}
                          ></i>{" "}
                          Boston Financial Advisory Group Inc. The Schrafts
                          Center Power House, Office No 341, 529 Main Street,
                          Charlestown, MA-02129
                        </li>
                      )}

                      {!isIndia && !isUAE && (
                        <li
                          style={{
                            paddingRight: "0px",
                            marginRight: "0px",
                            fontWeight: "300",
                            fontSize: "14px",
                            // lineHeight:'1.5rem'
                          }}
                        >
                          <i
                            className="fas fa-map-marker-alt pr-3 pt-2 text-white"
                            style={{ paddingRight: "10px" }}
                          ></i>
                          2214 Walnut Street, Mckeesport, PA 15132
                        </li>
                      )}

                      {!isUSA && !isIndia && (
                        <li
                          style={{
                            paddingRight: "0px",
                            marginRight: "0px",
                            fontWeight: "300",
                            fontSize: "14px",
                            // lineHeight:'1.5rem'
                          }}
                        >
                          <i
                            className="fas fa-map-marker-alt pr-3 pt-2 text-white"
                            style={{ paddingRight: "10px" }}
                          ></i>
                          Boston Financial Advisory Group Inc. Unit 2540, 25th
                          Floor, Iris Bay, Business Bay, Dubai, UAE
                        </li>
                      )}

                      {!isUSA && !isUAE &&(
                        <li
                          style={{
                            paddingRight: "0px",
                            marginRight: "0px",
                            fontWeight: "300",
                          }}
                        >
                          <i className="fas fa-phone-alt pr-3 pt-2 text-white"></i>
                          <a
                            href="tel:+917420061235"
                            className="text-white"
                            style={{
                              fontSize: "14px",
                              // lineHeight:'1.5rem',
                              paddingRight: "10px",
                              textDecoration: "none",
                              fontWeight: "300",
                            }}
                          >
                            +91 7420061235
                          </a>
                        </li>
                      )}

                      {!isIndia && !isUAE && (
                        <li
                          style={{
                            paddingRight: "0px",
                            marginRight: "0px",
                            fontWeight: "300",
                          }}
                        >
                          <i className="fas fa-phone-alt pr-3 pt-2 text-white"></i>
                          <a
                            href="tel:+16178865181"
                            className="text-white"
                            style={{
                              fontSize: "14px",
                              // lineHeight:'1.5rem',
                              paddingRight: "10px",
                              textDecoration: "none",
                              fontWeight: "300",
                            }}
                          >
                            +1 617-886-5181
                          </a>
                        </li>
                      )}
                      {!isUSA && !isUAE && (
                        <li
                          style={{
                            paddingRight: "0px",
                            marginRight: "0px",
                            fontWeight: "300",
                          }}
                        >
                          <i className="fas fa-phone-alt pr-3 pt-2 text-white"></i>
                          <a
                            href="tel:+18572144901"
                            className="text-white"
                            style={{
                              fontSize: "14px",
                              // lineHeight:'1.5rem',
                              paddingRight: "10px",
                              textDecoration: "none",
                              fontWeight: "300",
                            }}
                          >
                            +1 857-214-4901
                          </a>
                        </li>
                      )}

                      {!isUSA && (
                        <li
                          style={{
                            paddingRight: "0px",
                            marginRight: "0px",
                            fontWeight: "300",
                          }}
                        >
                          <i className="fas fa-phone-alt pr-3 pt-2 text-white"></i>
                          <a
                            href="tel:+18572144901"
                            className="text-white"
                            style={{
                              fontSize: "14px",
                              // lineHeight:'1.5rem',
                              paddingRight: "10px",
                              textDecoration: "none",
                              fontWeight: "300",
                            }}
                          >
                            +971 4 3760840
                          </a>
                        </li>
                      )}

                      <li
                        style={{
                          paddingRight: "0px",
                          marginRight: "0px",
                          fontWeight: "300",
                        }}
                      >
                        <i className="fas fa-envelope pr-3 pt-2 text-white"></i>
                        <a
                          href="mailto:info@bostonfagroup.com"
                          className="text-white"
                          style={{
                            fontSize: "14px",
                            // lineHeight:'1.5rem',
                            paddingRight: "10px",
                            textDecoration: "none",
                            fontWeight: "300",
                          }}
                        >
                          info@bostonfagroup.com
                        </a>
                        {/* <a href="mailto:sales@bostonfagroup.com" className='text-white' style={{fontSize:'14px', paddingRight: '20px'}}>sales@bostonfagroup.com</a> */}
                      </li>
                      <li
                        style={{
                          paddingRight: "0px",
                          marginRight: "0px",
                          fontWeight: "300",
                        }}
                      >
                        <i className="fas fa-envelope pr-3 pt-2 text-white"></i>
                        {/* <a href="mailto:info@bostonfagroup.com" className='text-white' style={{fontSize:'14px', paddingRight: '20px'}}>info@bostonfagroup.com</a> <br /> <br /> */}
                        <a
                          href="mailto:sales@bostonfagroup.com"
                          className="text-white"
                          style={{
                            fontSize: "14px",
                            // lineHeight:'1.5rem',
                            paddingRight: "10px",
                            textDecoration: "none",
                            fontWeight: "300",
                          }}
                        >
                          sales@bostonfagroup.com
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="row" style={{ background: "#072f5f" }}>
              <div
                className="col-lg-12"
                style={{
                  borderTop: "1px solid rgba(255, 255, 255, 0.2)",
                  paddingTop: "7px",
                  paddingBottom: "7px",
                }}
              >
                <div
                  className="col-lg-6"
                  style={{ float: "left", color: "#fff" }}
                >
                  <div className="footer-copyright">
                    <p>
                      Copyright @ 2023 All Rights Reserved | Carefully Crafted
                      By{" "}
                      <Link
                        to="https://www.digitalizetheglobe.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          color: hover ? "#000" : "#fff", // Change color to black on hover
                          textDecoration: "none",
                        }}
                        onMouseEnter={() => setHover(true)} // Set hover state to true on mouse enter
                        onMouseLeave={() => setHover(false)} // Set hover state to false on mouse leave
                      >
                        Digitalize The Globe
                      </Link>
                    </p>
                  </div>
                </div>
                <div className="col-lg-6" style={{ float: "right" }}>
                  <div className="header-right-social text-center text-lg-right ">
                    <ul style={{ padding: 0 }}>
                      <li
                        style={{ display: "inline-block", marginRight: "10px" }}
                      >
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.facebook.com/BostonFinancialAdvisoryGroup/"
                        >
                          <i
                            className="fab fa-facebook-f"
                            style={{ color: "#fff" }}
                          ></i>
                        </a>
                      </li>
                      <li
                        style={{ display: "inline-block", marginRight: "10px" }}
                      >
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://twitter.com/BostonfaGroup"
                        >
                          <i
                            className="fab fa-twitter"
                            style={{ color: "#fff" }}
                          ></i>
                        </a>
                      </li>
                      <li style={{ display: "inline-block" }}>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.linkedin.com/company/boston-financial-advisory-group/"
                        >
                          <i
                            className="fab fa-linkedin-in"
                            style={{ color: "#fff" }}
                          ></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </section>
  );
};

export default Footer;
