import React from "react";
import ammar from "../../../../assets/images/teams/ammarCircle.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";

const Ammartumpalliwar = () => {
  return (
    <div className="min-vh-100 mt-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 d-flex justify-content-center mb-3">
            <div style={{ padding: "1%" }}>
              <img
                style={{ borderRadius: "10px", width: "100%", height:'430px' }}
                className="img-fluid"
                src={ammar}
                alt="ammar"
              />
            </div>
          </div>
          <div className="col-lg-8 d-flex flex-column justify-content-start">
            <h3
              className="text-uppercase text-dark title"
              style={{ fontSize: "20px", fontWeight: 700 }}
            >
              Ammar Tumpalliwar
            </h3>
            <div>
              <span
                className="text-uppercase text-dark"
                style={{ fontSize: "16px", fontWeight: 600 }}
              >
                VP – Business Development
              </span>
              <a
                style={{
                  color: "#fff",
                  marginLeft: "10px",
                  backgroundColor: "#0072b1",
                  padding: "4px",
                  borderRadius: "5px",
                }}
                href="https://www.linkedin.com/in/amartumplwar/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faLinkedinIn}
                  style={{ color: "#fff" }}
                />
              </a>
            </div>
            <p
              className="mt-3 text-dark text-justify"
              style={{ fontSize: "16px" }}
            >
              Ammar, the VP of Business Development at Boston Financial Advisory
              Group (BFAG), has a remarkable record in consulting startups and
              SMEs on accounting systems, driving sales for lead generation, and
              facilitating market expansion. With extensive experience spanning
              over three decades in finance, accounts, operations, and business
              development, Ammar is a highly
            </p>
            <p
              className="mt-3 text-dark text-justify"
              style={{ fontSize: "16px" }}
            >
              Ammar's educational background is diverse, including a graduate
              degree in Commerce and Law, as well as a postgraduate degree in
              Management. He previously worked as a project manager,
              contributing to the implementation of SAP R3 in a complex
              environment. Leveraging his expertise in business development,
              finance, operations, and project management, Ammar drives
              organizational growth, fosters strong client relationships, and
              achieves success. Throughout his career, Ammar has provided
              consultancy on accounting systems, processes, and controls to
              SMEs.
            </p>
            <p
              className="mt-3 text-dark text-justify"
              style={{ fontSize: "16px" }}
            >
              Throughout his career, Ammar has provided
              consultancy on accounting systems, processes, and controls to
              SMEs. He played a crucial role in establishing business development
                back office and ensuring effective communication between sales
                force and customers. His accomplishments include identifying and
                implementing marketing tools, facilitating entry into new
                markets, and establishing a solid market presence.
            
            </p>
            <div class=" pl-0 pr-0 " style={{ marginLeft: "-52%" }}>
             

              <p
                className=" text-dark text-justify"
                style={{ fontSize: "16px" }}
              >
                As a specialist in Business Development, Ammar excels in
                identifying sales channel partners, seamless integration,
                expansion into new geographies through strategic alliances, and
                identifying revenue streams.
              </p>

              <p
                className="mt-3 text-dark text-justify"
                style={{ fontSize: "16px" }}
              >
                His strong data mining and analytical skills contribute to
                reaching prospects with a comprehensive services portfolio.
                Ammar finds great satisfaction in thoroughly exploring the
                market for COMPLIANCE iNBOX (CiB), the product he now
                represents.
              </p>
              <p
                className="mt-3 text-dark text-justify"
                style={{ fontSize: "16px" }}
              >
                Ammar actively participates in BNI – BARONS Pune chapter, a
                prestigious global business networking group. Through
                connections, collaboration, and referrals, he drives business
                growth and cultivates meaningful professional relationships. By
                engaging in weekly meetings, sharing insights, and contributing
                to fellow members' success, Ammar epitomizes the spirit of
                entrepreneurship and collective achievement.
              </p>
              {/* <p className="mt-3 text-dark text-justify" style={{ fontSize: '16px' }}>
          Amit Mulgund's remarkable journey in the finance industry has shaped him into a highly respected professional. By leveraging his extensive experience and understanding of the financial landscape, Amit continues to create a positive impact and pave the way for financial success.
          </p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ammartumpalliwar;
