import React, { useState } from "react";
import casestudy from "../../assets/FlipImages/1.png";
import it from "../../assets/FlipImages/1.png";
import {
  TextField,
  Button,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import blog13 from "../../assets/images/blogs/blog13.jfif";
import latestblog from "../../assets/images/blogs/latestblogimg1.jpeg";

const IRSagaindelayseffectivedate = () => {
  const [comment, setComment] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [saveInfo, setSaveInfo] = useState(false);
  const [comments, setComments] = useState([]);

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleWebsiteChange = (event) => {
    setWebsite(event.target.value);
  };

  const handleSaveInfoChange = (event) => {
    setSaveInfo(event.target.checked);
  };

  const handleCommentSubmit = () => {
    if (comment.trim() && name.trim() && email.trim()) {
      setComments([...comments, { comment, name, email, website }]);
      setComment("");
      setName("");
      setEmail("");
      setWebsite("");
      setSaveInfo(false);
    }
  };

  return (
    <>
      <style jsx>{`
        h4 {
          font-size: 18px;
        }
        h3 {
          font-size: 28px;
        }
        span {
          font-size: 16px;
        }
        p {
          font-size: 16px;
        }
        li {
          font-size: 16px;
          font-weight: 500;
        }
        .mainCard {
          height: 20rem;
        }
        .circle {
          width: 100px;
          height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          overflow: hidden;
        }
        i {
          color: #072f5f;
        }
        ul {
          padding-left: 17px;
        }
        @media (max-width: 574px) {
          .mTop {
            margin-top: 30px;
          }
          p {
            font-size: 18px;
          }
          .img-fluid {
            min-height: 100px;
          }
        }
        @media (min-width: 992px) {
          /* for banner image */
          .bmTop {
            margin-top: 130px;
          }
        }
      `}</style>

      <section className="">
        <div
          className="d-flex justify-content-center align-items-center banner-image"
          style={{
            background: `url(${casestudy}) no-repeat center`,
            backgroundSize: "cover",
            height: "200px",
          }}
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h3
              className="text-white text-uppercase text-center"
              style={{ fontWeight: "bold" }}
            >
              IRS again delays effective date of $600 threshold for <br /> Form
              1099-K reporting
            </h3>
          </div>
        </div>
        <div className="container-fluid">
          <div
            className="d-flex row justify-content-center align-items-start pl-4 pr-4"
            style={{ gap: "2rem" }}
          >
            <div
              className="col-lg-8 mt-50 mb-50 border shadow"
              style={{ padding: "2rem" }}
            >
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div>
                  <div
                    style={{
                      background: "#072f5f",
                      display: "inline",
                      width: "fit-content",
                      padding: "0.2rem 1rem 0.2rem 1rem",
                    }}
                  >
                    <small
                      className="text-uppercase text-white"
                      style={{ fontWeight: "600", fontSize: "15px" }}
                    >
                      Introduction
                    </small>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "18px",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      ></span>
                    </div>
                    <p
                      className="text-dark servicep"
                      style={{ textAlign: "justify" }}
                    >
                      Boston Financial Advisory Group (BFAG) is one of the
                      financial services companies that specializes in providing
                      services such as Tax Return Preparation, Accounting,
                      Bookkeeping, Payroll Management to corporates and CPA
                      firms. We exist to keep our clients aware of critical
                      updates on tax issues. The communication of the Internal
                      Revenue Service (IRS) in relation to the delay in
                      threshold reporting in Form 1099-K is indeed important to
                      taxpayers as well as financial advisors. Let's drill down
                      to what this means to you and your business.
                    </p>
                  </div>

                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        What’s New?
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc" }}
                    >
                      <li>
                        The IRS has announced a phased approach to implementing
                        the new Form 1099-K reporting threshold:
                      </li>
              
                     
                      <li>• 2024 Tax Year: The threshold will be $5,000</li>
                      <li>• 2025 Tax Year: The threshold will be $2,500</li>
                      <li>
                        {" "}
                        • 2026 Tax Year and beyond: The $600 threshold will
                        finally take effect{" "}
                      </li>
                    </ul>
                  </div>
                  <div className="mt-15">
                    <span
                      className="text-capitalize"
                      style={{
                        fontSize: "1.5rem",
                        // fontWeight: "700",
                        // color: "#002249",
                      }}
                    >
                      This gradual implementation provides a transitional period
                      for taxpayers and third-party settlement organizations
                      (TPSOs) to adapt to the new requirements. <br/>
                      Implications for Taxpayers, Small Businesses & thriving small business
                      community, this delay is precious time to plan.
                    </span>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        If one is a small business owner, it is advisable to
                        consider the following:
                      </span>
                    </div>
                  </div>
                  <ul
                    className="text-dark servicep"
                    // style={{ listStyleType: "disc", textAlign: "justify" }}
                  >
                    <li>
                      • Reviewing payment processing systems, updating
                      bookkeeping practices to track transactions more
                      meticulously, and consulting with a Certified CPA to
                      comprehend how these changes might influence one's tax
                      strategy.
                    </li>
                    <li>
                      {" "}
                      • Regarding gig economy workers, USA’s expanding gig
                      economy—encompassing rideshare drivers and freelance
                      professionals—will benefit from this phased approach.
                    </li>
                  </ul>

                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Key takeaways include:
                      </span>
                    </div>
                  </div>
                  <ul
                    className="text-dark servicep"
                    style={{ listStyleType: "disc", textAlign: "justify" }}
                  >
                    <li>
                      • Understanding which platform you use will be issuing
                      1099-Ks{" "}
                    </li>
                    <li>
                      {" "}
                      • Gearing up for potentially more detailed income
                      reporting in the future.{" "}
                    </li>
                    <li>
                      {" "}
                      • Set aside appropriate money for taxes, even when you
                      don't receive a 1099-K{" "}
                    </li>
                    <li>
                      {" "}
                      • Individual Taxpayers who occasionally sells online or
                      does part-time gig work: Remember that everything you earn
                      is taxable, 1099-K or not, start following online sales
                      and gig work income more closely.
                    </li>
                    <li>
                      • Gearing up for potentially more detailed income
                      reporting in the future. • Consider consulting with a tax
                      professional to comply Tax Compliance Strategies.
                    </li>
                  </ul>

                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        As your trusted partner for services such as Tax Filing,
                        Accounting, Bookkeeping, Payroll Management etc., Boston
                        Financial Advisory Group highly recommends the following
                        strategies:
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <li>
                        • Implement a Solid Record-Keeping System: Now's the
                        time to start monitoring all income sources closely.
                      </li>
                      <li>
                        • Segregate Personal and Business Expenses: Use separate
                        accounts for business activities.{" "}
                      </li>
                      <li>
                        • Schedule Regular Financial Reviews: Look at your
                        income and what potential tax liabilities you may be
                        exposed to every quarter.
                      </li>
                      <li>
                        • Learn: Stay on top of changes in tax laws as they
                        affect businesses and individuals.
                      </li>
                      <li>
                        • Proactive Tax Planning: Work with a Financial advisor
                        to strategize around these changes that will occur in
                        the near term.
                      </li>

                      <li></li>
                    </ul>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        How Boston Financial Advisory Group Can Assist you, as a
                        top financial advisory group, we are here to lead you
                        through these changes:
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <li>
                        • Personalized Tax Planning: Tailor strategies for
                        Boston small business owners and gig workers.{" "}
                      </li>
                      <li>
                        • Compliance Assistance: Ensure you meet your
                        requirements under the IRS.
                      </li>
                      <li>
                        • Financial Advisory Services: Comprehensive financial
                        planning through a changing tax landscape for taxpayers.
                      </li>

                      <li></li>
                    </ul>
                  </div>
                 
                  <p
                    className="text-dark servicep"
                    style={{ textAlign: "justify" }}
                  >
                    The threshold for Form 1099-K reporting goes into effect in
                    2026, at $600. All income remains taxable, proper
                    record-keeping and tax planning can't be overemphasized.
                    Consider consulting with BFAG for personalized advice.
                  </p>
                  <img
                    src={latestblog}
                    style={{
                      display: "block",
                      margin: "1rem auto",
                      width: "500px",
                      height: "300px",
                    }}
                  />
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Conclusion :
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign: "justify" }}
                    >
                      <li>
                        The delay in the imposition of the Form 1099-K threshold
                        provides some cushion, but taxpayers would be wise to
                        stay informed and prepared. At BFAG we make the
                        transition easier for clients to adapt to all of this.
                        Don't wait till the last-minute-get into planning now,
                        so you are future ready for tax reporting. To find out
                        how these new changes can affect your circumstances,
                        call BFAG now. Let us help you keep moving forward and
                        maximize your financial potential against such updates
                        from the IRS.
                      </li>
                    </ul>
                  </div>

                  <Box
                    mt="2rem"
                    p="2rem"
                    border="1px solid #ddd"
                    borderRadius="8px"
                  >
                    <Typography variant="h5" gutterBottom>
                      Leave a Comment
                    </Typography>
                    <TextField
                      label="Message"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={4}
                      value={comment}
                      onChange={handleCommentChange}
                      margin="normal"
                    />
                    <Box display="flex" gap="1rem">
                      <TextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        value={name}
                        onChange={handleNameChange}
                        margin="normal"
                      />
                      <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        value={email}
                        onChange={handleEmailChange}
                        margin="normal"
                      />
                    </Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={saveInfo}
                          onChange={handleSaveInfoChange}
                        />
                      }
                      label="Save my name, email, and website in this browser for the next time I comment."
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCommentSubmit}
                      style={{ backgroundColor: "rgb(0, 34, 73)" }}
                    >
                      Send Comment
                    </Button>
                  </Box>
                  <Box mt="2rem">
                    {comments.map((cmt, index) => (
                      <Box
                        key={index}
                        mt="1rem"
                        p="1rem"
                        border="1px solid #ddd"
                        borderRadius="8px"
                      >
                        <Typography variant="body1">{cmt.comment}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {cmt.name} - {cmt.email}
                          {cmt.website && ` - ${cmt.website}`}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default IRSagaindelayseffectivedate;
