import React from "react";
import { Box, Typography, Card, CardContent, Grid } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./carousel.css";
import Rating from "@mui/material/Rating";
import constantImage from "./assets/FlipImages/testimonial.png"; // Import the constant image
import whatweare2 from "./assets/images/what-we-are-shape-2.png";

const testimonials = [
  {
    text: "Boston Financial has been our Outsourced Accounting & Regulatory compliance partner from the beginning. Our association is more than a decade long and I am still pretty much impressed by their adherence to service levels. They have proactively solved issues impacting the business and have been consistently providing inputs on key performance indicators. This has helped me in running the business more effectively. The problem solving ability of the team continues to amaze.",
    author: "CEO of Leading Educational Institute in Southern India",
  },
  {
    text: "Boston Financial have been an asset to us and we consider them as an internal partner who has helped us on our growth path. Since we are working in multiple geographies, the biggest advantage of being associated with Boston Financial is that we have to talk to a single agency who does all our compliances and presents us with timely & accurate consolidated financial at all times",
    author: "A leading SAP solution provider",
  },
  {
    text: "Boston Financials is associated with Ephicacy as its accounting partner for 6 years. Their team comprising experts in finance, legal domain, have done a splendid job in efficiently managing Ephicacy's compliance to statutory and regulatory requirements without compromising data security. Over the years, they have become an extended arm of Ephicacy Finance and have earned the tag of 'trustworthy and reliable partners. As we continue our association with Boston team, we strongly recommend the same to others too!",
    author:
      "Sunanda Subramanyam - Manager (Finance), Ephicacy Lifescience Analytics",
  },
  {
    text: "Boston Financial have been an asset to us and we consider them as an internal partner who has helped us on our growth path.Since we are working in multiple geographies, the biggest advantage of being associated with Boston Financial is that we have to deal with a single agency who does all our compliances and presents us with timely & accurate consolidated financials. Their expertise in Corporate Structuring has helped us a lot in venturing overseas.",
    author: "Atul Siddam - Director, Diligent Tech India Private Limited",
  },
  {
    text: "Your Outsourced Accounting & Reporting services have always been excellent. Delivering in a complex manufacturing set-up, post-merger integration support, Global reporting, everything on time and always meeting the expectations. Great team that never fails to come up with solutions.",
    author: "A Communication & Data Connectivity Solution Provider",
  },
  {
    text: "Boston Financial have been an asset to us and we consider them as an internal partner who has helped us on our growth path. Since we are working in multiple geographies, the biggest advantage of being associated with Boston Financial is that we have to deal with a single agency who does all our compliances and presents us with timely & accurate consolidated financials. Their expertise in Corporate Structuring has helped us a lot in venturing overseas.",
    author: "A Leading Manufacturer of Automotive Electrical Parts",
  },
];

const TestimonialSlider = () => {
  return (
    <>
      <div style={{}}>
        <div className="what-we-are-shape-2">
          {/* <img
            src={whatweare2}
            alt="shape"
            style={{
              position: "absolute",
              transform: "rotate(180deg)",
              top: 0,
              overflow: "clip",
              left: "-1%",
              width: "20%",
            }}
          />
          <img
            src={whatweare2}
            alt="shape"
            style={{
              position: "absolute",
              overflow: "clip",
              right: "-2%",
              width: "20%",
              bottom: "-10%",
            }}
          /> */}
        </div>
        <div className="row text-center justify-content-center align-content-center">
          <div className="col-lg-12" style={{ marginTop: "14px" }}>
            <span
              style={{
                fontWeight: "bold",
                color: "rgb(7, 47, 95)",
                fontSize: "25px",
                color: "#072f5f",
              }}
            >
              Testimonials
            </span>
            <h3
              className="title  text-uppercase testword"
              style={{
                fontWeight: "bold",
                fontSize: "30px",
                marginTop: "10px",
                color: "#072f5f",
              }}
            >
              Words From Our Clients
            </h3>
          </div>
        </div>
        <Box
          sx={{
            width: "90%",
            margin: "auto",
            padding: 2,
            boxShadow:
              "0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)",
            borderRadius: 2,
            textAlign: "center",
            backgroundColor: "white", // Adding a background color for better contrast
          }}
        >
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} md={4}>
              <Box sx={{ textAlign: "center" }}>
                <img
                  src={constantImage}
                  alt="Constant Testimonial"
                  style={{ width: "100%", maxWidth: 300, borderRadius: "90%" }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Carousel
                showArrows={true}
                showThumbs={false}
                showStatus={false}
                infiniteLoop
                autoPlay
                interval={5000}
                transitionTime={500}
              >
                {testimonials.map((testimonial, index) => (
                  <Box key={index} sx={{ padding: 2 }}>
                    <CardContent>
                      <Typography
                        variant="body1"
                        className="text-center"
                        paragraph
                        style={{ fontWeight:500 , color:'black'}}
                      >
                        {testimonial.text}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        color="textSecondary"
                        className="text-center"
                        style={{ color: "rgb(7, 47, 95)", fontWeight: 600 }}
                      >
                        {testimonial.author} <br /> <br />
                        <Rating
                          name="read-only"
                          value={5}
                          readOnly
                          precision={0.5}
                          size="small"
                          sx={{ color: "gold", ml: 1 }}
                        />
                      </Typography>
                    </CardContent>
                  </Box>
                ))}
              </Carousel>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
};

export default TestimonialSlider;
