import React from 'react';
import './product.css'
import backgroundleft from '../../../assets/images/home-products-background-left.png'
import backgroundright from '../../../assets/images/home-products-background-right.png'
import Ci from '../../../assets/images/Ci.png'
import Ei from '../../../assets/images/Ei.png'
import Ai from '../../../assets/images/Ai.png'
import ProductComponent from '../../ProductComponent/ProductComponent';

const ProductPage = () => {
  return (
    <div className="portfolio-3-area custom-portfolio-css pt-40 pb-40" style={{backgroundColor:'white'}}>
      <div className="contact-us-area bg_cover mt-10">
        <div className="contact-overlay shadow justify-content-center dec-spacing" style={{ background: '#072f5f', position: 'relative' , height:'691px' }}>
          <img src={backgroundleft} alt="shape" style={{ position: 'absolute', top: 0, overflow: 'clip', left: '0%' }} />
          <img src={backgroundright} alt="shape" style={{ position: 'absolute', overflow: 'clip', right: '0%', top: 0 }} />
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div 
                // className="contact-details d-flex shadow flex-column justify-content-center align-items-center contactUsImg" 
                style={{ padding: '3%' }}>
                  {/* <div className="row text-center justify-content-center">
                    <div className="pb-2">
                      <h3 className="producth3 text-white" style={{fontWeight:'bold'}}>TAKE A LOOK AT OUR PRODUCTS</h3>
                    </div>
                  </div> */}
                  {/* <div className="row justify-content-center product-description mb-3" style={{ width: '100%' }}> */}
                    {/* <div className="pb-5 justify-content-center">
                      <p className="text-white text-center">
                        We use an optimum blend of people and technology. we make efficient use of technology to handle routine financial activities.
                      </p>
                    </div> */}
                  {/* </div> */}
                <div className=" shadow" style={{backgroundColor:'white', width:'100%' , marginTop: '22px'}}>
                <div className="row text-center justify-content-center ">
                    <div className="pb-2">
                      <h3 className="producth3" style={{fontWeight:'bold' , color:'black' , paddingTop:'17px'}}>TAKE A LOOK AT OUR PRODUCTS</h3>
                    </div>
                    <div className="pb-5 justify-content-center">
                      <p className="text-center" style={{color:'black'}}>
                        We use an optimum blend of people and technology. we make efficient use of technology to handle routine financial activities.
                      </p>
                    </div>
                  </div>
                  {/* <div className="row justify-content-center" style={{ gap: '2rem' , marginTop:'-14px'}}>
                    <div className="row justify-content-center cursor-pointer mt-5 mb-5" style={{ width: '333px' }} routerLink="/compliance-inbox" routerLinkActive="active">
                      <div className="case-card">
                        <img src={Ci} alt="Image" style={{paddingTop: '10px'}} />
                      </div>
                      <div className="text-center mt-1">
                        <h4 style={{fontWeight:'bold'}}>COMPLIANCE iNBOX</h4>
                        <p className="mt-10 text-dark text-center" style={{ lineHeight: 'normal', fontSize: '16px !important' }}>
                          It is an intuitive solution developed to address this very pressing need to simplify compliance management across the organization.
                        </p>
                      </div>
                    </div>
                    <div className="row justify-content-center cursor-pointer mt-5 mb-5" style={{ width: '333px' }} routerLink="/expense-inbox" routerLinkActive="active">
                      <div className="case-card">
                        <img src={Ei} alt="Image" style={{paddingTop: '10px'}} />
                      </div>
                      <div className="text-center mt-4">
                        <h4 style={{fontWeight:'bold'}}>EXPENSE iNBOX</h4>
                        <p className="mt-10 text-dark text-center" style={{ lineHeight: 'normal', fontSize: '16px !important' }}>
                          It is a highly scalable, out of the box expense reporting solution that delivers powerful insight into the company and departmental spending through automated data analysis.
                        </p>
                      </div>
                    </div>
                    <div className="row justify-content-center cursor-pointer mt-5 mb-5" style={{ width: '333px' }} routerLink="/analytics-inbox" routerLinkActive="active">
                      <div className="case-card">
                        <img src={Ai} alt="Image" style={{paddingTop: '10px'}} />
                      </div>
                      <div className="text-center mt-1">
                        <h4 style={{fontWeight:'bold'}}>ANALYTICS iNBOX</h4>
                        <p className="mt-10 text-dark text-center" style={{ lineHeight: 'normal', fontSize: '16px !important' }}>
                          Analytics iNBOX gives Information at the click of a button with 24 / 7 availability from anywhere, Bird’s eye view of the financial position of the enterprise.
                        </p>
                      </div>
                    </div>
                  </div> */}


                  <ProductComponent/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductPage;
