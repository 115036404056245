import React from 'react'
import { Link } from 'react-router-dom';

const AccordionItem = ({ eventKey, header, body, icon }) => {
    return (
  <div className="accordion-item" style={{ marginBottom: '20px' }}>
        <h2 className="accordion-header" id={`heading${eventKey}`}>
          <button
            className="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#collapse${eventKey}`}
            aria-expanded="true"
            aria-controls={`collapse${eventKey}`}
            style={{ color: '#002249',
border: '1px solid #F0F8FF',
    backgroundColor:'#F0F8FF'

             }}
          >
            <i className={`bi ${icon}`} style={{ marginRight: '10px' }}></i>
            {header}
          </button>
        </h2>
        <div
          id={`collapse${eventKey}`}
          className="accordion-collapse collapse"
          aria-labelledby={`heading${eventKey}`}
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body"  style={{ 
          border: '1px solid #F0F8FF',
             backgroundColor:'#FFF'
         
                    }}>
            {body}
          </div>
        </div>
      </div>
    );
  };
  
  const Faqindiaentitysetup = () => {
    return (
      <div className="bsb-accordion-6 py-3 py-md-5 py-xl-8">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div>
                <h2 className="mt-55 pb-3" style={{ fontWeight: 'bold', color: '#072f5f' }}>
                FAQ'S ON SETTING UP AN ENTITY IN INDIA
                </h2>
              </div>
              <div className="accordion" id="accordionExample" style={{ border: 'none' }}>
                <AccordionItem
                  eventKey="One"
                  header={<span style={{ fontWeight: 'bold' }}>What are the forms of setting up a business entity in India available for Foreign Investors? Which one is the most popular?</span>}
                  body={<p> Foreign investors can operate in India through separate
                    legal entities such as Private Limited Company, Public
                    Limited Company and Limited Liability Partnership (LLP) or
                    through dependent representative offices such as the Liaison
                    Office (LO), Project Office (PO) and Branch Office (BO).
                    <b>The Private Limited Company</b> is the most frequently
                    chosen form to set up a business in India, as it is
                    particularly convincing due to its relatively uncomplicated
                    incorporation process and high flexibility when it comes to
                    business models. There are other investment vehicles like
                    partnerships etc., but they are not available for foreign
                    investors.</p>}
                  icon="bi-envelope"
                />
                
                <AccordionItem
                  eventKey="Two"
                  header={<span style={{ fontWeight: 'bold' }}>What is a Private Limited Company?"</span>}
                  body={<p>A Private Limited Company is a separate legal entity, which
                    offers limited liability to its shareholders. The liability
                    of the shareholders can be either limited by shares or
                    limited by guarantee.</p>}
                  icon="bi-card-text"
                />
               
                <AccordionItem
                  eventKey="Three"
                  header={<span style={{ fontWeight: 'bold' }}>Is there a minimum number of shareholders required for an
                  Indian Private Limited Company?"</span>}
                  body={<p>Yes, a minimum of two shareholders are required to form a
                    Private Limited Company in India. It is sufficient if one of
                    the two shareholders holds only one share and the other
                    holds all of the remaining shares. Shareholders can be
                    either individuals or legal entities, as long as they are
                    legally recognized.</p>}
                  icon="bi-card-text"
                />
                 
                 <AccordionItem
                  eventKey="Four"
                  header={<span style={{ fontWeight: 'bold' }}>What are the duties and rights of shareholders of an Indian
                  Private Limited Company?"</span>}
                  body={<p>The shareholders can carry out their rights exclusively
                    through General Meetings. Shareholders have a right, among
                    others, to vote on matters concerning the substrate of the
                    company. Such matters include but are not limited to,
                    liquidity, the sale of assets, the MOA/AOA, inspect
                    statutory registers and minutes books and appointment and
                    removal of Directors. The shareholders are obliged to
                    participate in the General Meetings and exercise their
                    voting rights.<br /></p>}
                  icon="bi-card-text"
                />
                 <AccordionItem
                  eventKey="Five"
                  header={<span style={{ fontWeight: 'bold' }}>Who is responsible for managing a Private Limited Company?"</span>}
                  body={<p>The responsibility to manage the company is on the Board of
                    Directors, as a collective management body. It carries out a
                    double function: On one hand, it is the collective
                    management body but on the other hand, it is also carrying
                    out the controlling functions and is therefore comparable to
                    a supervisory or advisory board.
                    The Board of Directors consists at least two members of which
                    at least one has to be Resident of India (Resident
                        Director).
                        Resident of India is a person who stayed, means who has been
                  physically present, in India for 182 days or more 
                  within the last Financial Year. The purpose of his stay in
                  India does not matter.
                    </p>
                        
                }
                  icon="bi-card-text"
                />
                 <AccordionItem
                  eventKey="Six"
                  header={<span style={{ fontWeight: 'bold' }}>What are the duties and rights of Directors of a Private
                  Limited Company?"</span>}
                  body={<p> <li> The Board of Directors is responsible for the implementation
                    of shareholder resolutions and is therefore the sole
                    executive body responsible to the shareholders</li>
                
                 <li> Therefore, all Directors are obligated to Fiduciary Duties
                  and to the Duty of Care, Skill and Diligence. Fiduciary
                  duties ensure that Directors always put the interests of the
                  company and its shareholders first and above their own.
                  Fiduciary duties arise from the general authority granted to
                  the Board of Directors, according to which Directors have to
                  act in good faith and to their best knowledge.</li>
                 <li> The Directors are entitled to both individual and collective
                    rights. Individual rights include, for example, the right to
                    inspect the books and various voting rights. The collective
                    rights of the Board of Directors include, in particular, the
                    right to propose dividends, to appoint a Chairman and a
                    Managing Director. A Managing Director is, who is appointed
                    by virtue of the articles of the company or agreement with
                    the company or resolution passed in its general meeting or
                    by its board of Directors, entrusted with substantial powers
                    of management. The Managing Director usually manages the
                    day-to-day business of the company. He has a double role, as
                    he is both a Director and also an employee of the company.</li>
                    <li>It is not compulsory to appoint a Managing Director for a
                    Private Limited Company.</li>
                  </p>}
                
                  icon="bi-card-text"
                />
                 <AccordionItem
                  eventKey="Seven"
                  header={<span style={{ fontWeight: 'bold' }}>What is the liability of a director?"</span>}
                  body={<p> <li>Liability of Directors may result from wilful acts, gross
                    negligence or slight negligence.</li>
                    <li> Directors are personally liable to the company for all
                      unlawful acts and for transactions for which they were not
                      authorized and are inadmissible for being Ultra Vires.
                      This liability also includes damage compensation.</li>
                      <li> Directors are in general not liable for gross negligence or
                    slight negligence if the Directors have acted truly in the
                    interest of the company and within the scope of their powers
                    of disposal and with such due diligence, as it is
                    appropriate for their level of knowledge and experience.</li>
                    </p>}
                  icon="bi-card-text"
                />
                 <AccordionItem
                  eventKey="Eight"
                  header={<span style={{ fontWeight: 'bold' }}>Is it required to have a statute?"</span>}
                  body={<p> Yes, every Indian Private Limited Company has to have a
                    statute which is the<b> Memorandum of Association (MOA) and the Articles of
                    Association (AOA).</b>The MOA defines the name, objectives, registered office
                    address and capital of the company. Therefore, it explains
                    the relationship of a company with the outside world. The
                    AOA regulates the internal rules and regulations and the
                    internal relationship with the outside world. Both documents
                    can be seen as public documents at the Registrar of
                    Companies (ROC). </p>}
                  icon="bi-card-text"
                />
                 <AccordionItem
                  eventKey="Nine"
                  header={<span style={{ fontWeight: 'bold' }}>Is there a requirement for minimum share capital in India?"</span>}
                  body={<p>  There is no minimum share capital required for incorporating
                    a Private Limited Company. However, the amount of capital
                    required depends on the requirements of the proposed
                    business model of the company. Therefore, the company should
                    have at least enough capital in order to secure business
                    activities for a few months after incorporation.</p>}
                  icon="bi-card-text"
                />
                 <AccordionItem
                  eventKey="Ten"
                  header={<span style={{ fontWeight: 'bold' }}>What different types of capital exist"</span>}
                  body={<p>  There are three different "types" of capital: Authorised
                    Share Capital, Subscribed Share Capital and Paid-up
                    Capital.<li><b> Authorised Share Capital</b> is the maximum
                    amount of share capital, up to which a company can issue
                    shares.</li><li><b> Subscribed Share Capital</b> is a subgroup of
                    Authorised Capital and it indicates the number of shares
                    that the shareholders have to subscribe to. Each shareholder
                    must subscribe for at least one share but is not obliged to
                    convert the shares he subscribed for into capital in full.</li>
                    <li><b>Paid-up Capital</b> is the capital which is actually paid
                    into the company's bank account by the shareholders in
                    accordance with their Subscribed Share Capital. Upon receipt
                    of the Subscribed Share Capital, the company must issue
                    shares to the shareholders in the form of Share
                    Certificates. This process has to be documented and reported
                    to the authorities.
                    The personal liability of the shareholders then only extends
                    to unpaid capital on their shares.</li></p>}
                  icon="bi-card-text"
                />
                 <AccordionItem
                  eventKey="Eleven"
                  header={<span style={{ fontWeight: 'bold' }}>Is there a need to obtain additional permits or approvals for
                  foreign entities investing in India?"</span>}
                  body={<p><ul> No, Foreign Direct Investment in India has been greatly
                    liberalized in recent years and is now almost 100% permitted
                    in all sectors through the<b> Automatic route.</b> However,
                    foreign exchange regulations and procedures under the
                    Foreign Exchange Management Act (FEMA) must be carried out
                    in the case of cash payments from foreign shareholders. For
                    example, payments have to be made via the international
                    banking channel and via the Reserve Bank of India (RBI).
                    After the payment has been made, it has to be reported that
                    the shares were issued at a Fair Value.
                    Only in some highly sensible sectors, such as Defence and
                    Defence Industries, Foreign Direct Investment is only
                    allowed via the</ul><ul> <b>Approval Route.</b> Further, it is to be
                    mentioned that Foreign Direct Investment in the e-commerce
                    sector is subject to restrictions.</ul></p>}
                  icon="bi-card-text"
                />
                 <AccordionItem
                  eventKey="Twelve"
                  header={<span style={{ fontWeight: 'bold' }}>Can the capital of an Indian Private Limited Company be
                  increased?"</span>}
                  body={<p>  Yes, the Paid-up Capital can be increased as long it is
                      not exceeding the Authorised Share Capital.
                      The Authorised Share Capital can also be increased.
                    However, increasing the Authorised Share capital is rather
                    time-consuming, as the MOA has to be changed for this
                    purpose. For changing the MOA, a Special Resolution has to
                    be passed at an EGM. Any change in the MOA must be filed
                    with the ROC.
                       </p>}
                  icon="bi-card-text"
                />
                <AccordionItem
                  eventKey="Thirthin"
                  header={<span style={{ fontWeight: 'bold' }}>Can an Indian Private Limited Company take a loan?"</span>}
                  // headerStyle={{ fontWeight: '700' }}
                  body={<p>Yes, an Indian Private Limited Company can either take a
                    loan or overdraft facilities after checking the regulatory
                    restrictions. The loan is usually provided by an Indian bank
                    at local conditions.
                    Loans from a foreign shareholder are considered External
                    Commercial Borrowings (ECB). These ECBs are subject to
                    strict regulations.
                    </p>}
                  icon="bi-card-text"
                />
                <AccordionItem
                  eventKey="Fourteen"
                  header={<span style={{ fontWeight: 'bold' }}>How do you incorporate a Private Limited Company in India?"</span>}
                  // headerStyle={{ fontWeight: '700' }}
                  body={<p> 
                    <li> Apply for Name reservation.</li>
                    <li>If the shareholders are legal entities, they must
                      report their will for incorporating a new company in a Board
                      Resolution. Some basic information of the newly incorporated
                      company such as the name of the company, Authorised Share
                      Capital, Paid-up Capital, registered office, etc. has to be
                      reflected in the Board Resolution.</li>
                    <li>At least two Directors have to be appointed, and
                      one of them has to be Resident Director. All Directors have
                      to apply for a Director Identification Number (DIN) and a
                      Digital Signature Certificate (DSC) during the incorporation
                      process.</li>
                    <li>Several required documents must be signed and stamped by the shareholders.</li>
                  
                    </p>}
                  icon="bi-card-text"
                />
                 <AccordionItem
                  eventKey="Fivteen"
                  header={<span style={{ fontWeight: 'bold' }}>How long does the incorporation process take?"</span>}
                  // headerStyle={{ fontWeight: '700' }}
                  body={<p> Usually, the incorporation process for a Private Limited
                    Company takes <b>30 to 45 days,</b> once all the documents
                    are received from the client. However, this duration heavily
                    depends on the participation of the shareholders, the
                    Availability of the Name of the proposed entity, the working
                    conditions of the Ministry of Corporate Affairs (MCA)
                    website and the procurement of necessary documents.
                    </p>}
                  icon="bi-card-text"
                />
                <AccordionItem
                  eventKey="Sixteen"
                  header={<span style={{ fontWeight: 'bold' }}>What is a Fast Track Incorporation?</span>}
                  // headerStyle={{ fontWeight: '700' }}
                  body={<p> It is possible to first
                    incorporate a company with local shareholders.
                    The incorporation process and the bank account opening
                    process are significantly faster in such a scenario.
                    
                      After incorporation, this fully operational company can
                      then be transferred to the foreign shareholders.
                    </p>}
                  icon="bi-card-text"
                />
                 <AccordionItem
                  eventKey="Seventeen"
                  header={<span style={{ fontWeight: 'bold' }}>What is the post Incorporation process?"</span>}
                  // headerStyle={{ fontWeight: '700' }}
                  body={<p> 
                    <li>
                      Conducting of First Board Meeting within 30 days from the
                      date of incorporation.
                    </li>
                    <li>
                      Filing of Form INC-22 for the situation of the registered
                      office of the Company within 30 days from the date of
                      Incorporation.
                    </li>
                    <li>
                      Appointment of the First Statutory Auditor of the Company
                      and filing of all the forms with ROC.
                    </li>
                    <li>
                      Opening of a Bank account in the name of the Company.
                    </li>
                    <li>
                      Allotment of Shares and Issue of share certificates.
                    </li>
                    <li>Deposition of Capital Contribution.</li>
                    <li>
                      Filing of Commencement of Business (Form INC-20A) with
                      MCA.
                    </li>
                  
                    </p>}
                  icon="bi-card-text"
                />
                {/* Add more AccordionItems as needed */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

export default Faqindiaentitysetup;