import React from 'react';
import blog from '../../../assets/images/blogs/blog.png'
import videoBackground from "../../../assets/video/boston baneer video.mp4";
import BlogTwo from '../../BlogTwo';
 

const Blogs = () => {

  return (
    <section>
     {/* <div
            className="banner-wrapper"
            style={{ position: "relative", height: "400px" }}
          >
            <video
              className="banner-video"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              src={videoBackground}
              autoPlay
              loop
              muted
            />
            <div
              className="banner-overlay"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <div className="container-fluid trdBannar h-100">
                <div className="row h-100 d-flex align-items-center">
                  <div className="col-lg-6">
                    <div className="banner-content text-white">
                      <h1
                        className="title ml-3"
                        style={{
                          animation: "fadeInLeft 0.5s",
                          animationDelay: "0.5s",
                          fontWeight: "bold",
                          color: "#fff",
                        }}
                      >
                        <a  style={{fontSize:'30px' , justifyContent:'center'}}>Blogs </a>
                        <br />
                      </h1>
                      <li
                        className="pt-5 "
                        style={{
                          listStyleType: "none",
                          animation: "fadeInLeft 1.3s",
                          animationDelay: "1.3s",
                        }}
                      >
                       
                      </li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <BlogTwo/>
    </section>
  );
};

export default Blogs;
