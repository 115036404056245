import React, { useState } from "react";
import casestudy from "../../assets/FlipImages/1.png";
import it from "../../assets/FlipImages/1.png";
import {
  TextField,
  Button,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import blog1 from "../../assets/images/blogs/blog1.jfif";

const SecondBlog = () => {
  const [comment, setComment] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [saveInfo, setSaveInfo] = useState(false);
  const [comments, setComments] = useState([]);

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleWebsiteChange = (event) => {
    setWebsite(event.target.value);
  };

  const handleSaveInfoChange = (event) => {
    setSaveInfo(event.target.checked);
  };

  const handleCommentSubmit = () => {
    if (comment.trim() && name.trim() && email.trim()) {
      setComments([...comments, { comment, name, email, website }]);
      setComment("");
      setName("");
      setEmail("");
      setWebsite("");
      setSaveInfo(false);
    }
  };

  return (
    <>
      <style jsx>{`
        h4 {
          font-size: 18px;
        }
        h3 {
          font-size: 28px;
        }
        span {
          font-size: 16px;
        }
        p {
          font-size: 16px;
        }
        li {
          font-size: 16px;
          font-weight: 500;
        }
        .mainCard {
          height: 20rem;
        }
        .circle {
          width: 100px;
          height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          overflow: hidden;
        }
        i {
          color: #072f5f;
        }
        ul {
          padding-left: 17px;
        }
        @media (max-width: 574px) {
          .mTop {
            margin-top: 30px;
          }
          p {
            font-size: 18px;
          }
          .img-fluid {
            min-height: 100px;
          }
        }
        @media (min-width: 992px) {
          /* for banner image */
          .bmTop {
            margin-top: 130px;
          }
        }
      `}</style>

      <section className="">
        <div
          className="d-flex justify-content-center align-items-center banner-image"
          style={{
            background: `url(${casestudy}) no-repeat center`,
            backgroundSize: "cover",
            height: "200px",
          }}
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h3
              className="text-white text-uppercase text-center"
              style={{ fontWeight: "bold" }}
            >
              Sustainable Finance : Boston <br /> <br />
              Financial’s ESG Approach
            </h3>
          </div>
        </div>
        <div className="container-fluid">
          <div
            className="d-flex row justify-content-center align-items-start pl-4 pr-4"
            style={{ gap: "2rem" }}
          >
            <div
              className="col-lg-8 mt-50 mb-50 border shadow"
              style={{ padding: "2rem" }}
            >
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div>
                  <div
                    style={{
                      background: "#072f5f",
                      display: "inline",
                      width: "fit-content",
                      padding: "0.2rem 1rem 0.2rem 1rem",
                    }}
                  >
                    <small
                      className="text-uppercase text-white"
                      style={{ fontWeight: "600", fontSize: "15px" }}
                    >
                      Embracing the Future of Finance
                    </small>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "18px",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      ></span>
                    </div>
                    <p className="text-dark servicep" style={{textAlign:'justify'}}>
                      The ESG considerations increasingly influence investment
                      decisions, but Boston Financial Advisory Group is at the
                      cutting edge of sustainable finance now. Their ESG
                      [Environmental, Social and Governance] approach and how it
                      impacts their financial strategies will be discussed in
                      the following part.{" "}
                    </p>
                  </div>
                  <div
                    style={{
                      background: "#072f5f",
                      display: "inline",
                      width: "fit-content",
                      padding: "0.2rem 1rem 0.2rem 1rem",
                    }}
                  >
                    <small
                      className="text-uppercase text-white"
                      style={{ fontWeight: "600", fontSize: "15px" }}
                    >
                      ESG Integration
                    </small>
                  </div>
                  <div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign:'justify' }}
                    >
                      <li>
                        Boston Financial ensures that all activities related to
                        ESG are in line with the investment process. While
                        focusing on environmental, social, and governance
                        issues, they reveal possible investment opportunities in
                        the field of sustainability. This combination guarantees
                        a climate-friendly approach, future profitability, and
                        risk reduction.{" "}
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Process Automation :
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign:'justify' }}
                    >
                      <li>
                        The process is accelerated by automation removing
                        monotonous manual duties like inputting and three-way
                        matching, thus saving time and decreasing human errors.
                        Instead of being usurped by robots, accountants are now
                        able to concentrate on those activities and tasks that
                        are more creative, collaborative, and intuitive. These
                        are services that AI cannot provide with a great level
                        of accuracy, does not mean that there is no use of
                        articulated intelligence it can in many instances.{" "}
                      </li>

                      <li></li>
                    </ul>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Green Bonds and ESG-Linked Loans :
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign:'justify' }}
                    >
                      <li>
                        Boston Financial is the prime mover of companies that
                        are ESG-centric. They, on their part, conduct green bond
                        and ESG-linked loan campaigns, thus, supporting the
                        finance of the environmental protective sector and the
                        social responsibility cause. The financial products
                        procured are definitely the driving force behind
                        sustainable growth and a positive change that takes
                        place now.{" "}
                      </li>

                      <li></li>
                    </ul>
                  </div>
                  <div
                    style={{
                      background: "#072f5f",
                      display: "inline",
                      width: "fit-content",
                      padding: "0.2rem 1rem 0.2rem 1rem",
                    }}
                  >
                    <small
                      className="text-uppercase text-white"
                      style={{ fontWeight: "600", fontSize: "15px" }}
                    >
                      Net-Zero Commitment
                    </small>
                  </div>
                  <br />
                  <p className="text-dark servicep"style={{textAlign:'justify'}}>
                    Boston Financial puts forward massive and realistic net-zero
                    targets for the greenhouse gas emissions of portfolios along
                    and the activities within the finance sector. To be
                    specific, they develop in-house the carbon measurement tool
                    CO2 FI and through it, they monitor the journey to a full
                    carbon-neutral globe. As a result, it directly demonstrates
                    their commitment to environmental stewardship.{" "}
                  </p>
                  <img
                    src={blog1}
                    style={{
                      display: "block",
                      margin: "1rem auto",
                      width: "400px",
                      height: "300px",
                    }}
                  />
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Data-Driven Insights :
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign:'justify' }}
                    >
                      <li>
                        Forward-looking ESG data and reporting features should
                        be among the first factors in considering them as
                        essentials. Boston Financial is highly depending on data
                        for their responses to the regulatory requirements and
                        the satisfaction of the stakeholders. The reporting of
                        the data is so much a matter of simplicity and
                        transparency, so the investors tend to feel obliged and,
                        in their turn, trust the{" "}
                      </li>
                    </ul>
                  </div>

                  <div
                    style={{
                      background: "#072f5f",
                      display: "inline",
                      width: "fit-content",
                      padding: "0.2rem 1rem 0.2rem 1rem",
                    }}
                  >
                    <small
                      className="text-uppercase text-white"
                      style={{ fontWeight: "600", fontSize: "15px" }}
                    >
                      Conclusion
                    </small>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "18px",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      ></span>
                    </div>
                    <p className="text-dark servicep" style={{textAlign:'justify'}}>
                      Boston Financial’s ESG is different not just in terms of
                      compliance with regulations– It is a strategic
                      differentiator for them because of this. Through
                      sustainable finance, they make the lives of the people
                      around them better, they want to be cutting-edge, and they
                      show the others what is achievable in a world where what
                      gets measured truly gets financed.{" "}
                    </p>
                  </div>

                  <Box
                    mt="2rem"
                    p="2rem"
                    border="1px solid #ddd"
                    borderRadius="8px"
                  >
                    <Typography variant="h5" gutterBottom>
                      Leave a Comment
                    </Typography>
                    <TextField
                      label="Message"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={4}
                      value={comment}
                      onChange={handleCommentChange}
                      margin="normal"
                    />
                    <Box display="flex" gap="1rem">
                      <TextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        value={name}
                        onChange={handleNameChange}
                        margin="normal"
                      />
                      <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        value={email}
                        onChange={handleEmailChange}
                        margin="normal"
                      />
                    </Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={saveInfo}
                          onChange={handleSaveInfoChange}
                        />
                      }
                      label="Save my name, email, and website in this browser for the next time I comment."
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCommentSubmit}
                      style={{ backgroundColor: "rgb(0, 34, 73)" }}
                    >
                      Send Comment
                    </Button>
                  </Box>
                  <Box mt="2rem">
                    {comments.map((cmt, index) => (
                      <Box
                        key={index}
                        mt="1rem"
                        p="1rem"
                        border="1px solid #ddd"
                        borderRadius="8px"
                      >
                        <Typography variant="body1">{cmt.comment}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {cmt.name} - {cmt.email}
                          {cmt.website && ` - ${cmt.website}`}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SecondBlog;
