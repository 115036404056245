import React from 'react';
import { Link } from 'react-router-dom';

const AccordionItem = ({ eventKey, header, body, icon }) => {
  return (
<div className="accordion-item"
 style={{ 
  marginBottom: '20px' ,
    border: '1px solid #F0F8FF',
    backgroundColor:'#F0F8FF'
  }}>
      <h2 className="accordion-header" id={`heading${eventKey}`}>
        <button
          className="accordion-button"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#collapse${eventKey}`}
          aria-expanded="true"
          aria-controls={`collapse${eventKey}`}
          style={{ color: '#002249',
 border: '1px solid #F0F8FF',
    backgroundColor:'#F0F8FF'

           }}
        >
          <i className={`bi ${icon}`} style={{ marginRight: '10px' }}></i>
          {header}
        </button>
      </h2>
      <div
        id={`collapse${eventKey}`}
        className="accordion-collapse collapse"
        aria-labelledby={`heading${eventKey}`}
        data-bs-parent="#accordionExample"
      >
        <div className="accordion-body"
        
        style={{ 
          border: '1px solid #F0F8FF',
             backgroundColor:'#FFF'
         
                    }}
        >
          {body}
        </div>
      </div>
    </div>
  );
};

const Faqoncpasolution = () => {
  return (

<>
    <style>{`
      @media (max-width: 767px) {
        .accordion-header {
          text-align: center; /* Center align the headings */
        }

        .accordion-button {
          font-size: 16px; /* Adjust font size for better readability */
        }
        
        .accordion-body {
          padding: 10px; /* Adjust padding if needed */
        }
      }
    `}</style>

    <div className="bsb-accordion-6 py-3 py-md-3 mt-8 py-xl-8 bg-[#f0f8ff] " >
      <div className="container ">
        <div className="row">
          <div className="col-12">
            <div>
              <h2 className="mt-55 pb-3" style={{ fontWeight: 'bold', color: '#072f5f' }}>
                FAQ on CPA Solution
              </h2>
            </div>
            <div className="accordion" id="accordionExample" style={{ border: 'none' }}>
              <AccordionItem
                eventKey="One"
                header={<span style={{ fontWeight: 'bold' }}>HOW THE ACTUAL TAX RETURN PREPARATION WORK WILL INITIATE?</span>}
                body={<p>CPA will assign the Tax Returns to be prepared via e-Mail.</p>}
                icon="bi-envelope"
              />
              
              <AccordionItem
                eventKey="Two"
                header={<span style={{ fontWeight: 'bold' }}>WILL THESE RETURNS BE ASSIGNED ONE-BY-ONE OR IN BULK?</span>}
                body={<p>This is purely dependent on the Workload of a CPA & at the same time, availability of Source Documents for preparation and completion of a particular return.</p>}
                icon="bi-card-text"
              />
             
              <AccordionItem
                eventKey="Three"
                header={<span style={{ fontWeight: 'bold' }}>TALKING ABOUT THE 'SOURCE DOCUMENTS', WHAT IS THE PROCESS OF SHARING THE SAME?</span>}
                body={<p>Knowing the sensitivity & confidentiality of the work, we opt only secure means to share these documents such as Smart Vault, FTP (File Transfer Protocol), Drop box, etc.</p>}
                icon="bi-card-text"
              />
               
               <AccordionItem
                eventKey="Four"
                header={<span style={{ fontWeight: 'bold' }}>HOW DO YOU LOCATE THESE SOURCE DOCUMENTS WITHIN A PARTICULAR PORTAL?</span>}
                body={<p>In the first mail regarding assignment of tax returns, we request you to mention the path where the Client Folder containing the source documents are located.</p>}
                icon="bi-card-text"
              />
               <AccordionItem
                eventKey="Five"
                header={<span style={{ fontWeight: 'bold' }}>HOW WILL YOU COME TO KNOW WHETHER THE AVAILABLE SOURCE DOCUMENTS ARE COMPLETE IN ALL RESPECT?</span>}
                body={<p>For this purpose we will require the Tax Return of previous year. We do a comprehensive study of Last Year’s Tax Return, which facilitates us to identify which Source Documents are missing and will be needed to complete the tax return.</p>}
                icon="bi-card-text"
              />
               <AccordionItem
                eventKey="Six"
                header={<span style={{ fontWeight: 'bold' }}>HOW WILL YOU OBTAIN THE MISSING SOURCE DOCUMENTS?"</span>}
                body={<p>Firstly, we will input all the available data in respective Tax Software and once this is done we will convey the missing documents along with open points, if any. This process will not take more than one working day.</p>}
                icon="bi-card-text"
              />
               <AccordionItem
                eventKey="Seven"
                header={<span style={{ fontWeight: 'bold' }}>WHICH TAX SOFTWARE DO YOU USE?"</span>}
                body={<p>We have already worked on Tax Software’s viz. Ultra Tax, Intuit Tax Online, ATX, CCH, Lacerte  etc. Working on any new Tax Software is never a bottleneck for BFAG as all our staffs are highly adaptable when it comes to software.</p>}
                icon="bi-card-text"
              />
               <AccordionItem
                eventKey="Eight"
                header={<span style={{ fontWeight: 'bold' }}>HOW YOU DEAL WITH THOSE TAX RETURNS FOR WHICH NO PRIOR YEAR RETURN IS AVAILABLE?</span>}
                body={<p>As CPA will be in direct contact with all the Clients, in such type of Tax Returns CPA will provide some insights on nature of client, a variety of income sources, any particular requirements by client, etc. We will take this as a base and on the same guidelines we will prepare the Tax Return.</p>}
                icon="bi-card-text"
              />
               <AccordionItem
                eventKey="Nine"
                header={<span style={{ fontWeight: 'bold' }}>HOW DO YOU DEAL WITH THE TAX RETURNS FOR WHICH NO SOURCE DOCUMENTS ARE PENDING AND NO OPEN POINTS ARE THERE?"</span>}
                body={<p> As said earlier, firstly we will input all the available source documents in respective Tax Software. After conveying the required information to CPA, he/she will ask the same from client. As soon as CPA receives all the required information from the client, it will be uploaded to secured portal and will be conveyed to us. From this point we will take it forward and complete the return & draft copy of the tax return will be uploaded to client’s folder.</p>}
                icon="bi-card-text"
              />
               <AccordionItem
                eventKey="Ten"
                header={<span style={{ fontWeight: 'bold' }}>HOW MUCH TIME THIS WHOLE PROCESS WILL TAKE?"</span>}
                body={<p> It all depends on the availability of source documents. If all the documents are in hand, return preparation will not take more than one working day. On the other hand if any documents are missing, then it all depends on the response of the client to a CPA. From our experience, generally clients do respond in one or two working days. So in such cases the whole process will take three working days.</p>}
                icon="bi-card-text"
              />
               <AccordionItem
                eventKey="Eleven"
                header={<span style={{ fontWeight: 'bold' }}>IF AT ANY POINT OF TIME CPA WANTS TO KNOW THE REAL TIME STATUS OF THEIR WORK, THEN HOW CAN HE OBTAIN THE SAME?"</span>}
                body={<p>  While dealing with numerous CPA’s, we understood that though everyone is specialized in diversified activities, their pain area is similar; Status of the Outsourced Activity. To overcome this problem, we have come forward with the simple solution of preparation of real time Activity/Status Report. This report will be prepared in Excel spreadsheet of Google Drive so that it will be accessible to both the parties (CPA & BFAG) at any point of time. This report will mention all the activities viz. Date of Assignment, Starting Date of an assignment, Completion Date of an assignment, Current status such as WIP, Missing Documents, Open Points, etc. which will give the real time status of any particular return.</p>}
                icon="bi-card-text"
              />
               <AccordionItem
                eventKey="Twelve"
                header={<span style={{ fontWeight: 'bold' }}>WHAT ABOUT THE SECURITY OF DATA AND CONFIDENTIALITY MEASURES?"</span>}
                body={<p> for details please visit our website www.bostonfagroup.com and refer our data security page.</p>}
                icon="bi-card-text"
              />
              <AccordionItem
                eventKey="Thirthin"
                header={<span style={{ fontWeight: 'bold' }}>HOW TO GET IN TOUCH?"</span>}
                // headerStyle={{ fontWeight: '700' }}
                body={<p> for details please visit our website www.bostonfagroup.com , alternatively you can also write to; bfagconnect@bostonfagroup.com / sameer.p@bostonfagroup.com or call us on +1 609 937 7291.</p>}
                icon="bi-card-text"
              />
              {/* Add more AccordionItems as needed */}
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Faqoncpasolution;
