import React from "react";
import { Link } from "react-router-dom";
import ccpalogo from "../../assets/images/NewIcon/ccpacom.png";
import abstract from "../../assets/images/products/products-abstract.png";
import law from "../../assets/images/NewIcon/law1.png";

const AccordionItem = ({ eventKey, header, body, icon }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleToggle = () => setIsOpen(!isOpen);

  return (
    <div className="accordion-item" style={{ border: 'none' }}>
      <h2 className="accordion-header">
        <button
          className={`accordion-button ${!isOpen && 'collapsed'}`}
          type="button"
          onClick={handleToggle}
          aria-expanded={isOpen}
          aria-controls={`collapse${eventKey}`}
          style={{
            border: 'none',
            borderRadius: '0.5rem',
            backgroundColor: 'transparent',
            color: 'black',
            boxShadow: 'none'
          }}
        >
          <i className={`bi ${icon} me-2`}></i>
          {header}
        </button>
      </h2>
      <div
        id={`collapse${eventKey}`}
        className={`accordion-collapse collapse ${isOpen && 'show'}`}
        data-bs-parent="#accordionExample"
        style={{ border: 'none' }}
      >
        <div className="accordion-body" style={{ backgroundColor: isOpen ? 'white' : 'transparent', border: 'none', color: 'black' }}>
          {body}
        </div>
      </div>
    </div>
  );
};

const CustomAccordion = () => {
  return (
    <div className="bsb-accordion-6 py-3 py-md-5 py-xl-8">
      <div className="container pt-5 pb-5" style={{backgroundColor:'#F0F8FF'}}>
        <div className="row">
          <div className="col-12">
            <div className="accordion pt-1 pb-1" id="accordionExample" style={{ border: 'none' }}>
              <AccordionItem
                eventKey="One"
                header="Email request."
                body={<p>You may submit your request to opt out of the sale of your personal information described above by contacting us at <a href="mailto:privacy@bostonfagroup.com" style={{ color: 'black' }}>privacy@bostonfagroup.com</a>.</p>}
                icon="bi-envelope"
              />
              <AccordionItem
                eventKey="Two"
                header="Request form."
                body={<p>You may submit a request via DSAR (<Link to="/dsarpage" style={{ color: 'black' }}>Data Subject Access Request</Link>).</p>}
                icon="bi-card-text"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CcpaPage = () => {
  return (
    <>
      <div className="mep-contracting about-experience-area dec-spacing about-experience-area-custom gray-bg dec-spacing" >
        <div className="col p-0 m-0">
          <div
            className="d-flex justify-content-center align-items-center banner-image"
            style={{
              backgroundImage: `url(${ccpalogo})`,
              backgroundSize: "cover",
              height: "430px",
            }}
          >
            <div className="d-flex flex-column justify-content-center align-items-center"></div>
          </div>
        </div>
      </div>

      <div style={{ overflow: "hidden", position: "relative" }}>
        <img
          src={abstract}
          alt="shape"
          style={{
            position: "absolute",
            overflow: "clip",
            left: "-3%",
            top: "0%",
          }}
        />

        <div className="mt-50 mb-50 container d-flex justify-content-start align-items-start">
          <div className="col-lg-4 col-md-12 imgDiv">
            <img src={law} className="img-fluid" style={{ height: "17rem" }} />
          </div>
          <div className="col-lg-8 col-md-12">
            <p
              className="text-dark text-justify mb-30"
              style={{ lineHeight: "2.5rem", fontWeight: "500" }}
            >
              Under the California Consumer Privacy Act of 2018 (“CCPA”),
              California residents have the right to opt-out of the “sale” of
              their personal information. We do not sell your personal
              information. While we do not receive financial compensation for
              your personal information, we may share it with third parties to
              personalize your experience on our site, improve our site and
              services, and so that we can engage you with interest-based
              advertising on other websites. To exercise this right, please see
              the options detailed below. Please visit our Website Privacy and
              Cookie Policy for further details about our privacy practices.
            </p>
            <h6>Submit your request via email or fill out a request form</h6>
            <br />

            <CustomAccordion />

            <p
              className="text-dark text-justify mb-30"
              style={{ lineHeight: "2.5rem", fontWeight: "500" }}
            >
              <strong> Note: </strong>Please note that we may need to request
              certain information from you to verify your identity or otherwise
              process your request. If you are making this request on behalf of
              another person, we may also require verification of their consent
              to make this request.
            </p>
          </div>
        </div>
      </div>

      <style jsx>{`
        @media (max-width: 767px) {
          .container {
            display: flex;
            flex-direction: column;
            align-items: center;
          }

          .imgDiv {
            margin-top: 20px;
            width: 100%;
            text-align: center;
          }

          .imgDiv img {
            width: 100%;
            height: auto;
          }
        }
      `}</style>
    </>
  );
};

export default CcpaPage;
