import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import howdoit from "../../../assets/images/how we do it/howwedoit.png";
import vectorleft from "../../../assets/images/how we do it/home-vector-left.png";
import vectorright from "../../../assets/images/how we do it/home-vector-right.png";
import engagement from "../../../assets/images/how we do it/engagement.jpg";
import execution from "../../../assets/images/how we do it/execution.webp";
import datasecurity002 from "../../../assets/images/how we do it/datasecurity002.png";

const HowwedoIt = () => {
  const [selectedCountry, setSelectedCountry] = useState(
    localStorage.getItem("selectedCountry") || "USA"
  );
  const [hoveredCard, setHoveredCard] = useState(null);

  const cardsData = {
    USA: [
      {
        img: execution,
        title: "EXECUTION METHODOLOGY",
        link: "/execution-methodology",
      },
      {
        img: datasecurity002,
        title: "DATA SECURITY & CONFIDENTIALITY",
        link: "/data-security",
      },
      {
        img: engagement,
        title: "ENGAGEMENT PROCESS",
        link: "/engagement-process",
      },
    ],
    India: [
      {
        img: execution,
        title: "EXECUTION METHODOLOGY",
        link: "/execution-methodology",
      },
      {
        img: datasecurity002,
        title: "DATA SECURITY & CONFIDENTIALITY",
        link: "/data-security",
      },
      {
        img: engagement,
        title: "ENGAGEMENT PROCESS",
        link: "/engagement-process",
      },
    ],
  };

  const countryCards = cardsData[selectedCountry] || cardsData["USA"];

  return (
    <>
      <div className="">
        <div
          className="d-flex justify-content-center align-items-center banner-image"
          style={{
            background: `url(${howdoit}) no-repeat center`,
            backgroundSize: "cover",
            height: "430px",
          }}
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h3
              className="text-white text-uppercase"
              style={{ fontWeight: "bold" }}
            >
              How we Do It
            </h3>
          </div>
        </div>

        <div style={{ overflow: "hidden", position: "relative" }}>
          <img
            src={vectorleft}
            alt="shape"
            style={{ position: "absolute", overflow: "clip", maxWidth: "21%", zIndex:'-2' }}
          />
          <img
            src={vectorright}
            alt="shape"
            style={{
              position: "absolute",
              overflow: "clip",
              right: "-2%",
              maxWidth: "21%",
               zIndex:'-2'
            }}
          />
          <div className="container d-flex flex-column justify-content-center align-items-center">
            <div
              className="row pt-30 justify-content-center align-items-center"
              style={{ gap: "2rem", marginBottom: "50px" }}
            >
              <div style={styles.container}>
                {countryCards.map((card, index) => (
                  <div
                    style={{
                      ...styles.card,
                      ...(hoveredCard === index && styles.cardHover),
                    }}
                    key={index}
                    onMouseEnter={() => setHoveredCard(index)}
                    onMouseLeave={() => setHoveredCard(null)}
                  >
                    <img src={card.img} alt={card.title} style={styles.image} />
                    <h3 style={styles.title}>{card.title}</h3>
                    <div style={{ textAlign: "center" }}>
                      <Link
                        to={card.link}
                        style={{
                          ...styles.button,
                          ...(hoveredCard === index && styles.buttonHover),
                        }}
                      >
                        Know More
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const styles = {
  container: {
    display: "flex",
    flexWrap: "wrap", // Allows wrapping of items in mobile view
    justifyContent: "space-around",
    padding: "20px",
    gap: "20px",
  },
  card: {
    borderRadius: "8px",
    padding: "20px",
    textAlign: "center",
    width: "250px",
    height: "330px",
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "white",
    transition: "background-color 0.3s ease",
  },
  cardHover: {
    // backgroundColor: "#E4F0F8",
  },
  image: {
    width: "100px",
    height: "100px",
    alignSelf: "center",
  },
  title: {
    fontSize: "18px",
    marginBottom: "20px",
    flex: "1",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    width: "120px",
    height: "40px",
    marginBottom: "20px",
    backgroundColor: "#002D62",
    color: "white",
    border: "none",
    borderRadius: "20px",
    cursor: "pointer",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "12px",
    margin: "0 auto",
    transition: "background-color 0.3s ease",
  },
  buttonHover: {
    backgroundColor: "black",
  },
};

// Add media queries for mobile view
const mobileStyles = {
  container: {
    flexDirection: "column", // Stack items vertically on mobile
  },
  card: {
    width: "100%", // Full width on mobile
    height: "auto", // Adjust height on mobile
    marginBottom: "20px", // Space between cards
  },
};

export default HowwedoIt;
