import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./FlipCard.css";
import { FaFileCode } from "react-icons/fa";
import technology from "../assets/FlipImages/tech.gif";
import edit from "../assets/FlipImages/wired-outline-35-edit.gif";
import search from "../assets/FlipImages/wired-outline-19-magnifier-zoom-search.gif";
import bookreader from "../assets/FlipImages/wired-outline-471-ebook-reader.gif";
import startup from "../assets/FlipImages/startup.gif";
import { Button } from "@mui/material";
import PropTypes from "prop-types";
import blogflipimg from "../assets/images/blogs/blog8.png";
import flip1 from "../assets/FlipImages/flip1.jfif";
import flip2 from "../assets/FlipImages/flip2.jfif";
import flip3 from "../assets/FlipImages/flip3.jfif";
import flip4 from "../assets/FlipImages/flip4.jfif";
import flip5 from "../assets/FlipImages/flip5.jfif";
import flip6 from "../assets/FlipImages/flip6.jfif";
import flip7 from "../assets/FlipImages/flip7.jfif";
import financial from "../assets/images/home-services/financial.png";
import taxregulatory from "../assets/images/home-services/tax regulatory.png";
import payroll from "../assets/images/home-services/payroll.png";
import consulting from "../assets/images/home-services/consulting.png";
import Startup from "../assets/images/home-services/Startup.png";

const FlipCard = ({
    title,
    description,
    backgroundImage,
    IconComponent,
    customClass,
    link,
  }) => {
    const [isFlipped, setIsFlipped] = useState(false);
  
    const handleClick = () => {
      setIsFlipped(!isFlipped);
    };
  
    return (
      <Link
        to={link}
        className="col-lg-2 col-md-4 col-sm-6 col-12 mb-4 d-flex justify-content-center wow fadeInUp widthcentre mb-5 carddiv"
        style={{ textDecoration: "none" }}
      >
        <div className={`flip-card ${customClass}`} onClick={handleClick}>
          <div className={`flip-card-inner ${isFlipped ? "flipped" : ""}`}>
            <div className="flip-card-front">
              {IconComponent && <IconComponent size={30} />}
              <h2>{title}</h2>
            </div>
            <div
              className="flip-card-back"
              style={{
                backgroundImage: `url(${backgroundImage})`,
              }}
            >
              <h2>Description</h2>
              <p>{description}</p>
              <a
                href={link}
                className="read-more-btn"
                style={{
                  backgroundColor: "#072F5F",
                  color: "white",
                  border: "none",
                  // borderRadius: '5px',
                  padding: "10px 20px",
                  cursor: "pointer",
                  textDecoration: "none",
                  marginTop: "auto",
                  fontSize: "16px",
                  transition: "background-color 0.3s",
                }}
                onMouseOver={(e) =>
                  (e.currentTarget.style.backgroundColor = "black")
                }
                onMouseOut={(e) =>
                  (e.currentTarget.style.backgroundColor = "#072F5F")
                }
              >
                Read More
              </a>
            </div>
          </div>
        </div>
      </Link>
    );
  };
  
  FlipCard.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    backgroundImage: PropTypes.string.isRequired,
    IconComponent: PropTypes.elementType,
    customClass: PropTypes.string,
    link: PropTypes.string.isRequired,
  };

const Flipcardforservicesonly = () => {
    const [selectedCountry, setSelectedCountry] = useState("USA");
  
    useEffect(() => {
      const storedCountry = localStorage.getItem("selectedCountry");
      if (storedCountry) {
        setSelectedCountry(storedCountry);
      }
    }, []);
  
    const cards = [
      {
        title: "Accounting & Transaction Processing",
        description: (
          <>
            At Accounting and Financial Solution, we provide comprehensive and
            tailored accounting solution.{" "}
          </>
        ),
        backgroundImage: require("../assets/images/home-services/financial.png"),
        IconComponent: () => (
          <img
            src={financial}
            height={170}
            width={215}
            style={{
              // borderRadius: '50%',
              marginTop: "-21px",
              objectFit: "cover",
              border: "2px solid #fff",
            }}
            alt="tech icon"
          />
        ),
        customClass: "card-1",
        link: "/accounting-and-transaction-processing",
      },
  
      {
        title: (
          "Tax & Regulatory Compliances"
        ),
        description: (
          <>
            Direct and Indirect Taxes play a pivotal role in the United States tax
            system.
          </>
        ),
        backgroundImage: require("../assets/images/home-services/consulting.png"),
        IconComponent: () => (
          <img
            src={taxregulatory}
            height={157}
            width={215}
            style={{
              marginTop: "-21px",
              objectFit: "cover",
              border: "2px solid #fff",
            }}
            alt="tech icon"
          />
        ),
        customClass: "card-2  ",
        link: "/taxregulatorycompliances",
      },
  
      {
        title: "Payroll Advisory",
        description: (
          <>
            Our philosophy for Payroll Solutions in the United States revolves
            around delivering accurate, reliable.{" "}
          </>
        ),
        backgroundImage: require("../assets/images/home-services/payroll.png"),
        IconComponent: () => (
          <img
            src={payroll}
            height={157}
            width={215}
            style={{
              marginTop: "-21px",
              objectFit: "cover",
              border: "2px solid #fff",
            }}
            alt="tech icon"
          />
        ),
        customClass: "card-3",
        link: "/payrolladvisory",
      },
  
      {
        title: "Consulting & Advisory",
        description: (
          <>
            As consultants specializing in Mergers and Acquisitions (M&A) and
            Global Structuring.
          </>
        ),
        backgroundImage: require("../assets/images/home-services/consulting.png"),
        IconComponent: () => (
          <img
            src={consulting}
            height={157}
            width={215}
            style={{
              marginTop: "-21px",
              objectFit: "cover",
              border: "2px solid #fff",
            }}
            alt="tech icon"
          />
        ),
        customClass: "card-4",
        link: "/consultingandadvisoryservices",
      },
  
      // Conditionally include this card based on selectedCountry
      ...(selectedCountry === "India"
        ? [
            {
              title: "Startup Solution",
              description: (
                <>
                  At BFAG, we believe in empowering start-ups in India by
                  providing comprehensive finance.
                </>
              ),
              backgroundImage: require("../assets/images/home-services/Startup.png"),
              IconComponent: () => (
                <img
                  src={Startup}
                  height={157}
                  width={215}
                  style={{
                    marginTop: "-21px",
                    objectFit: "cover",
                    border: "2px solid #fff",
                  }}
                  alt="tech icon"
                />
              ),
              customClass: "card-4",
              link: "/startupsolutionservices",
            },
          ]
        : []),
    ];
  
    return (
      <div style={{ backgroundColor: "#F7F8F8" }}>
        <div className="pb-4 d-flex justify-content-center align-items-center">
          <h2
            className="text-center"
            style={{ fontWeight: "bold", color: "#072f5f", marginTop: "20px" }}
          >
            OUR SERVICES
          </h2>
        </div>
        <p className="text-center unique-paragraph">
          Are you equipped to stay ahead in today’s ever-evolving global economy?
          Boston Financial Advisory Group is here to support you. As your trusted
          advisors, we help you and your organization discover the ideal blend of
          innovative solutions to maximize your potential. We offer a
          comprehensive range of services tailored to your needs, from audit and
          assurance to consulting and taxation. Your success is our ultimate goal!
        </p>
  
        <div className="flip-card-row mt-5">
          {cards.map((card, index) => (
            <FlipCard
              key={index}
              title={card.title}
              description={card.description}
              backgroundImage={card.backgroundImage}
              IconComponent={card.IconComponent}
              customClass={card.customClass}
              link={card.link}
            />
          ))}
        </div>
       
      </div>
    );
  };

export default Flipcardforservicesonly