import React from "react";
import kartik from "../../../../assets/images/teams/kartikCircle.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";

const Kartikmehta = () => {
  return (
    <div className="min-vh-100 mt-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 d-flex justify-content-center mb-3">
            <div style={{ padding: "1%" }}>
              <img
                style={{ borderRadius: "10px", width: "100%", height:'400px' }}
                className="img-fluid"
                src={kartik}
                alt="kartik"
              />
            </div>
          </div>
          <div className="col-lg-8 d-flex flex-column justify-content-start">
            <h3
              className="text-uppercase text-dark title"
              style={{ fontSize: "20px", fontWeight: 700 }}
            >
              KARTIK MEHTA
            </h3>
            <div>
              <span
                className="text-uppercase text-dark"
                style={{ fontSize: "16px", fontWeight: 600 }}
              >
                Vice President International Taxation
              </span>
              <a
                style={{
                  color: "#fff",
                  marginLeft: "10px",
                  backgroundColor: "#0072b1",
                  padding: "4px",
                  borderRadius: "5px",
                }}
                href="https://www.linkedin.com/in/kartik-mehta-848385102/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faLinkedinIn}
                  style={{ color: "#fff" }}
                />
              </a>
            </div>
            <p
              className="mt-3 text-dark text-justify"
              style={{ fontSize: "16px" }}
            >
              Kartik Mehta is a Fellow Chartered Accountant who has amassed over
              a decade of experience as a trusted advisor to businesses.
              Possessing extensive knowledge in various areas such as domestic
              tax, transfer pricing, foreign exchange regulations, and
              international tax, Kartik has successfully guided numerous
              organizations through intricate financial matters.
            </p>
            <p
              className="mt-3 text-dark text-justify"
              style={{ fontSize: "16px" }}
            >
              Currently, Kartik brings over 15 years of expertise and experience
              to Boston Financial Advisory Group, where they provide strategic
              guidance and innovative solutions to meet the diverse needs of
              clients. Committed to excellence and possessing a comprehensive
              understanding of taxation, Kartik is dedicated to helping
              businesses optimize their financial operations and achieve
              sustainable growth.
            </p>
            <p
              className="mt-3 text-dark text-justify"
              style={{ fontSize: "16px" }}
            >
              Specializing in a broad range of direct taxation aspects, Kartik
              has assisted businesses in developing tax-efficient and compliant
              structures for both domestic operations and inbound/outbound
              investments.
            </p>

            {/* <p className="mt-3 text-dark text-justify" style={{ fontSize: '16px' }}>
          He has conducted thorough tax due diligence exercises and prepared comprehensive transfer pricing policies and study reports.
          </p> */}

            <div class=" pl-0 pr-0" style={{ marginLeft: "-52%" }}>
              <p
                className="mt-3 text-dark text-justify"
                style={{ fontSize: "16px" }}
              >
                By deeply understanding taxation and regulatory frameworks,
                Kartik has facilitated cross-border transactions and ensured
                compliance with international tax obligations. He has conducted
                thorough tax due diligence exercises and prepared comprehensive
                transfer pricing policies and study reports. Leveraging in-depth
                knowledge of transfer pricing regulations, he has helped
                organizations establish effective pricing mechanisms and
                maintain compliance with global standards.
              </p>
              <p
                className="mt-3 text-dark text-justify"
                style={{ fontSize: "16px" }}
              >
                He has active participation in various Committees of
                professional associations, contributing significantly to the
                advancement of the field. Kartik strives to contribute to the
                professional community and keep businesses informed of the
                latest developments in the tax landscape.
              </p>
              {/* <p className="mt-3 text-dark text-justify" style={{ fontSize: '16px' }}>
          Amit Mulgund's remarkable journey in the finance industry has shaped him into a highly respected professional. By leveraging his extensive experience and understanding of the financial landscape, Amit continues to create a positive impact and pave the way for financial success.
          </p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Kartikmehta;
