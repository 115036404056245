import React from 'react'
import ganesh from '../../../../assets/images/teams/ganesh.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

const Ganeshpadakannya = () => {
  return (
    <div className="min-vh-100 mt-5">
    <div className="container">
      <div className="row">
        <div className="col-lg-4 d-flex justify-content-center mb-3">
          <div style={{ padding: '1%' }}>
            <img
              style={{ borderRadius: '10px', width: '350px', height:'420px' }}
              className="img-fluid"
              src={ganesh}
              alt="ganesh"
            />
          </div>
        </div>
        <div className="col-lg-8 d-flex flex-column justify-content-start">
          <h3 className="text-uppercase text-dark title" style={{ fontSize: '20px', fontWeight: 700 }}>
          GANESH PADAKANNAYA
          </h3>
          <div>
            <span className="text-uppercase text-dark" style={{ fontSize: '16px', fontWeight: 600 }}>
            Chief Operating Officer
            </span>
            <a
              style={{ color: '#fff', marginLeft: '10px', backgroundColor: '#0072b1', padding: '4px', borderRadius: '5px' }}
              href="https://www.linkedin.com/in/ganesh-padakannaya-2b739516/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faLinkedinIn} style={{ color: '#fff' }} />
            </a>
          </div>
          <p className="mt-3 text-dark text-justify" style={{ fontSize: '16px' }}>
          Ganesh is a target-driven Chartered Accountant and Company Secretary with a proven track record in finance, accounting, and business advisory. A seasoned professional with over 16 years of experience.          </p>
          <p className="mt-3 text-dark text-justify" style={{ fontSize: '16px' }}>
          As the COO at Boston Financial Advisory Group since December 2009, Ganesh has been instrumental in developing the M&A disposition model and successfully delivering efficient F&A processes. He has showcased his expertise in planning, organizing, and implementing strategies that drive business valuation, business deals, and business structuring. Additionally, his management consultancy skills have helped numerous clients achieve their financial goals.<br/>
          </p>
          <p className="mt-3 text-dark text-justify" style={{ fontSize: '16px' }}>
          Ganesh's expertise extends beyond traditional financial roles. As an International Finance Manager at a BSE-listed software company, he gained valuable experience in working with different jurisdictional GAAP and consolidation requirements. This exposure allowed him to navigate various legal setups and critically analyze business models.
          </p>
          <p className=" text-dark text-justify" style={{ fontSize: '16px' }}>
          His knowledge of taxation and assurance has further enhanced his ability to perform meticulous due diligence practices.

          </p>
          <div class=" pl-0 pr-0" style={{marginLeft:'-52%'}} >
         
          <p className="mt-3 text-dark text-justify" style={{ fontSize: '16px' }}>
          Driven by a passion for achieving results, the focus on meticulous planning has been instrumental in his career. He holds the prestigious titles of Chartered Accountant and Law Graduate, highlighting his strong educational background and professional qualifications. With a strong focus on planning, organizing, and implementation skills, successfully provided management consultancy services to multiple clients. Have keen eye for business valuation and excels at proposing and negotiating budgets, ensuring quality and cost control within the organization.
          </p>
          {/* <p className="mt-3 text-dark text-justify" style={{ fontSize: '16px' }}>
            As a visionary and dynamic leader with extensive experience in leadership, team building, and technology advancement, Sameer has a wealth of expertise in all these areas. He consistently inspires and motivates his teams to reach their full potential and fosters a collaborative and inclusive work environment, encouraging innovation and embracing technological advancements to drive business success. His exceptional ability to build and nurture high-performing teams has been instrumental in the group's continued growth and triumph. Sameer’s profound knowledge of compliance and financial management, combined with his strategic acumen, has left an indelible mark on the Boston Financial Advisory Group and the industry.
          </p> */}
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Ganeshpadakannya
