import React from "react";

const AccordionItem = ({ eventKey, header, body, icon }) => {
  return (
    <div className="accordion-item" style={{ marginBottom: "20px" }}>
      <h2 className="accordion-header" id={`heading${eventKey}`}>
        <button
          className="accordion-button"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#collapse${eventKey}`}
          aria-expanded="true"
          aria-controls={`collapse${eventKey}`}
          style={{
            color: "#002249",
            border: "1px solid #F0F8FF",
            backgroundColor: "#F0F8FF",
          }}
        >
          <i className={`bi ${icon}`} style={{ marginRight: "10px" }}></i>
          {header}
        </button>
      </h2>
      <div
        id={`collapse${eventKey}`}
        className="accordion-collapse collapse"
        aria-labelledby={`heading${eventKey}`}
        data-bs-parent="#accordionExample"
      >
        <div
          className="accordion-body"
          style={{
            border: "1px solid #F0F8FF",
            backgroundColor: "#FFF",
          }}
        >
          {body}
        </div>
      </div>
    </div>
  );
};

const Faquaeentitysetup = () => {
  return (
    <div className="bsb-accordion-6 py-3 py-md-5 py-xl-8">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div>
              <h2
                className="mt-55 pb-3"
                style={{ fontWeight: "bold", color: "#072f5f" }}
              >
                FAQ ON SETTING UP AN ENTITY IN DUBAI, UAE
              </h2>
            </div>
            <div
              className="accordion"
              id="accordionExample"
              style={{ border: "none" }}
            >
              <AccordionItem
                eventKey="One"
                header={
                  <span style={{ fontWeight: "bold" }}>
                    Why choose UAE to incorporate a company?
                  </span>
                }
                body={
                  <p>
                    {" "}
                    The UAE, and Dubai particularly, has, for good reason,
                    garnered a lot of media attention recently. Dubai enjoys a
                    central location between Europe, Africa, and Asia. This
                    makes it a prime business destination for many sectors and
                    industries. You will not find a city as cosmopolitan or as
                    advanced anywhere within a five-hour flight from Dubai. The
                    city’s highly regulated environment adheres to international
                    best practices operating with minimal rates for personal and
                    corporate taxes. Businesses in Dubai enjoy 100% capital
                    repatriation with no currency restrictions. This level of
                    financial freedom is rarely found in areas with such a
                    developed business environment. One can find numerous
                    opportunities in the UAE and across a wide range of sectors.
                    Dubai is a leading commercial center with state-of-the-art
                    infrastructure and outstanding business environment. Other
                    reasons include:
                    <li>
                      A liberal economy fully integrated with the world economy.
                    </li>
                    <li>
                      The city is central, located between Europe, Asia, and
                      Africa, attracting approximately five million investors
                      and tourists each year.
                    </li>
                    <li>One of the safest cities in the world.</li>
                    <li>
                      More than 200 nationalities live in harmony and call Dubai
                      their home.
                    </li>
                    <li>
                      Dubai is well-serviced by international and local
                      financial institutions.
                    </li>
                    <li>
                      The education system in Dubai is suitable for all cultures
                      and languages.
                    </li>
                    <li>Dubai enjoys world class healthcare.</li>
                  </p>
                }
                icon="bi-envelope"
              />

              <AccordionItem
                eventKey="Two"
                header={
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    What types of UAE companies can I start?
                  </span>
                }
                body={
                  <p>
                    {" "}
                    In the UAE, companies can be started as mainland businesses,
                    free zone, or offshore businesses. For a business setup in
                    the UAE mainland, a local service agent is required, and the
                    license is provided by the Department of Economic Department
                    in Dubai. In a free zone, you can operate within a specific
                    geographical region & outside UAE without local service
                    agent, Tax-free policies for both corporate and income tax
                    and the business is licensed by the respective free zone
                    authority.
                    <br />
                    Note that you can enjoy 100% foreign ownership of your
                    business in both these business zones, i.e., mainland and
                    free zones. The company formation experts at BFAG help you
                    incorporate your dream venture in the UAE by taking care of
                    licensing, documentation, translation work, and much more.
                    <br />
                    An offshore business in UAE allows you to safeguard your
                    wealth and assets, but you can only trade outside UAE. A
                    certificate of incorporation is provided to the company and
                    not a trade license.
                    <br />
                    Mostly all types of companies can be started in the UAE and
                    there are more than thousands of activities registered and
                    allowed by the Economic Department. Yet, it is best to be
                    sure before getting started.
                  </p>
                }
                icon="bi-card-text"
              />

              <AccordionItem
                eventKey="Three"
                header={
                  <span style={{ fontWeight: "bold" }}>
                    How to start an LLC in Dubai?
                  </span>
                }
                body={
                  <p>
                    <p class="pb-15">
                      To establish a limited liability company in Dubai Mainland
                      or anywhere else in the UAE, you need to reserve a trade
                      name and obtain preliminary approvals. You also need to
                      get a memorandum of association of the company and submit
                      the lease contract and other necessary documents to the
                      Department of Economic Development.
                      <br />
                      You will also need to partner with a UAE national and may
                      require additional certifications from authorities, a
                      rented office space also is a must. With BFAG, we can
                      guide you through full setup and you can start a business
                      in Dubai and relax about the requirements.
                    </p>
                  </p>
                }
                icon="bi-card-text"
              />

              <AccordionItem
                eventKey="Four"
                header={
                  <span style={{ fontWeight: "bold" }}>
                    What are the benefits of a business setup in mainland UAE?
                  </span>
                }
                body={
                  <p>
                    <p class="pb-15">
                      As a mainland UAE company, you can do business with any
                      country and trade freely. You get access to affordable
                      rental office spaces as you are not geographically
                      limited, compared to free zone companies who can rent
                      office space only inside the free zones and have limited
                      business scope.
                    </p>
                  </p>
                }
                icon="bi-card-text"
              />
              <AccordionItem
                eventKey="Five"
                header={
                  <span style={{ fontWeight: "bold" }}>
                    What is a free zone?
                  </span>
                }
                body={
                  <p>
                    {" "}
                    <p class="pb-15">
                      Free Zones are areas designated for international
                      businesses & low-cost setup, which enables 100% foreign
                      ownership. Companies in each free zone can trade outside
                      UAE as well as trade with business in UAE with limited
                      scope.
                    </p>
                  </p>
                }
                icon="bi-card-text"
              />
              <AccordionItem
                eventKey="Six"
                header={
                  <span style={{ fontWeight: "bold" }}>
                    What are the benefits of a business setup in UAE free zones?
                  </span>
                }
                body={
                  <p>
                    {" "}
                    <ul>
                      As a freezone company, you can have full ownership of your
                      company with low-cost set-ups and get access to free-hold
                      property anywhere in Dubai. Not all but some of the free
                      zone business setups in Dubai are exempted from paying
                      taxes and import or export duties.
                    </ul>
                  </p>
                }
                icon="bi-card-text"
              />
              <AccordionItem
                eventKey="Seven"
                header={
                  <span style={{ fontWeight: "bold" }}>
                    How much time does it take to start a business in Dubai?
                  </span>
                }
                body={
                  <p>
                    {" "}
                    With BFAG, company formation in Dubai Mainland or Free Zone
                    can take around 8-10 business days, however, it is a must
                    that you have the right documents with you. Being trusted
                    partners and over 15 years of association with the Free
                    Zones & Department of Economic Development in Dubai, we can
                    establish businesses in UAE 8-10 business days.
                  </p>
                }
                icon="bi-card-text"
              />
              <AccordionItem
                eventKey="Eight"
                header={
                  <span style={{ fontWeight: "bold" }}>
                    What are the types of UAE business license or trade licenses
                    in Dubai?
                  </span>
                }
                body={
                  <p>
                    {" "}
                    There are four types of Business License or Trade Licenses
                    in the UAE; Commercial License –issued to a company engaged
                    in any kind of trading activity, Industrial License –issued
                    to a company engaged any kind of manufacturing or industrial
                    activity, Professional License – issued to service
                    providers, artisans, professionals and craftsmen and the
                    last one is Tourism License – issued to travel, tourism and
                    hospitality companies in the UAE.
                  </p>
                }
                icon="bi-card-text"
              />
              <AccordionItem
                eventKey="Nine"
                header={
                  <span style={{ fontWeight: "bold" }}>
                    What are the documents required for business license renewal
                    in UAE?
                  </span>
                }
                body={
                  <p>
                    To renew your trade license, you need to provide a
                    certificate of authenticity for the lease contract issued
                    from the Real Estate Regulatory Agency as well as a new
                    Memorandum of Association, with the necessary modifications.
                  </p>
                }
                icon="bi-card-text"
              />
              <AccordionItem
                eventKey="Ten"
                header={
                  <span style={{ fontWeight: "bold" }}>
                    Do I need to visit the UAE to incorporate the company?
                  </span>
                }
                body={
                  <p>
                    {" "}
                    We can incorporate offshore companies without needing your
                    physical presence. However, in the event of incorporation in
                    the free zones and onshore jurisdictions, we can start the
                    process without you, but your presence will eventually be
                    required. You are expected to sign formal incorporation
                    documents at the notary public here in the UAE.
                    <br />
                    However, if you were to provide us with a power of attorney,
                    certified and attested by a UAE embassy or consulate abroad,
                    we can complete the incorporation procedures without
                    requiring you to visit the UAE.
                  </p>
                }
                icon="bi-card-text"
              />
              <AccordionItem
                eventKey="Eleven"
                header={
                  <span style={{ fontWeight: "bold" }}>
                    Do I require a residence visa?
                  </span>
                }
                body={
                  <p>
                    {" "}
                    Anyone wishing to live and work in the UAE needs a residence
                    visa. To employ staff in your organization, a manager of the
                    entity must have a residence visa. This will then enable the
                    company to recruit staff. Offshore incorporations do not
                    require a residence visa as they do not entitle the holder
                    to do business in the UAE. The free zone incorporations
                    allow for issuing residence visas depending upon the size of
                    the rented or purchased premises of the establishment.
                  </p>
                }
                icon="bi-card-text"
              />
              <AccordionItem
                eventKey="Twelve"
                header={
                  <span style={{ fontWeight: "bold" }}>
                    How can I open a company’s bank account in Dubai?
                  </span>
                }
                body={
                  <p>
                    {" "}
                    Opening a bank account in the UAE can be challenging due to
                    the Anti-Money Laundering and Combatting Financing of
                    Terrorism (AML-CFT) laws. UAE residents with a residence
                    visa find it easier to open an account, while non-residents
                    face additional KYC procedures. Offshore bank accounts in
                    the UAE offer advantages such as convenience, wealth
                    management services, and easy international payments. The
                    AML-CFT laws have increased due diligence requirements,
                    including background checks on shareholders, scrutiny of
                    high-risk business activities, verification of physical
                    office addresses, and checks on business dealings with
                    high-risk countries and politically exposed persons. We can
                    assist with navigating these challenges and opening a bank
                    account in UAE.
                  </p>
                }
                icon="bi-card-text"
              />
             
              {/* Add more AccordionItems as needed */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faquaeentitysetup;
