import React from 'react';
import casestudybanner from '../../../assets/images/case-studies/case-study-main-banner.png';
import productabstract from '../../../assets/images/products/products-abstract.png';
import complience from '../../../assets/images/products/complience.png';

const Complianceinbox = () => {
  return (
    <div className="">
      <div
        className="d-flex justify-content-center align-items-center banner-image"
        style={{
          background: `url(${casestudybanner}) no-repeat center`,
          backgroundSize: 'cover',
          height: '430px',
        }}
      >
        <div className="d-flex flex-column justify-content-center align-items-center">
          <h3 className="text-white" style={{fontWeight:'bold'}}>COMPLIANCE iNBOX (CiB)</h3>
        </div>
      </div>
      <div style={{ overflow: 'hidden', position: 'relative' }}>
        <img 
          src={productabstract} 
          alt="shape" 
          style={{ 
            position: 'absolute', 
            overflow: 'clip', 
            left: '-3%', 
            top: '0%' 
          }} 
        />
        <div className="mt-50 mb-50 container d-flex justify-content-start align-items-start">
          <div className="col-lg-4 col-md-12 justify-content-center align-items-center imgDiv">
            <img 
              src={complience} 
              className="img-fluid" 
              style={{ height: '23rem' }} 
            />
          </div>
          <div className="col-lg-8 col-md-12">
            <p 
              className="text-dark mb-30 text-justify" 
              style={{ 
                lineHeight: '2.5rem', 
                fontWeight: 500 
              }}
            >
              COMPLIANCE iNBOX is an intuitive solution developed to address this very pressing need to simplify COMPLIANCE management across the organization. Developed by a team of practicing CAs, CPAs, and financial stalwarts from Boston Financial Advisory Group (BFAG), COMPLIANCE iNBOX brings to you the promise of timely and accurate COMPLIANCE without the typical cost and hassle associated with it.
            </p>
            <div className="navbar-btn contactUs-btn cursor-pointer">
              <a 
                href="https://complianceinbox.com/" 
                target="_blank" 
                className="text-white btn d-sm-mobile rounded contactBtn" 
                style={{ background: '#072F5F' }}
              >
                Learn More
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Complianceinbox;
