import React from "react";
import { Link } from "react-router-dom";
import cpasolution from "../../assets/images/CPA back-office/CPAsolutions1.png";
import abstractleft from "../../assets/images/teams/abstract-images/P-abstract-left.png";
import abstractright from "../../assets/images/teams/abstract-images/P-abstract-right.png";
import CPAsolutions4 from "../../assets/images/CPA back-office/CPAsolutions4.png";
import tax from "../../assets/images/CPA back-office/tax.png";
import cpasolution3 from "../../assets/images/CPA back-office/CPAsolutions3.png";
import "../Service/service.css";
import videoBackground from "../../assets/video/boston baneer video.mp4";
import { Button } from '@mui/material'; 

const Cpabackoffice = () => {
  return (
    <>
        {/* <div
            className="banner-wrapper"
            style={{ position: "relative", height: "450px" }}
          >
            <video
              className="banner-video"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              src={videoBackground}
              autoPlay
              loop
              muted
            />
            <div
              className="banner-overlay"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            >
              <div className="container-fluid trdBannar h-100">
                <div className="row h-100 d-flex align-items-center">
                  <div className="col-lg-6">
                    <div className="banner-content text-white">
                      <h1
                        className="title"
                        style={{
                          animation: "fadeInLeft 0.5s",
                          animationDelay: "0.5s",
                          fontWeight: "bold",
                          color: "#fff",
                        }}
                      >
                        <a  style={{fontSize:'30px' , justifyContent:'center'}}>CPA Back Office </a>
                        <br />
                      </h1>
                      <li
                        className="pt-5 "
                        style={{
                          listStyleType: "none",
                          animation: "fadeInLeft 1.3s",
                          animationDelay: "1.3s",
                        }}
                      >
                        
                      </li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

<div
          className="d-flex justify-content-center align-items-center banner-image"
          style={{
            background: `url(${cpasolution}) no-repeat center`,
            backgroundSize: "cover",
            height: "430px",
          }}
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h3
              className="text-white text-uppercase"
              style={{ fontWeight: "bold" }}
            >
            CPA BACK OFFICE
            </h3>
          </div>
        </div>

      <div
        className="portfolio-3-area custom-portfolio-css pt-115 pb-120 dec-spacing d-flex flex-column justify-content-center align-items-center"
        style={{ overflow: "hidden", position: "relative" }}
      >
        <img
          src={abstractleft}
          alt="shape"
          style={{
            position: "absolute",
            overflow: "clip",
            left: "0%",
            bottom: "0%",
            width: "22%",
            zIndex:'-2'
          }}
        />
        <img
          src={abstractright}
          alt="shape"
          style={{
            position: "absolute",
            overflow: "clip",
            right: "0%",
            bottom: "0%",
            width: "22%",
          }}
        />

        <div className="">
          <div className="container-fluid">
            {/* <div className="pb-4 d-flex justify-content-center align-items-center">
          <h3 className="text-center" style={{fontWeight:'bold', color: '#072f5f',}}>CPA BACK OFFICE</h3>
        </div> */}
            <div className="row justify-content-center" style={{ gap: "7rem" }}>
              {/* <!-- Card 1 --> */}

              <div
      className="col-lg-2 col-md-4 col-sm-6 col-12 mb-4 d-flex justify-content-center wow fadeInUp widthcentre mb-5 carddiv"
      data-wow-duration="1s"
      data-wow-delay=".2s"
    >
      <Link
        to="/auditsupport"
        className="shadow"
        style={{ textDecoration: "none" }}
      >
        <div
          className="custom-card text-center"
          style={{ width: "240px", height: "345px", ...styles.card }}
        >
          <img
            src={CPAsolutions4}
            alt="Accounting & Transaction Processing"
            className="img-fluid"
          />
          <div className="mt-3 d-flex flex-column justify-content-between align-items-center inddiv">
            <h4 className="service-title">Audit Support</h4>
            <Button
              className='buttonUnique'
              variant="contained"
              color="primary"
              style={{ backgroundColor: '#072f5f', color: 'white' }}
            >
              Read more
            </Button>
            <div className="blue-line"></div>
          </div>
        </div>
      </Link>
      {/* <style>
        {`
          .custom-card:hover {
            background-color: #d8eafd; 
          }
        `}
      </style> */}
    </div>
              {/* <!-- Card 2 --> */}
              <div
                className="col-lg-2 col-md-4 col-sm-6 col-12 mb-4 d-flex justify-content-center wow fadeInUp widthcentre mb-5 carddiv"
                data-wow-duration="1s"
                data-wow-delay=".6s"
              >
                <Link
                to='/taxreturnpreparation'
                  className="read-more-link"
                  style={{ textDecoration: "none" }}
                  // routerLink="/income-tax"
                >
                  <div
                    className="custom-card text-center"
                    style={{ width: "240px", height: "345px" }}
                  >
                    <img
                      src={tax}
                      alt="Tax & Regulatory Compliances"
                      className="img-fluid"
                    />
                    <div className="mt-3 d-flex flex-column justify-content-between align-items-center inddiv">
                      <h4 className="service-title">Tax Preparation</h4>
                      <Button
              className='buttonUnique'
              variant="contained"
              color="primary"
              style={{ backgroundColor: '#072f5f', color: 'white' }}
            >Read more</Button>

                      <div className="blue-line"></div>
                    </div>
                  </div>
                </Link>
              </div>

              {/* <!-- Card 3 --> */}
              <div
                className="col-lg-2 col-md-4 col-sm-6 col-12 mb-4 d-flex justify-content-center wow fadeInUp widthcentre mb-5 carddiv"
                data-wow-duration="1s"
                data-wow-delay=".4s"
              >
                <Link
                to='/accounting'
                  className="read-more-link"
                  style={{ textDecoration: "none" }}
                  // routerLink="/payroll-processing"
                >
                  <div
                    className="custom-card text-center"
                    style={{ width: "240px", height: "345px" }}
                  >
                    <img
                      src={cpasolution3}
                      alt="Payroll Advisory"
                      className="img-fluid"
                    />
                    <div className="mt-3 d-flex flex-column justify-content-between align-items-center inddiv">
                      <h4 className="service-title">Accounting</h4>
                      <a style={{textDecoration:'none'}}
                        className="read-more-link"
                        routerLink="/payroll-advisory"
                      >
                        
                        <Button
              className='buttonUnique'
              variant="contained"
              color="primary"
              style={{ backgroundColor: '#072f5f', color: 'white' }}
            >
              Read More
            </Button>
                      </a>
                      <div className="blue-line"></div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

    
  );
};

const styles = {
  card: {
    transition: 'background-color 0.3s ease',
  },
};

export default Cpabackoffice;
