import React from 'react';
import datasecurity from '../../../../assets/images/how we do it/dataSecurity1.png';

const Datasecurityandconfidentiality = () => {
  return (
    <div className="">
      <style>
        {`
          .nav-link,
          .nav-link.active,
          .nav-link:hover {
            text-decoration: none;
          }
        `}
      </style>
      <div className="container-fluid divmart d-flex flex-column justify-content-center align-items-center">
        <div className="row d-flex flex-column justify-content-center align-items-center">
          <div className="col-lg-12 d-flex flex-column justify-content-center align-items-center mt-4">
            <div>
              <h3
                className="text-center"
                style={{ borderBottom: '4px solid #072F5F', display: 'inline-block', color:'#072f5f', fontWeight:'bold' }}
              >
                DATA SECURITY AND CONFIDENTIALITY
              </h3>
            </div>
            <div className="mb-5" style={{ marginTop: '3rem' }}>
              <img
                className="img-fluid"
                style={{ borderRadius: '15px', width:'700px' }}
                src={datasecurity}
                alt="data_security"
              />
            </div>
          </div>
          <div className="col-lg-12">
            <p className="text-dark text-justify">
              Financial data sharing, with us it is secure and seamless. Whether it is a physical document or digital files or the software you use for financial accounting / reporting, we have well defined standard protocols that ensure that you share with us in the most secured way. We are ISO 27001 and ISO 9001 certified company which follows information security standards, the person in charge of our IT is a Microsoft and CISCO certified professional.
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="col-12 container-fluid pt-50 pb-3 pr-0 ml-0 mr-0">
          <ul className="justify-content-between nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li className="nav-item col-lg-6 pl-0 pr-0" role="presentation">
              <button
                className="nav-link active border-0 text-white buttonInd mr-2"
                id="pills-home-tab"
                data-toggle="pill"
                data-target="#pills-home"
                type="button"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
                style={{ background: '#072F5F', borderRadius: 20, width: '100%' }}
              >
                A. The client can share the document in following ways
              </button>
            </li>
            <li className="nav-item col-lg-6 pl-0 pr-0" role="presentation">
              <button
                className="nav-link border-0 text-white buttonInd mr-2"
                id="pills-profile-tab"
                data-toggle="pill"
                data-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
                style={{ background: '#072F5F', borderRadius: 20, width: '100%' }}
              >
                B. What BFAG does on receipt of a document
              </button>
            </li>
          </ul>
          <div className="col-12 pl-0 ml-0 pr-0 mr-0 tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              <ul className="text-dark " style={{ lineHeight: '2rem' }}>
                <li>
                  <i className="fas fa-arrow-circle-right mr-2" style={{ color: '#072F5F', marginTop:'0.5rem' }}></i> Client can save the documents on a predefined path on client server (when working on client’s environment)
                </li>
                <li>
                  <i className="fas fa-arrow-circle-right mr-2" style={{ color: '#072F5F', marginTop:'0.5rem' }}></i> Client can save the documents on cloud storage (when working on BFAG environment)
                </li>
                <li>
                  <i className="fas fa-arrow-circle-right mr-2" style={{ color: '#072F5F', marginTop:'0.5rem' }}></i> Share thru email, fax or CamScanner mobile application
                </li>
                <li>
                  <i className="fas fa-arrow-circle-right mr-2" style={{ color: '#072F5F', marginTop:'0.5rem' }}></i> Bank, Customer, Vendor can send documents directly to BFAG at a predefined email id
                </li>
                <li>
                  <i className="fas fa-arrow-circle-right mr-2" style={{ color: '#072F5F', marginTop:'0.5rem' }}></i> BFAG can download from third party portals directly in case instructions are given
                </li>
              </ul>
            </div>
            <div
              className="tab-pane fade"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              <ul className="text-dark" style={{ lineHeight: '2rem' }}>
                <li>
                  <i className="fas fa-arrow-circle-right mr-2" style={{ color: '#072F5F', marginTop:'0.5rem' }}></i> Verify the document and revert back with query if any
                </li>
                <li>
                  <i className="fas fa-arrow-circle-right mr-2" style={{ color: '#072F5F', marginTop:'0.5rem' }}></i> Scheduling the document to respective process associate
                </li>
                <li>
                  <i className="fas fa-arrow-circle-right mr-2" style={{ color: '#072F5F', marginTop:'0.5rem' }}></i> Document coding and entry into the F & A system
                </li>
                <li>
                  <i className="fas fa-arrow-circle-right mr-2" style={{ color: '#072F5F', marginTop:'0.5rem' }}></i> Saving the document on the predefined path
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Datasecurityandconfidentiality;