import React from 'react';
import hit from '../../../assets/images/media hits/media.png'

const Newsletters = () => {
//   const newsletters = [
//     {
//       imgFile: { secure_url: '../../../../assets/images/media hits/media.png' },
//       title: 'Title of Newsletter',
//       createdAt: new Date(),
//       shortDesc: 'Short description of the newsletter content.',
//       _id: 'newsletter-id-1', // Example ID
//     },
  
//   ];

  return (
    <section className="">
      <div className="">
        <div
          className="d-flex justify-content-center align-items-center banner-image"
          style={{
            background: `url(${hit}) no-repeat center`,
            height: '430px',
            backgroundSize: 'cover',
          }}
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h3 className="text-white text-uppercase" style={{fontWeight:'bold'}}>NewsLetters</h3>
          </div>
        </div>
        <div className="container pt-4">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div className="col-lg-12 mb-100">
              <div className="mb-30 text-center" style={{fontWeight:'bold'}}>
                <h3>All Time News Letters</h3>
              </div>
              {((news) => (
                <div
                  key={news._id}
                  className="d-flex flex-column justify-content-center align-items-center shadow mb-30"
                  style={{
                    background: `url(${news.imgFile.secure_url}) no-repeat center`,
                    backgroundSize: 'cover',
                    borderRadius: '17px',
                  }}
                >
                  <div className="row d-flex justify-content-between pl-3 pr-3" style={{ minWidth: '100%' }}>
                    <div className="bg-white mt-30" style={{ borderRadius: '40px', padding: '20px 35px' }}>
                      <h4>{news.title}</h4>
                    </div>
                    <span
                      className="bg-transparent border text-white d-flex justify-content-center align-items-center mt-30"
                      style={{ borderRadius: '40px', width: '12rem' }}
                    >
                      {news.createdAt.toLocaleDateString()} {/* Assuming createdAt is a Date object */}
                    </span>
                  </div>
                  <div
                    className="bg-white text-dark border-0 p-3 text-left"
                    style={{
                      maxWidth: '100%',
                      borderRadius: '0 0 17px 17px',
                      marginTop: '24rem',
                      fontSize: '21px',
                      fontWeight: '600',
                    }}
                  >
                    {news.shortDesc}{' '}
                    <a style={{ color: '#0951A4' }} href={`/newsletter-details/${news._id}`}>
                      Read More.....
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Newsletters;
