import React from "react";

const AccordionItem = ({ eventKey, header, body, icon }) => {
  return (
    <div className="accordion-item" style={{ marginBottom: "20px" }}>
      <h2 className="accordion-header" id={`heading${eventKey}`}>
        <button
          className="accordion-button"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#collapse${eventKey}`}
          aria-expanded="true"
          aria-controls={`collapse${eventKey}`}
          style={{
            color: "#002249",
            border: "1px solid #F0F8FF",
            backgroundColor: "#F0F8FF",
          }}
        >
          <i className={`bi ${icon}`} style={{ marginRight: "10px" }}></i>
          {header}
        </button>
      </h2>
      <div
        id={`collapse${eventKey}`}
        className="accordion-collapse collapse"
        aria-labelledby={`heading${eventKey}`}
        data-bs-parent="#accordionExample"
      >
        <div
          className="accordion-body"
          style={{
            border: "1px solid #F0F8FF",
            backgroundColor: "#FFF",
          }}
        >
          {body}
        </div>
      </div>
    </div>
  );
};
const Faqusaentitysetup = () => {
  return (
    <div className="bsb-accordion-6 py-3 py-md-5 py-xl-8">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div>
              <h2
                className="mt-55 pb-3"
                style={{ fontWeight: "bold", color: "#072f5f" }}
              >
                FAQ on Setting Up an Entity in the USA{" "}
              </h2>
            </div>
            <div
              className="accordion"
              id="accordionExample"
              style={{ border: "none" }}
            >
              <AccordionItem
                eventKey="One"
                header={
                  <span style={{ fontWeight: "bold" }}>
                    What are the different types of business entities in the
                    USA?
                  </span>
                }
                body={
                  <p>
                    In USA, common types of business entities include:
                    <li>
                      <b>a) Sole Proprietorship: </b>A sole proprietorship is
                      the simplest and most common structure chosen to start a
                      business. A business owned and operated by one individual.
                      A sole proprietor is someone who owns an unincorporated
                      business by himself or herself. The owner is personally
                      responsible for all business debts.
                    </li>
                    <li>
                      <b>b) Partnership: </b>A partnership exists when two or
                      more persons co-own a business and share in the profits
                      and losses of the business. Each of the co-owners or
                      partners contribute something, usually money or real
                      property, to the business endeavour.
                    </li>
                    <li>
                      <b>c) Corporation: </b>A corporation is an independent
                      legal entity that exists separately from the people who
                      own, control and manage it. It does not dissolve when its
                      owners (or shareholders) die because it is considered a
                      separate “person.” A corporation can enter into contracts,
                      pay taxes, transact business, etc. The owners have limited
                      liability. A legal entity separates from its owners,
                      offering limited liability protection.
                    </li>
                    <li>
                      <b>d) Limited Liability Company (LLC): </b>This is not a
                      partnership or a corporation but rather is a distinct type
                      of entity that has the powers of both a corporation and a
                      partnership. Depending on how the LLC is structured, it
                      may be likened to a general partnership with limited
                      liability, to a limited partnership where all the owners
                      are free to participate in the management and all have
                      limited liability, or to an “S” corporation without the
                      ownership and tax restrictions imposed by the Internal
                      Revenue Code.
                    </li>
                    <li>
                      <p>
                        <b>Difference between S’ corp. and C’ corp:</b>
                      </p>
                      <p>
                        C-Corp: For federal tax purposes, C corporation profits
                        are taxed, and are reported on the corporation tax
                        return. Any after-tax profits distributed to
                        shareholders as dividends are taxed again (double
                        taxation) and are reported by the shareholders on their
                        personal tax returns.
                      </p>
                      <p>
                        {" "}
                        S-Corp: An S corp. is treated like a sole proprietorship
                        or a partnership. The profits (or losses) are passed
                        through the S corp. to the shareholders and are only
                        taxed to the shareholders and reported on their personal
                        tax returns.{" "}
                      </p>
                    </li>
                  </p>
                }
                icon="bi-envelope"
              />

              <AccordionItem
                eventKey="Two"
                header={
                  <span style={{ fontWeight: "bold" }}>
                    What is the process for setting up a business entity in the
                    USA?
                  </span>
                }
                body={
                  <p>
                    The process for setting up a business entity in the USA
                    typically involves the following steps:
                    <li>
                      a) Choose a business name and check its availability.
                    </li>
                    <li>
                      b) Determine the state where you want to establish your
                      entity.
                    </li>
                    <li>
                      c) Register your business with the state's Secretary of
                      State or appropriate agency.
                    </li>
                    <li>
                      d) Obtain an Employer Identification Number (EIN) from the
                      Internal Revenue Service (IRS).
                    </li>
                    <li>
                      e) Comply with any additional state or local requirements,
                      such as obtaining licenses or permits.
                    </li>
                    <li>
                      f) Consider consulting with an attorney or business
                      advisor for guidance throughout the process.
                    </li>
                  </p>
                }
                icon="bi-card-text"
              />

              <AccordionItem
                eventKey="Three"
                header={
                  <span style={{ fontWeight: "bold" }}>
                    What are the resources available to help with setting up a
                    business entity in the USA?
                  </span>
                }
                body={
                  <p>
                    Several resources are available to assist with setting up a
                    business entity in the USA, including:
                    <li>
                      a) State-specific websites and Secretary of State offices.
                    </li>
                    <li>
                      b) Small Business Administration (SBA) and its local
                      offices.
                    </li>
                    <li>
                      c) Local chambers of commerce or business development
                      centres.
                    </li>
                    <li>
                      d) Legal and business consulting services specializing in
                      entity formation.
                    </li>
                  </p>
                }
                icon="bi-card-text"
              />

              <AccordionItem
                eventKey="Four"
                header={
                  <span style={{ fontWeight: "bold" }}>
                    Where should I register my Company in USA?
                  </span>
                }
                body={
                  <p>
                    <p>
                      <b>US Residents</b>
                    </p>
                    <p>
                      If you are a US resident, your best bet is to register in
                      the state where you live and work, rather than Wyoming or
                      Delaware, where you do not operate in. If, on the other
                      hand, you want to prepare your startup for Angel and VC
                      investments, we recommend that you incorporate your
                      company as a corporation in Delaware and foreign qualify
                      the business in the state where you conduct business.
                      Normally, this refers to the state where the founders are
                      located and is often the home of one of the founders.
                    </p>

                    <p>
                      <b>Non-US Residents</b>
                    </p>
                    <p>
                      If you are a non-US resident and do not have any US
                      residents on your team, your best option is to either
                      incorporate in Delaware or Wyoming. If you plan to raise
                      funds from angel investors or venture capitalists, you
                      will need to incorporate a Corporation in Delaware instead
                      of Wyoming.
                    </p>
                  </p>
                }
                icon="bi-card-text"
              />
              <AccordionItem
                eventKey="Five"
                header={
                  <span style={{ fontWeight: "bold" }}>
                    Which is the best State to register in USA?
                  </span>
                }
                body={
                  <div>
                    <p>
                      Delaware and Wyoming are the top two choices for non-US
                      founders all over the world. Merits of incorporating an
                      entity in Delaware:
                    </p>

                    <p>
                      <b>1. Easy and Reliable</b>
                    </p>
                    <ul>
                      <li>
                        Delaware law has been tested and provides
                        predictability.
                      </li>
                      <li>
                        Delaware regularly updates its laws to remain the
                        leading state for incorporation.
                      </li>
                      <li>
                        Delaware has a separate Court of Chancery that handles
                        Corporation law cases quickly and competently.
                      </li>
                    </ul>

                    <p>
                      <b>2. Lower Costs</b>
                    </p>
                    <ul>
                      <li>
                        The Delaware Corporation minimum annual franchise tax is
                        $175, and the filing fee is $50.
                      </li>
                      <li>
                        The Delaware LLC annual fee is $300, regardless of
                        capitalization.
                      </li>
                      <li>
                        Delaware does not tax intangible property such as leases
                        or royalties (the “Delaware loophole”).
                      </li>
                    </ul>

                    <p>
                      <b>3. Protection</b>
                    </p>
                    <ul>
                      <li>
                        Piercing the corporate veil or making individuals
                        personally liable for claims against their business is
                        very unlikely in Delaware.
                      </li>
                      <li>
                        Delaware explicitly makes the charging order the sole
                        and exclusive remedy of creditors of LLC members,
                        meaning that creditors of members can only receive
                        distributions from the LLC, and not control foreclosure
                        or liquidation.
                      </li>
                    </ul>
                  </div>
                }
                icon="bi-card-text"
              />
              <AccordionItem
                eventKey="Six"
                header={
                  <span style={{ fontWeight: "bold" }}>
                    What are the requirements to register a Company in Delaware?
                  </span>
                }
                body={
                  <div>
                    <p className="pb-15">
                      Delaware is a popular state among foreign businesses that
                      are planning to do business in the USA. Delaware formation
                      requirements for starting a company in Delaware are as
                      follows:
                    </p>
                  
                      <li>
                        <b>1. Hire a Delaware registered agent:</b>
                        
                          A registered agent can be an individual or business
                          entity. The registered agent will help your company in
                          receiving important information such as official
                          government notification, legal documents, tax forms,
                          and others related to lawsuits. It will represent your
                          business’s point of contact with the state without the
                          need of you being present at the company, which allows
                          you to operate the company outside the USA.
                       
                      </li>
                      <li>
                        <b>2. Provide the Name of the company:</b>
                        
                          Choose 3 different names and make sure that these
                          names are still available. After that, the business
                          owner submits the business’s names to the Delaware
                          Government, and it will automatically belong to your
                          business after the state approves.
                      
                      </li>
                      <li>
                        <b>3. File Certificate of Incorporation:</b>
                      
                          To register an entity in Delaware, the business owners
                          need to file the Certificate of Incorporation with the
                          Secretary of State. It can be done online or mailed to
                          the Delaware Division of Corporations’ website.
                       
                      </li>
                      <li>
                        <b>4. Obtain an EIN:</b>
                        
                          The Delaware company requires obtaining an Employer
                          Identification Number (EIN) to file tax returns and
                          pay taxes later.
                        
                      </li>
                 
                  </div>
                }
                icon="bi-card-text"
              />
              <AccordionItem
                eventKey="Seven"
                header={
                  <span style={{ fontWeight: "bold" }}>
                    Do I need SSN (Social Security Number) of the officer to
                    apply for EIN?
                  </span>
                }
                body={
                  <p>
                    {" "}
                    EIN can be applied online on IRS portal if any director or
                    officer is having valid SSN. However, if SSN is not
                    available with any officer, EIN can be applied for by
                    filling and submitting Form SS-4 physically (via courier /
                    post) with IRS. Usually it takes around 3-5 weeks to get the
                    EIN if applied physically.
                    <br />
                  </p>
                }
                icon="bi-card-text"
              />
              <AccordionItem
                eventKey="Eight"
                header={
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    What is the Information Required for Incorporation of a
                    corporation?Is it required to have a statute?"
                  </span>
                }
                body={
                  <ul>
                    <li>
                      1.Proposed Name of the Entity (At least 2 options,
                      preference wise)
                    </li>
                    <li>2.Name and address of Directors</li>
                    <li>3.Name, address, and designation of Officers</li>
                    <li>4.Address of Principal Place of business</li>
                    <li>
                      5.Identity Proof (Passport) of Directors and Officers
                    </li>
                    <li>
                      6.Share capital of the Entity and value of each share
                    </li>
                    <li>7.SSN of any one of the Directors/Officers, if any.</li>
                  </ul>
                }
                icon="bi-card-text"
              />
              <AccordionItem
                eventKey="Nine"
                header={
                  <span style={{ fontWeight: "bold" }}>
                    How long does it take to set up a business entity in the
                    USA?
                  </span>
                }
                body={
                  <p>
                    {" "}
                    The time required to set up a business entity in the USA can
                    vary depending on various factors, such as the type of
                    entity, the state of formation, and the completeness of the
                    application. Generally, it can take a few weeks to several
                    months to complete the entire process.
                  </p>
                }
                icon="bi-card-text"
              />
              <AccordionItem
                eventKey="Ten"
                header={
                  <span style={{ fontWeight: "bold" }}>
                    Can I change the type of business entity after it is formed?
                  </span>
                }
                body={
                  <p style={{ textAlign: "justify" }}>
                    In many cases, it is possible to change the type of business
                    entity after it is formed. This process is often referred to
                    as "entity conversion" or "entity restructuring." However,
                    the specific procedures and requirements for changing entity
                    types may vary by state.
                  </p>
                }
                icon="bi-card-text"
              />
              <AccordionItem
                eventKey="Eleven"
                header={
                  <span style={{ fontWeight: "bold" }}>
                    What are the ongoing compliance requirements for business
                    entities in the USA?
                  </span>
                }
                body={
                  <p>
                    <p>
                      Business entities in the USA have ongoing compliance
                      requirements, which may include:
                    </p>

                    <li>Filing annual reports or statements with the state.</li>
                    <li>
                      Paying state and federal taxes and maintaining proper
                      accounting records.
                    </li>
                    <li>
                      Holding regular meetings and documenting minutes for
                      corporations.
                    </li>
                    <li>
                      Complying with any specific industry regulations or
                      licensing requirements.
                    </li>
                    <li>
                      Updating and renewing licenses or permits as necessary.
                    </li>
                  </p>
                }
                icon="bi-card-text"
              />
              <AccordionItem
                eventKey="Twelve"
                header={
                  <span style={{ fontWeight: "bold" }}>
                     Do I need to visit US for incorporation of the Corporation?
                  </span>
                }
                body={
                  <p>
                    {" "}
                    You don't have to physically visit the United States. When it comes to creating a company, we do everything remotely. This means that you can set up a company from wherever in the world.
                  </p>
                }
                icon="bi-card-text"
              />
              <AccordionItem
                eventKey="Thirthin"
                header={
                  <span style={{ fontWeight: "bold" }}>
                   Can a foreign individual or company set up a business entity in the USA?
                  </span>
                }
                // headerStyle={{ fontWeight: '700' }}
                body={
                  <p>
                   Yes, foreign individuals and companies can set up a business entity in the USA. However, there may be additional requirements and considerations, such as obtaining the necessary visas or permits, appointing a registered agent, and complying with specific regulations for foreign-owned entities. Individuals residing in or citizens of the following countries, however, will be unable to use our service: Cuba, Iran, North Korea, and Syria.
                  </p>
                }
                icon="bi-card-text"
              />
              <AccordionItem
                eventKey="Fourteen"
                header={
                  <span style={{ fontWeight: "bold" }}>
                   Why should I start my company in the US?
                  </span>
                }
                // headerStyle={{ fontWeight: '700' }}
                body={
                  <p>
                    {" "}
                    Access to U.S.-based customers, venture capital, tax benefits, banks, accelerators, payment providers and lines of credit are among the benefits of starting a business in the United States. American customers also have a high level of comfort purchasing digital and physical products from U.S.-based companies.
                  </p>
                }
                icon="bi-card-text"
              />
              <AccordionItem
                eventKey="Fivteen"
                header={
                  <span style={{ fontWeight: "bold" }}>
                    Will I have a US Address?
                  </span>
                }
                // headerStyle={{ fontWeight: '700' }}
                body={
                  <p>
                  The registered agent address that comes with the plan serves as your US Mailing Address. You are entitled to receive and scan up to 10 regular mails per year. Mail from the state does not count against this quota because the Registered Agent is required by law to accept mail from state government on your behalf.
                  </p>
                }
                icon="bi-card-text"
              />
             
            
              {/* Add more AccordionItems as needed */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faqusaentitysetup;
