import React, { useState } from "react";
import cpasolution1 from "../../../assets/images/CPA back-office/CPAsolutions1.png";
import cpa2 from "../../../assets/images/CPA back-office/CPA2.png";
import cpa1 from "../../../assets/images/CPA back-office/CPA1.png";
import cpa3 from "../../../assets/images/CPA back-office/CPA3.png";
import cpa4 from "../../../assets/images/CPA back-office/CPA4.png";
import dot from "../../../assets/images/full-stop.png";

const AuditSupport = () => {
  return (
    <div>
      <div className="">
        <style jsx>{`
          .imgBtn {
            z-index: 1;
            background: #21ac51;
            padding: 5px 20px;
            border-radius: 20px;
            position: absolute;
            top: 17rem;
          }

          .imgBtn-india {
            z-index: 1;
            background: #0951a4;
            padding: 5px 20px;
            border-radius: 20px;
            position: absolute;
            top: 17rem;
          }

          p {
            font-weight: 500 !important;
          }

          h1 {
            font-size: 48px !important;
          }

          h4 {
            font-size: 18px;
          }

          h3 {
            font-size: 28px;
          }

          .subSpan {
            font-size: 18px !important;
            color: #072f5f !important;
            font-weight: 600;
          }

          @media (min-width: 280px) {
            li {
              font-size: 16px;
            }
            p {
              font-size: 16px;
            }
            .col-lg-7 {
              padding-right: 4% !important;
            }
          }

          @media (min-width: 337px) {
            li {
              font-size: 16px;
            }
            p {
              font-size: 16px;
            }
            .col-lg-7 {
              padding-right: 4% !important;
            }
          }

          @media (max-width: 575px) {
            .mTop {
              margin-top: 0 !important;
            }
          }

          @media (min-width: 570px) {
            .mTop {
              margin-top: 100px;
            }
            li {
              font-size: 18px;
            }
            p {
              font-size: 18px;
            }
            .col-lg-7 {
              padding-right: 4% !important;
            }
          }

          @media (min-width: 767px) {
            .mTop {
              margin-top: 100px;
            }
            li {
              font-size: 18px;
            }
            p {
              font-size: 18px;
            }
          }

          @media (min-width: 976px) {
            li {
              font-size: 16px;
            }
            p {
              font-size: 16px;
            }
            .mTop {
              margin-top: 100px;
            }
          }

          @media (max-width: 999px) {
            .imgDiv {
              display: none;
            }
          }
        `}</style>

        <div
          className="d-flex justify-content-center align-items-center banner-image"
          style={{
            background: `url(${cpasolution1}) no-repeat center`,
            backgroundSize: "cover",
            height: "430px",
          }}
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h3
              className="text-white text-uppercase"
              style={{ fontWeight: "bold" }}
            >
              Audit Support
            </h3>
            {/* <h4 className="cursor-pointer pt-2" style={{ color: '#69a1bb' }}>Home | Careers</h4> */}
          </div>
        </div>

        <div className="container">
          <div className="row" style={{ marginTop: "5%", marginBottom: "4%" }}>
            <p
              className="text-dark"
              style={{
                marginBottom: "3%",
                padding: "0px 15px 0px",
                textAlign: "justify",
              }}
            >
              We specialize in providing comprehensive back-office support
              services for Certified Public Accountants (CPAs) and accounting
              firms specifically focused on audit engagements. Our aim is to
              assist you in efficiently managing your audit processes, ensuring
              compliance with regulatory requirements, and delivering
              high-quality audit services to your clients.
            </p>
            <div className="col-lg-5 justify-content-center align-items-center imgDiv">
              <div className="d-flex justify-content-start align-items-start">
                <img src={cpa2} className="img-fluid" alt="CPA Back Office" />
              </div>
            </div>
            <div
              className="col-lg-7 pr-0"
              style={{ paddingLeft: "4%", paddingRight: "4%" }}
            >
              <div>
                <span
                  className="subSpan"
                  style={{ color: "#072f5f !important" }}
                >
                  Audit Planning and Preparation
                </span>
              </div>

              <div className="mb-15 mt-10 text-dark d-flex align-items-start">
                <img
                  src={dot}
                  height={20}
                  width={20}
                  alt="dot icon"
                  className="mr-2"
                  style={{ width: "50px", height: "26px", marginTop: "0px" }}
                />
                <p style={{ textAlign: "justify", fontWeight: "400" }}>
                  <b>Risk assessment and scoping:</b> We assist in identifying
                  and assessing audit risks, determining materiality thresholds,
                  and developing an audit plan tailored to your client's
                  business.
                </p>
              </div>

              <div className="mb-15 mt-10 text-dark d-flex align-items-start">
                <img
                  src={dot}
                  alt="dot icon"
                  className="mr-2"
                  style={{ width: "50px", height: "26px", marginTop: "0px" }}
                />
                <p style={{ textAlign: "justify", fontWeight: "400" }}>
                  <b>Audit program development:</b> Our team helps in creating
                  detailed audit programs, outlining procedures and testing
                  methodologies for each audit area.
                </p>
              </div>

              <div className="mb-15 mt-10 text-dark d-flex align-items-start">
                <img
                  src={dot}
                  alt="dot icon"
                  className="mr-2"
                  style={{ width: "50px", height: "26px", marginTop: "0px" }}
                />
                <p style={{ textAlign: "justify", fontWeight: "400" }}>
                  <b>Documentation and management:</b> We organize and maintain
                  audit documentation, including workpapers and compliance with
                  standards.
                </p>
              </div>
            </div>
          </div>

          <div className="row" style={{ marginBottom: "4%" }}>
            <div className="col-lg-7" style={{ paddingRight: "4% !important" }}>
              <div>
                <span
                  className="subSpan"
                  style={{ color: "#072f5f !important" }}
                >
                  Fieldwork Assistance
                </span>
              </div>

              <div className="mb-15 mt-10 text-dark d-flex align-items-start">
                <img
                  src={dot}
                  alt="dot icon"
                  className="mr-2"
                  style={{ width: "50px", height: "26px", marginTop: "0px" }}
                />
                <p style={{ textAlign: "justify", fontWeight: "400" }}>
                  <b>Testing and sampling:</b> We support the execution of audit
                  procedures, including substantive testing, analytical
                  procedures, and control testing, ensuring adherence to
                  auditing standards.
                </p>
              </div>
              <div className="mb-15 mt-10 text-dark d-flex align-items-start">
                <img
                  src={dot}
                  alt="dot icon"
                  className="mr-2"
                  style={{ width: "50px", height: "26px", marginTop: "0px" }}
                />
                <p style={{ textAlign: "justify", fontWeight: "400" }}>
                  <b>Audit evidence analysis:</b> Our team assists in evaluating
                  audit findings, identifying potential errors, irregularities,
                  or non-compliance with accounting principles and regulations.
                </p>
              </div>
              <div className="mb-15 mt-10 text-dark d-flex align-items-start">
                <img
                  src={dot}
                  alt="dot icon"
                  className="mr-2"
                  style={{ width: "50px", height: "26px", marginTop: "0px" }}
                />
                <p style={{ textAlign: "justify", fontWeight: "400" }}>
                  <b>Communication with client personnel:</b> We liaise with
                  client personnel to request and gather necessary
                  documentation, resolve queries, and obtain information
                  relevant to the audit.
                </p>
              </div>
            </div>
            <div className="col-lg-5 justify-content-center align-items-center imgDiv">
              <div className="d-flex justify-content-start align-items-start">
                <img src={cpa3} className="img-fluid" alt="CPA Fieldwork" />
              </div>
            </div>
          </div>

          <div className="row" style={{ marginBottom: "4%" }}>
            <div className="col-lg-5 justify-content-center align-items-center imgDiv">
              <div className="d-flex justify-content-start align-items-start">
                <img
                  src={cpa4}
                  className="img-fluid"
                  alt="CPA Financial Reporting"
                />
              </div>
            </div>
            <div
              className="col-lg-7 pr-0"
              style={{ paddingLeft: "4%", paddingRight: "4% !important" }}
            >
              <div>
                <span
                  className="subSpan"
                  style={{ color: "#072f5f !important" }}
                >
                  Financial Reporting and Disclosure
                </span>
              </div>
              <div className="mb-15 mt-10 text-dark d-flex align-items-start">
                <img
                  src={dot}
                  alt="dot icon"
                  className="mr-2"
                  style={{ width: "50px", height: "26px", marginTop: "0px" }}
                />
                <p style={{ textAlign: "justify", fontWeight: "400" }}>
                  <b>Financial statement preparation:</b> We help prepare and
                  review financial statements in accordance with Generally
                  Accepted Accounting Principles (GAAP) or International
                  Financial Reporting Standards (IFRS).
                </p>
              </div>
              <div className="mb-15 mt-10 text-dark d-flex align-items-start">
                <img
                  src={dot}
                  alt="dot icon"
                  className="mr-2"
                  style={{ width: "50px", height: "26px", marginTop: "0px" }}
                />
                <p style={{ textAlign: "justify", fontWeight: "400" }}>
                  <b>Footnote disclosures:</b> Our team assists in drafting and
                  reviewing footnote disclosures to ensure they comply with
                  relevant accounting standards and provide adequate
                  transparency to users.
                </p>
              </div>
              <div className="mb-15 mt-10 text-dark d-flex align-items-start">
                <img
                  src={dot}
                  alt="dot icon"
                  className="mr-2"
                  style={{ width: "50px", height: "26px", marginTop: "0px" }}
                />
                <p style={{ textAlign: "justify", fontWeight: "400" }}>
                  <b>Management representation letters:</b> We help prepare
                  management representation letters, which confirm the
                  completeness and accuracy of the information provided to the
                  auditors.
                </p>
              </div>
            </div>
          </div>

          <div className="row" style={{ marginBottom: "4%" }}>
            <div className="col-lg-7" style={{ paddingRight: "4% !important" }}>
              <div>
                <span
                  className="subSpan"
                  style={{ color: "#072f5f !important" }}
                >
                  CPA Back Office Support
                </span>
              </div>

              <div className="mb-15 mt-10 text-dark d-flex align-items-start">
                <img
                  src={dot}
                  alt="dot icon"
                  style={{ width: "50px", height: "26px", marginTop: "0px" }}
                />
                <p style={{ textAlign: "justify", fontWeight: "400" }}>
                  <b>Outsourced accounting services:</b> We offer comprehensive
                  accounting services, including bookkeeping, payroll
                  processing, accounts payable and receivable management, and
                  financial reporting.
                </p>
              </div>
              <div className="mb-15 mt-10 text-dark d-flex align-items-start">
                <img
                  src={dot}
                  alt="dot icon"
                  style={{ width: "50px", height: "26px", marginTop: "0px" }}
                />
                <p style={{ textAlign: "justify", fontWeight: "400" }}>
                  <b>Tax return preparation and filing:</b> Our team provides
                  accurate and timely tax return preparation and filing
                  services, ensuring compliance with tax laws and regulations.
                </p>
              </div>
              <div className="mb-15 mt-10 text-dark d-flex align-items-start">
                <img
                  src={dot}
                  alt="dot icon"
                  style={{ width: "50px", height: "26px", marginTop: "0px" }}
                />
                <p style={{ textAlign: "justify", fontWeight: "400" }}>
                  <b>Audit and assurance support:</b> We assist in planning and
                  executing audit procedures, conducting risk assessments, and
                  evaluating audit evidence, enabling you to deliver
                  high-quality audit and assurance services to your clients.
                </p>
              </div>
            </div>
            <div className="col-lg-5 justify-content-center align-items-center imgDiv">
              <div className="d-flex justify-content-start align-items-start">
                <img src={cpa1} className="img-fluid" alt="CPA Compliance" />
              </div>
            </div>
            <p
              className="text-dark pt-5"
              style={{
                padding: "0px 15px 0px",
                textAlign: "justify",
                fontWeight: "400",
              }}
            >
              At BFAG, we understand the importance of providing accurate,
              thorough, and reliable tax return preparation, compilation and
              review, and audit services. Our dedicated back-office support team
              is committed to assisting you in delivering high-quality audits,
              meeting regulatory requirements, exceeding client expectations
              along with confidential services to help streamline your
              operations and enhance your client service delivery. Contact us
              today to learn more about our CPA Back Office Support services and
              how we can assist you in enhancing the efficiency and
              effectiveness of your tax return preparation, compilation and
              review, and audit engagements.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuditSupport;
