import React from 'react'
import sanjaykapadia from "../../../../assets/images/teams/sanjaycircle.jpeg";

const Sanjaykapadia = () => {
  return (
    <div className="min-vh-100 mt-5">
    <div className="container">
      <div className="row">
        <div className="col-lg-4 d-flex justify-content-center mb-3">
          <div style={{ padding: '1%' }}>
            <img
              style={{ borderRadius: '10px', width: '100%' }}
              className="img-fluid"
              src={sanjaykapadia}
              alt="SANJAY KAPADIA"
            />
          </div>
        </div>
        <div className="col-lg-8 d-flex flex-column justify-content-start">
          <h3 className="text-uppercase text-dark title" style={{ fontSize: '20px', fontWeight: 700 }}>
          SANJAY KAPADIA
          </h3>
          <div>
            <span className="text-uppercase text-dark" style={{ fontSize: '16px', fontWeight: 600 }}>
              ADVISOR
            </span>
            <a
              style={{ color: '#fff', marginLeft: '10px', backgroundColor: '#0072b1', padding: '4px', borderRadius: '5px' }}
              href="https://www.linkedin.com/in/sanjaykapadia/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-linkedin-in" style={{color:'#fff'}}></i>
            </a>
          </div>
          <p className="mt-3 text-dark text-justify" style={{ fontSize: '16px' }}>
          Sanjay Kapadia is a highly accomplished finance and taxation
            professional with expertise in both accounting and law. As a
            distinguished Member of the ICAI and a Law degree holder from Mumbai
            University, he has established himself as a prominent figure in
            financial advisory.He started his career as an Article with and
            later as a Partner with Bansi S. Mehta &amp; Co (1988-1997),
            followed by a stint with the erstwhile Arthur Andersen (1997-1998).          </p>
          <p className="mt-3 text-dark text-justify" style={{ fontSize: '16px' }}>
          Currently, Sanjay serves as an Advisor at Boston Financial Advisory
            Group, specializing in comprehensive Cross Border Advisory and
            Transfer Pricing solutions. His role involves providing strategic
            guidance and counsel to clients seeking international financial
            assistance. With over 30 years of experience, he has advised
            multinational corporations and Indian businesses on complex tax and
            regulatory issues.  </p>
          
          <div class=" pl-4 pr-0" style={{marginLeft:'-52%'}} >
          <p className="mt-3 text-dark text-justify" style={{ fontSize: '16px' }}>
          Over the course of his career, Sanjay has been
            active in sharing his expertise with the professional community. His
            areas of expertise include Tax Treaties, Permanent Establishment
            concerns, Corporate Restructuring, Transfer Pricing Strategy, and
            more.         
          Sanjay is also the Founding Partner of S K M S & Co. LLP, Chartered
        Accountants, a highly regarded firm offering expert guidance on Tax &
        Regulatory matters across industries.                    </p>
         
          <p className="" style={{ fontSize: '16px'}}>
          Formerly, Sanjay was a Senior Advisor for India Tax Practice, KNAV
          &amp; Co., partner with B S R &amp; Associates LLP (2016-2019).          </p>
          <p className="mt-3 text-dark text-justify" style={{ fontSize: '16px' }}>
          (KPMG), Ernst &amp; Young’s (EY) (2010-2016) International Tax and 4Transfer
          Pricing practice in Mumbai as lead transfer pricing partner within
          EY’s Mumbai’s Telecommunication, Technology, and Entertainment &amp;
          Media practice, RSM &amp; Co (1998-2007) (the firm, which merged with
          PwC India).          </p>
          <p className="mt-3 text-dark text-justify" style={{ fontSize: '16px' }}>
          Sanjay also provides insights on Indian Exchange Control advisory, Tax
          & Regulatory Due Diligence, and Audits. His vast experience led him to
          conduct training sessions and presentations at the Indian Tax
          Department's National Tax Academy. He has also been a presenter and
          panel speaker at various industry and professional forums, discussing
          tax and transfer pricing matters. Additionally, he co-authored the
          Transfer Pricing Manual of the Bombay Chartered Accountants' Society
          and served on the Editorial Board of Taxmann's Monthly Journal on
          International Taxation.          </p>
          <p className="mt-3 text-dark text-justify" style={{ fontSize: '16px' }}>
          He has served on Pannell Kerr Foster's International Tax Committee, as
          well as the Corporate and Allied Laws Committee and the Direct Tax
          Committee of the Institute of Chartered Accountants of India. Sanjay
          Kapadia is recognized as one of the premier tax and regulatory
          advisor, establishing successful businesses within and outside the
          country wealth of experience and unwavering commitment to excellence
          in finance, taxation, and law.          </p>
          {/* <p className="mt-3 text-dark text-justify" style={{ fontSize: '16px' }}>
            In the past, he has served as Director of SBI Mutual Fund Trustee Company Private Limited, Director of GIC Housing Finance Limited, and Independent Director of the Insurance Regulatory & Development Authority (IRDA). He has also contributed his expertise as a Member of SEBI's Primary Market Advisory Committee, Director of IDBI Capital Market Services Limited, and Director of the Indian Institute of Insolvency Professionals of ICAI, among other significant roles.
          </p>
          <p className="mt-3 text-dark text-justify" style={{ fontSize: '16px' }}>
            Prafulla has also been a prominent figure in the media, having written a weekly column titled 'Paisa Jhala Motha' for the renowned Marathi daily 'Maharashtra Times' for almost two decades.
          </p>
          <p className="mt-3 text-dark text-justify" style={{ fontSize: '16px' }}>
            Additionally, Prafulla has been involved in various committees and organizations, such as the Extensible Business Reporting Language (XBRL) India, Banking & Finance Committee of IMC Chamber of Commerce, CII National Committee on Financial Reporting.
          </p>
          <p className="mt-3 text-dark text-justify" style={{ fontSize: '16px' }}>
            Through his diverse leadership roles, extensive industry contributions, and remarkable achievements, Prafulla has made an indelible impact on the accountancy profession and continues to be a respected figure in the field.
          </p> */}
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Sanjaykapadia