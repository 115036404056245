import React from 'react';
import prafulla from '../../../../assets/images/teams/prafullacircle.jpeg'

const Prafullachhajed = () => {
  return (
    <div className="min-vh-100 mt-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 d-flex justify-content-center mb-3">
            <div style={{ padding: '1%' }}>
              <img
                style={{ borderRadius: '10px', width: '100%', height:'430px' }}
                className="img-fluid"
                src={prafulla}
                alt="PRAFULLA CHHAJED"
              />
            </div>
          </div>
          <div className="col-lg-8 d-flex flex-column justify-content-start">
            <h3 className="text-uppercase text-dark title" style={{ fontSize: '20px', fontWeight: 700 }}>
              PRAFULLA CHHAJED
            </h3>
            <div >
              <span className="text-uppercase text-dark" style={{ fontSize: '16px', fontWeight: 600 }}>
                ADVISOR
              </span>
              <a
                style={{ color: '#fff', marginLeft: '10px', backgroundColor: '#0072b1', padding: '4px', borderRadius: '5px' }}
                href="https://www.linkedin.com/in/prafullachhajed/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-linkedin-in" style={{color:'#fff'}}></i>
              </a>
            </div>
            <p className=" mt-3 text-dark text-justify" style={{ fontSize: '16px', overflowWrap: 'break-word', lineHeight: '1.5' }}>
              CA. Prafulla P Chhajed is a fellow and practicing member of the Institute of Chartered Accountants of India (ICAI). He has made significant contributions to the field of accountancy and held prestigious leadership positions throughout his career. Prafulla has recently joined Boston Financial Advisory Group as an Advisor. With his expertise and guidance, the company is excited to embark on a journey towards becoming a globally recognized and esteemed Financial Services Solution provider.
            </p>
            <p className=" text-dark text-justify" style={{ fontSize: '16px' }}>
              In 2019-20, he served as the President of the Institute of Chartered Accountants of India which is the second largest accounting body in the world, demonstrating his exemplary leadership and commitment to the profession. Prior to that, he held the position of Chairman of the Western India Regional Council (WIRC) of ICAI in 2007-08.
            </p>
            <p className=" text-dark text-justify" style={{ fontSize: '16px' }}>
              His expertise and influence extend beyond national boundaries. He has been elected as the Deputy President of the Confederation of Asian & Pacific Accountants (CAPA), highlighting his global standing in the profession. 
            </p>
           
            <p className=" text-dark text-justify" style={{ fontSize: '16px' }}>
              Throughout his career, he has actively contributed to various national and international organizations, including SAFA, IFAC SMP committee, CA Worldwide, and the Integrated Reporting Council.
            </p>
            <div className="pl-0 pr-0" style={{marginLeft:'-52%'}} >
            <p className="mt-3" style={{ fontSize: '16px'}}>
            He was also the Global Chairman of the Executive Committee of the World Congress of Accountants 2022 organized by the International Federation of Accountants (IFAC). The event witnessed the participation of over 6,500 delegates from 105 countries.
            </p>
            <p className="mt-3 text-dark text-justify" style={{ fontSize: '16px' }}>
              His involvement in these esteemed bodies showcases his dedication to advancing the profession and promoting best practices.
            </p>
            <p className="mt-3 text-dark text-justify" style={{ fontSize: '16px' }}>
              Currently, he holds several esteemed positions, including Independent Director on the Central Board of State Bank of India, Member of the Board of Management of Mumbai School of Economics and Public Policy (Mumbai University), and Governing Council Member & Chairman of the Banking Finance & IT Committee of the Maharashtra Chamber of Commerce, Industry & Agriculture.
            </p>
            <p className="mt-3 text-dark text-justify" style={{ fontSize: '16px' }}>
              Furthermore, he is a valued member of the Professional Accountancy Organization Development & Advisory Group (2021-2023) of IFAC, based in New York.
            </p>
            <p className="mt-3 text-dark text-justify" style={{ fontSize: '16px' }}>
              In the past, he has served as Director of SBI Mutual Fund Trustee Company Private Limited, Director of GIC Housing Finance Limited, and Independent Director of the Insurance Regulatory & Development Authority (IRDA). He has also contributed his expertise as a Member of SEBI's Primary Market Advisory Committee, Director of IDBI Capital Market Services Limited, and Director of the Indian Institute of Insolvency Professionals of ICAI, among other significant roles.
            </p>
            <p className="mt-3 text-dark text-justify" style={{ fontSize: '16px' }}>
              Prafulla has also been a prominent figure in the media, having written a weekly column titled 'Paisa Jhala Motha' for the renowned Marathi daily 'Maharashtra Times' for almost two decades.
            </p>
            <p className="mt-3 text-dark text-justify" style={{ fontSize: '16px' }}>
              Additionally, Prafulla has been involved in various committees and organizations, such as the Extensible Business Reporting Language (XBRL) India, Banking & Finance Committee of IMC Chamber of Commerce, CII National Committee on Financial Reporting.
            </p>
            <p className="mt-3 text-dark text-justify" style={{ fontSize: '16px' }}>
              Through his diverse leadership roles, extensive industry contributions, and remarkable achievements, Prafulla has made an indelible impact on the accountancy profession and continues to be a respected figure in the field.
            </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Prafullachhajed;
