import React from 'react';
import casestudy from '../../../assets/images/case-studies/case-study-main-banner.png'
import it from '../../../assets/images/case-studies/IT.png'

const Itcompanycasestudy = () => {

  return (
      <>
        <style jsx>{`
      h4 {
        font-size: 18px;
      }
      h3 {
        font-size: 28px;
      }
      span {
        font-size: 16px;
      }
      p {
        font-size: 16px;
      }
      li {
        font-size: 16px;
        font-weight: 500;
      }
      .mainCard {
        height: 20rem;
      }
      .circle {
        width: 100px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        overflow: hidden;
      }
      i {
        color: #072f5f;
      }
      ul {
        padding-left: 17px;
      }
      @media (max-width: 574px) {
        .mTop {
          margin-top: 30px;
        }
        p {
          font-size: 18px;
        }
        .img-fluid {
          min-height: 100px;
        }
      }
      @media (min-width: 992px) {
        /* for banner image */
        .bmTop {
          margin-top: 130px;
        }
      }
    `}</style>

        <section className=""
        
        >
          <div
            className="d-flex justify-content-center align-items-center banner-image"
            style={{
              background: `url(${casestudy}) no-repeat center`,
              backgroundSize: "cover",
              height: "430px"
            }}
          >
            <div className="d-flex flex-column justify-content-center align-items-center">
              <h3 className="text-white text-uppercase text-center" style={{fontWeight:'bold'}}>
                IT COMPANY CASE <br />
                STUDY
              </h3>
            </div>
          </div>
          <div className="container-fluid">
            <div className="d-flex row justify-content-center align-items-start pl-4 pr-4" style={{ gap: '2rem' }}>
              <div className="col-lg-8 mt-50 mb-50 border shadow" style={{ padding: '2rem' }}>
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <img
                    className="img-fluid"
                    src={it}
                    alt=""
                    style={{ marginBottom: '2rem' }}
                  />
                  <div>
                    <div
                      style={{
                        background: '#072f5f',
                        display: 'inline',
                        width: 'fit-content',
                        padding: '0.2rem 1rem 0.2rem 1rem'
                      }}
                    >
                      <small
                        className="text-uppercase text-white"
                        style={{ fontWeight: '600', fontSize: '15px' }}
                      >
                        case details
                      </small>
                    </div>
                    <div>
                      <div className="mt-15">
                        <span
                          className="text-capitalize"
                          style={{
                            fontSize:'18px',
                            fontWeight: '700',
                            color: '#002249'
                          }}
                        >
                          overview
                        </span>
                      </div>
                      <p className="text-dark servicep" style={{textAlign:'justify'}}>
                        Client is into providing customized & cost effective solutions in the areas of SAP/JD Edwards implementation such as Business Process Reengineering, Expert Consulting, Training, Solution reviews, Safeguarding Services, Post implementation maintenance support. The client is having operations spread over places such as Australia, USA, Malaysia, South America, Africa apart from India.
                      </p>
                    </div>
                    <div>
                      <div className="mt-15">
                        <span
                          className="text-capitalize"
                          style={{
                            fontSize: '1.5rem',
                            fontWeight: '700',
                            color: '#002249'
                          }}
                        >
                          Client
                        </span>
                      </div>
                      <ul className="text-dark servicep" style={{ listStyleType: 'disc', textAlign:'justify' }}>
                        <li>
                          Presence in multiple jurisdictions with legal entities for ease of local jurisdiction. Multiple office locations within India. Employees travelling to various locations across the globe for project execution.
                        </li>
                        <li>
                          Specialization in SAP, JD Edwards, Apriso, IT infrastructure operations support.
                        </li>
                      </ul>
                    </div>
                    <div>
                      <div className="mt-15">
                        <span
                          className="text-capitalize"
                          style={{
                            fontSize: '1.5rem',
                            fontWeight: '700',
                            color: '#002249'
                          }}
                        >
                          Strategies Described
                        </span>
                      </div>
                      <ul className="text-dark servicep" style={{ listStyleType: 'disc', textAlign:'justify' }}>
                        <li>
                          Defining the proper off-site Accounting organization structure and providing capable resources.
                        </li>
                        <li>
                          Defining the SLA’s for keeping the F & A function online, defining the roadmap for ongoing accounting activity.
                        </li>
                        <li>
                          Providing consulting solution for creating the group structure globally.
                        </li>
                      </ul>
                    </div>
                    <div>
                      <div className="mt-15">
                        <span
                          className="text-capitalize"
                          style={{
                            fontSize: '1.5rem',
                            fontWeight: '700',
                            color: '#002249'
                          }}
                        >
                          Client's Challenges
                        </span>
                      </div>
                      <ul className="text-dark servicep" style={{ listStyleType: 'disc', textAlign:'justify' }}>
                        <li>
                          Defining the proper off-site Accounting organization structure and providing capable resources.
                        </li>
                        <li>
                          Defining the SLA’s for keeping the F & A function online, defining the roadmap for ongoing accounting activity.
                        </li>
                        <li>
                          Providing consulting solution for creating the group structure globally.
                        </li>
                      </ul>
                    </div>
                    <div>
                      <div className="mt-15">
                        <span
                          className="text-capitalize"
                          style={{
                            fontSize: '1.5rem',
                            fontWeight: '700',
                            color: '#002249'
                          }}
                        >
                          Solution Provider
                        </span>
                      </div>
                      <ul className="text-dark servicep" style={{ listStyleType: 'disc', textAlign:'justify' }}>
                        <li>
                          Providing experienced and capable team to manage the global F & A function, establishing accounting organization structure to suit the global expansion. Defining proper SLA’s, Compliance Calendar, Structuring the MIS to highlight on the KPI’s.
                        </li>
                        <li>
                          Online solution for Employee expense management, right from submission of reports, to verification, approval, processing, payments, MIS.
                        </li>
                        <li>
                          Consolidation reporting and Dashboard for MIS which can be viewed 24 * 7 from anywhere.
                        </li>
                        <li>Employee self service module for Payroll.</li>
                        <li>Providing the global corporate structure.</li>
                      </ul>
                    </div>
                    <div>
                      <div className="mt-15">
                        <span
                          className="text-capitalize"
                          style={{
                            fontSize: '1.5rem',
                            fontWeight: '700',
                            color: '#002249'
                          }}
                        >
                          Outcome
                        </span>
                      </div>
                      <ul className="text-dark servicep" style={{ listStyleType: 'disc', textAlign:'justify' }}>
                        <li>
                          Tax efficient Global corporate structure with due regards to wealth creation.
                        </li>
                        <li>
                          Well defined accounting process, proper internal controls.
                        </li>
                        <li>
                          Excellent coordination between operations and Accounting function.
                        </li>
                        <li>
                          Accurate & Timely consolidated reporting which has facilitated quick decision making.
                        </li>
                        <li>
                          Management time on accounting & finance function has been restricted to authorizations and discussion on MIS.
                        </li>
                        <li>
                          Negligible employee grievance on account of expenses reports and payroll processing.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    
  );
};

export default Itcompanycasestudy;
