import React from "react";
import "./visionmission.css";
import missionandvision from '../../../assets/images/mission and vision/team.JPG'
import vectorleft from '../../../assets/images/mission and vision/Vector-left.png'
import vectorright from '../../../assets/images/mission and vision/Vector-right.png'
import missionind from '../../../assets/images/mission and vision/missionINd.gif'
import visionind from '../../../assets/images/mission and vision/visionInd.gif'
import statementInd from '../../../assets/images/mission and vision/statementInd.gif'

const VisionMission = () => {
 
  return (
    <div className="pb-100">
      <div
        className="col-lg-12 d-flex flex-column justify-content-center align-items-center"
        style={{
          background:
            "linear-gradient(to bottom, #072f5f 0%, rgba(50, 217, 81, 0) 100%, #ffff 100%)",
        }}
      >
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ paddingTop: "50px" }}
        >
          <h3 className="text-white text-uppercase text-center missionh3" style={{fontWeight:'bold'}}>
            Our Mission and Vision
          </h3>
          <div style={{ paddingTop: "50px", textAlign: "center" }}>
            <img
              src={missionandvision}
              style={{ width: "95%", borderRadius: "40px" }}
              className="imgmission"
              alt="Team"
            />
          </div>
        </div>
      </div>
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{ paddingTop: "65px" }}
      ></div>
      <div className="container-fluid">
        <div
          className="row d-flex justify-content-center align-items-center"
          style={{
            paddingTop: "40px",
            gap: "3rem",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <img
            src={vectorleft}
            alt="shape"
            style={{
              position: "absolute",
              overflow: "clip",
              left: 0,
              bottom: 0,
              width: "12%",
            }}
          />
          <img
            src={vectorright}
            alt="shape"
            style={{
              position: "absolute",
              overflow: "clip",
              right: 0,
              bottom: 0,
              width: "12%",
            }}
          />

          <div
            className="card shadow border-0 d-flex flex-column justify-content-center align-items-center p-3"
            style={{
              width: "350px",
              height: "460px",
              borderRadius: "15px",
              marginBottom: "2rem",
            }}
          >
            <img
              className="img-fluid pt-10"
              src={missionind}
              alt="Card cap"
              style={{ width: "12rem" }}
            />
            <div className="card-body d-flex flex-column justify-content-center align-items-center pt-0 mt-0">
              <span
                className="card-title text-center text-dark"
                style={{
                  borderBottom: "3px solid #072f5f",
                  fontWeight: 700,
                  fontSize: "21px",
                }}
              >
                OUR MISSION
              </span>
              <p className="text-dark" style={{ lineHeight: "normal", textAlign: "justify" }}>
                To be a leading outsourcing and advisory partner for the SME
                sector worldwide that provides analytical information,
                technology, and domain expertise quickly and cost effectively.
              </p>
            </div>
          </div>
          <div
            className="card shadow border-0 d-flex flex-column justify-content-center align-items-center p-3"
            style={{
              width: "350px",
              height: "460px",
              borderRadius: "15px",
              marginBottom: "2rem",
            }}
          >
            <img
              className="img-fluid pt-50"
              src={visionind}
              alt="Card cap"
              style={{ width: "10rem" }}
            />
            <div className="card-body d-flex flex-column justify-content-center align-items-center">
              <span
                className="card-title text-center text-dark"
                style={{
                  borderBottom: "3px solid #072f5f",
                  fontWeight: 700,
                  fontSize: "21px",
                }}
              >
                OUR VISION
              </span>
              <p className="text-dark" style={{ lineHeight: "normal", textAlign: "justify" }}>
                At the core of our success story lies the unwavering commitment
                to excellence, which permeates every interaction and is
                fortified by our five pillars: advice, outsourcing services,
                domain knowledge, processes, and technology.
              </p>
            </div>
          </div>
          <div
            className="card shadow border-0 d-flex flex-column justify-content-center align-items-center p-3"
            style={{
              width: "350px",
              height: "460px",
              borderRadius: "15px",
              marginBottom: "2rem",
            }}
          >
            <img
              className="img-fluid pt-10"
              src={statementInd}
              alt="Card cap"
              style={{ width: "12rem" }}
            />
            <div className="card-body d-flex flex-column justify-content-center align-items-center pt-0 mt-0">
              <span
                className="card-title text-center text-dark text-uppercase"
                style={{
                  borderBottom: "3px solid #072f5f",
                  fontWeight: 700,
                  fontSize: "21px",
                }}
              >
                Quality Statement
              </span>
              <p className="text-dark" style={{ lineHeight: "normal", textAlign: "justify" }}>
                Excel in providing financial outsourcing, consulting and
                advisory services leveraging our expertise and technology-driven
                process leading to customer and partner delight.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisionMission;
