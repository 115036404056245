import React, { useState } from "react";
import casestudy from "../../assets/FlipImages/1.png";
import it from "../../assets/FlipImages/1.png";
import {
  TextField,
  Button,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import blog13 from "../../assets/images/blogs/blog13.jfif";

const FirstBlog = () => {
  const [comment, setComment] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [saveInfo, setSaveInfo] = useState(false);
  const [comments, setComments] = useState([]);

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleWebsiteChange = (event) => {
    setWebsite(event.target.value);
  };

  const handleSaveInfoChange = (event) => {
    setSaveInfo(event.target.checked);
  };

  const handleCommentSubmit = () => {
    if (comment.trim() && name.trim() && email.trim()) {
      setComments([...comments, { comment, name, email, website }]);
      setComment("");
      setName("");
      setEmail("");
      setWebsite("");
      setSaveInfo(false);
    }
  };

  return (
    <>
      <style jsx>{`
        h4 {
          font-size: 18px;
        }
        h3 {
          font-size: 28px;
        }
        span {
          font-size: 16px;
        }
        p {
          font-size: 16px;
        }
        li {
          font-size: 16px;
          font-weight: 500;
        }
        .mainCard {
          height: 20rem;
        }
        .circle {
          width: 100px;
          height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          overflow: hidden;
        }
        i {
          color: #072f5f;
        }
        ul {
          padding-left: 17px;
        }
        @media (max-width: 574px) {
          .mTop {
            margin-top: 30px;
          }
          p {
            font-size: 18px;
          }
          .img-fluid {
            min-height: 100px;
          }
        }
        @media (min-width: 992px) {
          /* for banner image */
          .bmTop {
            margin-top: 130px;
          }
        }
      `}</style>

      <section className="">
        <div
          className="d-flex justify-content-center align-items-center banner-image"
          style={{
            background: `url(${casestudy}) no-repeat center`,
            backgroundSize: "cover",
            height: "200px",
          }}
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h3
              className="text-white text-uppercase text-center"
              style={{ fontWeight: "bold" }}
            >
              The Future of Accounting Technology <br /> Navigating Digital
              Transformation
            </h3>
          </div>
        </div>
        <div className="container-fluid">
          <div
            className="d-flex row justify-content-center align-items-start pl-4 pr-4"
            style={{ gap: "2rem" }}
          >
            <div
              className="col-lg-8 mt-50 mb-50 border shadow"
              style={{ padding: "2rem" }}
            >
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div>
                  <div
                    style={{
                      background: "#072f5f",
                      display: "inline",
                      width: "fit-content",
                      padding: "0.2rem 1rem 0.2rem 1rem",
                    }}
                  >
                    <small
                      className="text-uppercase text-white"
                      style={{ fontWeight: "600", fontSize: "15px" }}
                    >
                      Introduction
                    </small>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "18px",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      ></span>
                    </div>
                    <p className="text-dark servicep" style={{textAlign:'justify'}}>
                      Today, the information technology of business is
                      constantly changing, and accountants have the difficult
                      choice of being traditional or innovative. On one hand,
                      some leaders in the industry have forecast a decline of
                      accountants in the digital age however; other technologies
                      that are currently at the developmental stage are expected
                      to lift the tasks of accountants to the next level. The
                      latest inventions in the field are already causing a
                      revolution and the accountants find the future unclear
                      while the accountants are still learning, and the brands
                      are still developing.{" "}
                    </p>
                  </div>
                  <div
                    style={{
                      background: "#072f5f",
                      display: "inline",
                      width: "fit-content",
                      padding: "0.2rem 1rem 0.2rem 1rem",
                    }}
                  >
                    <small
                      className="text-uppercase text-white"
                      style={{ fontWeight: "600", fontSize: "15px" }}
                    >
                      Leveraging Digital Tools
                    </small>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Cloud-Based Data Management :
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign:'justify' }}
                    >
                      <li>
                        Earlier impossible ideas are now practical thanks to
                        cloud data collection and storage. If we implement
                        applications into the workspace there is a positive
                        correlation between the efficiency of these systems and
                        the profitability of the companies. With the help of an
                        integrated and designed datasphere, it would be fast and
                        convenient to capture, access, share and analyze data.
                        Transparency is in progress as the silos, to which data
                        are held, have been removed and the data quality has
                        surged to fronts various of which nothing was done
                        before.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Process Automation :
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign:'justify' }}
                    >
                      <li>
                        The process is accelerated by automation removing
                        monotonous manual duties like inputting and three-way
                        matching, thus saving time and decreasing human errors.
                        Instead of being usurped by robots, accountants are now
                        able to concentrate on those activities and tasks that
                        are more creative, collaborative, and intuitive. These
                        are services that AI cannot provide with a great level
                        of accuracy, does not mean that there is no use of
                        articulated intelligence it can in many instances.{" "}
                      </li>

                      <li></li>
                    </ul>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Advanced Analytics :
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign:'justify' }}
                    >
                      <li>
                        Today's big data has become a treasure trove of
                        information. Accountants can resort to data analytics
                        tools to make assumptions, recognize patterns, and
                        arrive at solid decisions. Whether it is forecasting,
                        recognizing distrust, or the analysis of the
                        implementation of a service, advanced analytics are the
                        ones that encourage accountants to go beyond traditional
                        accounting by giving extra meaning to business.{" "}
                      </li>

                      <li></li>
                    </ul>
                  </div>
                  <div
                    style={{
                      background: "#072f5f",
                      display: "inline",
                      width: "fit-content",
                      padding: "0.2rem 1rem 0.2rem 1rem",
                    }}
                  >
                    <small
                      className="text-uppercase text-white"
                      style={{ fontWeight: "600", fontSize: "15px" }}
                    >
                      Soft Skills Matter
                    </small>
                  </div>
                  <br />
                  <p className="text-dark servicep" style={{textAlign:'justify'}}>
                    Even though technology changes accounting, soft skills are
                    still critical. Accountants must put in improving the same
                    level of their communication, critical thinking, and
                    adaptability. Let me tell you{" "}
                  </p>
                  <img
                    src={blog13}
                    style={{
                      display: "block",
                      margin: "1rem auto",
                      width: "400px",
                      height: "300px",
                    }}
                  />
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Client Relationships :
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign:'justify' }}
                    >
                      <li>
                        Technology cannot replace human contact. The capability
                        of building good relationships with clients comes from
                        an engaging style of communication, empathy, and really
                        getting to know one another. Accountants that are strong
                        in these areas will be the winners.{" "}
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "1.5rem",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      >
                        Problem-Solving :
                      </span>
                    </div>
                    <ul
                      className="text-dark servicep"
                      style={{ listStyleType: "disc", textAlign:'justify' }}
                    >
                      <li>
                        Creative problem-solving is one of the most human skills
                        that people have. Accountants should scrutinize
                        potential solutions to complex situations, suggest
                        ideas, and respond to changing situations. Technology is
                        a tool for this process, not a substitute for it.{" "}
                      </li>
                    </ul>
                  </div>

                  <div
                    style={{
                      background: "#072f5f",
                      display: "inline",
                      width: "fit-content",
                      padding: "0.2rem 1rem 0.2rem 1rem",
                    }}
                  >
                    <small
                      className="text-uppercase text-white"
                      style={{ fontWeight: "600", fontSize: "15px" }}
                    >
                      Conclusion
                    </small>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span
                        className="text-capitalize"
                        style={{
                          fontSize: "18px",
                          fontWeight: "700",
                          color: "#002249",
                        }}
                      ></span>
                    </div>
                    <p className="text-dark servicep" style={{textAlign:'justify'}}>
                      The technology-driven future of accounting will be a
                      junction of human skills and technology utilization.
                      Boston Financial Advisory Group may present itself as a
                      trendsetter providing innovation opportunities through
                      digitization to good effect in their clients in areas such
                      as efficiency, accuracy, and client satisfaction. Even
                      though technology is advancing at a fast pace and we see
                      that accountants continue to be an important link that
                      uses their adaptation and their open-mindedness to the
                      dynamic conditions to thrive in it.{" "}
                    </p>
                  </div>

                  <Box
                    mt="2rem"
                    p="2rem"
                    border="1px solid #ddd"
                    borderRadius="8px"
                  >
                    <Typography variant="h5" gutterBottom>
                      Leave a Comment
                    </Typography>
                    <TextField
                      label="Message"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={4}
                      value={comment}
                      onChange={handleCommentChange}
                      margin="normal"
                    />
                    <Box display="flex" gap="1rem">
                      <TextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        value={name}
                        onChange={handleNameChange}
                        margin="normal"
                      />
                      <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        value={email}
                        onChange={handleEmailChange}
                        margin="normal"
                      />
                    </Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={saveInfo}
                          onChange={handleSaveInfoChange}
                        />
                      }
                      label="Save my name, email, and website in this browser for the next time I comment."
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCommentSubmit}
                      style={{ backgroundColor: "rgb(0, 34, 73)" }}
                    >
                      Send Comment
                    </Button>
                  </Box>
                  <Box mt="2rem">
                    {comments.map((cmt, index) => (
                      <Box
                        key={index}
                        mt="1rem"
                        p="1rem"
                        border="1px solid #ddd"
                        borderRadius="8px"
                      >
                        <Typography variant="body1">{cmt.comment}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {cmt.name} - {cmt.email}
                          {cmt.website && ` - ${cmt.website}`}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FirstBlog;
