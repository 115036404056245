import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Container,
  Typography,
  Grid,
  Box,
  Modal,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { FaUser } from "react-icons/fa";
import thankyouAnimation from "../../assets/images/NewIcon/Animation - 1720520813759.json";
import aboutImage from "../../assets/FlipImages/contact.webp";
import "./ConatctForm.css";
import contact from "../../assets/images/contact/contactimg.mp4";
import contact1 from "../../assets/images/contact/contact1.mp4";
import contact2 from "../../assets/images/contact/contact2.mp4";
import contact3 from "../../assets/images/contact/contact3.mp4";
import contact4 from "../../assets/images/contact/contact4.mp4";

const countryCodes = [
  { code: "+1", name: "United States" },
  { code: "+44", name: "United Kingdom" },
  { code: "+91", name: "India" },
  { code: "+971", name: "UAE" },
  { code: "+61", name: "Australia" },
  { code: "+49", name: "Germany" },
  { code: "+33", name: "France" },
  { code: "+81", name: "Japan" },
  { code: "+86", name: "China" },
  { code: "+55", name: "Brazil" },
  // Add more country codes as needed
];

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm();
  const [submitted, setSubmitted] = useState(false);
  const [resultTrue, setResultTrue] = useState(false);
  const [resultFalse, setResultFalse] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);

  const proxyUrl = "https://cors-anywhere.herokuapp.com/";
  const targetUrl = "https://api.rungreet.com/cookiestore/api/getContacts";

  const onSubmit = async (data) => {
    try {
      const response = await fetch(proxyUrl + targetUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: data.fullName,
          email: data.email,
          message: data.message,
          subject: data.subject,
          phoneNumber: `${data.countryCode}${data.phone}`,
          enquiryService: null,
        }),
      });

      if (response.ok) {
        setSubmitted(true);
        setResultTrue(true);
        setTimeout(() => {
          setSubmitted(false);
          setResultTrue(false);
          setShowThankYou(true);
          reset(); // Reset the form fields
        }, 1000);
      } else {
        setResultFalse(true);
        setTimeout(() => {
          setResultFalse(false);
          setShowThankYou(true);
          reset(); // Reset the form fields
        }, 1000);
      }
    } catch (error) {
      setResultFalse(true);
      setTimeout(() => {
        setResultFalse(false);
        setShowThankYou(true);
        reset(); // Reset the form fields
      }, 1000);
    }
  };

  const handleCloseThankYou = () => {
    setShowThankYou(false);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: thankyouAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div style={{ backgroundColor: "#f0f0f0", padding: "5px" }}>
      <Container maxWidth="md" className="my-5">
        <div className="row text-center justify-content-center align-content-center">
          <div className="col-lg-12">
            <span
              style={{
                fontWeight: "bold",
                color: "#072f5f",
                fontSize: "25px",
              }}
            >
              Contact Us
            </span>
            <h2
              className="title text-uppercase testword"
              style={{
                fontWeight: "bold",
                fontSize: "25px",
                marginTop: "10px",
                color: "#072f5f",
              }}
            >
              Don't Hesitate To Contact Us, Say Hello!
            </h2>
          </div>
        </div>
        <br />
        <Grid container spacing={4} alignItems="center">
          <Grid item md={6}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextField
                fullWidth
                label="Full Name"
                margin="normal"
                {...register("fullName", { required: "Full Name is required" })}
                error={!!errors.fullName}
                helperText={errors.fullName ? errors.fullName.message : ""}
              />
              <TextField
                fullWidth
                label="Email"
                margin="normal"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: "Invalid email address",
                  },
                })}
                error={!!errors.email}
                helperText={errors.email ? errors.email.message : ""}
              />
              <Box display="flex" alignItems="center" gap="1rem">
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="country-code-label">Code</InputLabel>
                  <Controller
                    name="countryCode"
                    control={control}
                    defaultValue="+91"
                    rules={{ required: "Country code is required" }}
                    render={({ field }) => (
                      <Select
                        labelId="country-code-label"
                        label="Code"
                        {...field}
                      >
                        {countryCodes.map((country) => (
                          <MenuItem key={country.code} value={country.code}>
                            {country.code} ({country.name})
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
                <TextField
                  fullWidth
                  label="Mobile Number"
                  margin="normal"
                  {...register("phone", {
                    required: "Mobile Number is required",
                    pattern: {
                      value: /^[0-9]{10}$/, 
                      message: "Invalid mobile number",
                    },
                  })}
                  error={!!errors.phone}
                  helperText={errors.phone ? errors.phone.message : ""}
                  style={{ marginBottom: "15px" }}
                />
              </Box>
              <TextField
                fullWidth
                label="Subject"
                margin="normal"
                {...register("subject", { required: "Subject is required" })}
                error={!!errors.subject}
                helperText={errors.subject ? errors.subject.message : ""}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    {...register("terms", {
                      required: "You must accept the terms and conditions",
                    })}
                  />
                }
                label={
                  <>
                    I accept the&nbsp;
                    <Link
                      to="/privacypolicy"
                      style={{ textDecoration: "none" }}
                    >
                      terms and conditions
                    </Link>
                  </>
                }
              />
              {errors.terms && (
                <Typography color="error">{errors.terms.message}</Typography>
              )}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{
                  marginTop: "16px",
                  backgroundColor: "rgb(7, 47, 95)",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                }}
              >
                Send Message
              </Button>
            </form>
          </Grid>
          <Grid item md={6} textAlign="center">
            <video
              src={contact3}
              height="300"
              width="500"
              alt="About"
              className="unique-img-fluid unique-rounded"
              loop
              autoPlay
              muted
              // controls
            >
              Your browser does not support the video tag.
            </video>
          </Grid>
        </Grid>
      </Container>

      <Modal open={showThankYou} onClose={handleCloseThankYou}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleCloseThankYou}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <FaUser />
          </IconButton>
          <Typography variant="h6" component="h2">
            Thank You!
          </Typography>
          <Typography>Thank You For Submitting Your Information</Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default ContactForm;
