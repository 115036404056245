import React from 'react';
import cpasolution1 from '../../../assets/images/CPA back-office/CPAsolutions1.png'
import CPAsolutions6 from '../../../assets/images/CPA back-office/CPAsolutions6.png'
import CPAsolutions5 from '../../../assets/images/CPA back-office/CPAsolutions5.png'
import CPAsolutions7 from '../../../assets/images/CPA back-office/CPAsolutions7.png'
import CPAsolutions8 from '../../../assets/images/CPA back-office/CPA4solution8.png'
const Cpaaccounting = () => {

  const styles = {
    container: { marginTop: '5%', marginBottom: '4%' },
    colPaddingLeft: { paddingLeft: '4%' },
    colPaddingRight: { paddingRight: '4%' },
    ulPaddingLeft: { paddingLeft: '3%' },
    bannerImage: {
      background: `url(${cpasolution1}) no-repeat center`,
      backgroundSize: 'cover',
      height: '430px',
    },
    textWhite: { color: 'white' },
    textUppercase: { textTransform: 'uppercase' },
    listItem: { textAlign: 'justify', marginBottom: '15px', marginTop: '10px', color: 'dark', fontWeight:'400' },
    subSpan: { fontSize: '18px', color: '#072F5F', fontWeight: '600' },
    imgBtn: { zIndex: 1, background: '#21ac51', padding: '5px 20px', borderRadius: '20px', position: 'absolute', top: '17rem' },
    imgBtnIndia: { zIndex: 1, background: '#0951A4', padding: '5px 20px', borderRadius: '20px', position: 'absolute', top: '17rem' },
    p: { fontWeight: '500' },
    h1: { fontSize: '48px' },
    h3: { fontSize: '28px' },
    h4: { fontSize: '18px' },
    '@media (min-width:280px)': {
      li: { fontSize: '16px' },
      p: { fontSize: '16px' },
      colPaddingRight: { paddingRight: '4%' },
    },
    '@media (min-width:357px)': {
      li: { fontSize: '16px' },
      colPaddingRight: { paddingRight: '4%' },
    },
    '@media (max-width:575px)': {
      mTop: { marginTop: '0' },
      li: { fontSize: '16px' },
    },
    '@media (min-width:570px)': {
      mTop: { marginTop: '100px' },
      li: { fontSize: '18px' },
    },
    '@media (min-width:767px)': {
      mTop: { marginTop: '100px' },
      li: { fontSize: '18px' },
    },
    '@media (min-width:976px)': {
      mTop: { marginTop: '100px' },
      li: { fontSize: '16px' },
    },
    '@media (max-width:999px)': {
      imgDiv: { display: 'none' },
    },
  };

  return (
    <div className="">
      <div className="d-flex justify-content-center align-items-center banner-image" style={styles.bannerImage}>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <h3 className="text-white text-uppercase" style={{fontWeight:'bold'}}>Accounting</h3>
        </div>
      </div>

      <div className="container">
        <div className="row" style={styles.container}>
          <div className="col-lg-5 justify-content-center align-items-center imgDiv">
            <div className="d-flex justify-content-start align-items-start">
              <img src={CPAsolutions6} className="img-fluid" alt="CPA Solutions 6" />
            </div>
          </div>
          <div className="col-lg-7 pr-0" style={styles.colPaddingLeft}>
            <div>
              <span className="subSpan" style={styles.subSpan}>Book keeping and Accounting</span>
            </div>
            <div style={styles.ulPaddingLeft}>
             
                <li className="mb-15 mt-10 text-dark" style={styles.listItem}>
                  <b>General ledger maintenance: </b> We ensure accurate and up-to-date record-keeping, including journal entries, reconciliations, and adjustments.
                </li>
                <li className="mb-15 mt-10 text-dark" style={styles.listItem}>
                  <b>Accounts payable and receivable: </b> Our team manages vendor invoices, bill payments, customer invoicing, and collections.
                </li>
                <li className="mb-15 mt-10 text-dark" style={styles.listItem}>
                  <b>Financial statement preparation:</b> We compile financial statements, including balance sheets, income statements, and cash flow statements, adhering to accounting standards.
                </li>
            </div>
          </div>
        </div>

        <div className="row" style={styles.container}>
          <div className="col-lg-7" style={styles.colPaddingRight}>
            <div>
              <span className="subSpan" style={styles.subSpan}>Financial Analysis and Reporting</span>
            </div>
            <div style={styles.ulPaddingLeft}>
              
                <li className="mb-15 mt-10 text-dark" style={styles.listItem}>
                  <b>Ratio analysis: </b> We analyze financial ratios to assess your clients' financial health, performance, and profitability.
                </li>
                <li className="mb-15 mt-10 text-dark" style={styles.listItem}>
                  <b>Budgeting and forecasting: </b> Our team helps in developing financial budgets and forecasts, providing insights into future financial performance.
                </li>
                <li className="mb-15 mt-10 text-dark" style={styles.listItem}>
                  <b>Management reporting: </b> We create customized reports and dashboards to communicate financial information effectively to your clients.
                </li>
                
            </div>
          </div>
          <div className="col-lg-5 justify-content-center align-items-center imgDiv">
            <div className="d-flex justify-content-start align-items-start">
              <img src={CPAsolutions7} className="img-fluid" alt="CPA Solutions 7" />
            </div>
          </div>
        </div>

        <div className="row" style={styles.container}>
          <div className="col-lg-5 justify-content-center align-items-center imgDiv">
            <div className="d-flex justify-content-start align-items-start">
              <img src={CPAsolutions8} className="img-fluid" alt="CPA Solutions 8" />
            </div>
          </div>
          <div className="col-lg-7 pr-0" style={styles.colPaddingLeft}>
            <div>
              <span className="subSpan" style={styles.subSpan}>Payroll Processing</span>
            </div>
            <div style={styles.ulPaddingLeft}>
              
                <li className="mb-15 mt-10 text-dark" style={styles.listItem}>
                  <b>Payroll administration: </b> We handle payroll processing, including calculation of employee salaries, deductions, and tax withholdings.
                </li>
                <li className="mb-15 mt-10 text-dark" style={styles.listItem}>
                  <b>Payroll tax filings: </b> Our team prepares and files quarterly and annual payroll tax returns, including W-2 and 1099 forms.
                </li>
              
            </div>
          </div>
        </div>

        <div className="row" style={styles.container}>
          <div className="col-lg-7 pr-0" style={styles.colPaddingRight}>
            <div>
              <span className="subSpan" style={styles.subSpan}>Practice Management Support</span>
            </div>
            <div style={styles.ulPaddingLeft}>
             
                <li className="mb-15 mt-10 text-dark" style={styles.listItem}>
                  <b>Client onboarding and engagement: </b> We assist in client onboarding processes, including engagement letters, client agreements, and client database management.
                </li>
                <li className="mb-15 mt-10 text-dark" style={styles.listItem}>
                  <b>Time and billing: </b> Our team helps track billable hours, prepare client invoices, and manage accounts receivable.
                </li>
                <li className="mb-15 mt-10 text-dark" style={styles.listItem}>
                  <b>Workflow management: </b> We streamline workflow processes, ensuring efficient project management and resource allocation within your practice.
                </li>
             
            </div>
          </div>
          <div className="col-lg-5 justify-content-center align-items-center imgDiv">
            <div className="d-flex justify-content-start align-items-start">
              <img src={CPAsolutions5} className="img-fluid" alt="CPA Solutions 5" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cpaaccounting;

