import React from 'react';
import law from './assets/images/NewIcon/law.png'
import isosymbol from './assets/images/NewIcon/iso-symbol.png'
import file from './assets/images/NewIcon/file.png';
import endpoint from './assets/images/NewIcon/endpoint.png';
import Boston from './assets/images/NewIcon/Boston.jpg';
import trustcenterbanner from './assets/images/NewIcon/trustCenterBanner.jpeg';
import { Link } from "react-router-dom";

const TrustCenter = () => {
  return (
    <div className="trust-center">
      
      <style>
        {`
          .custom-bg-color {
              background-color: #1abc9c;
              transition: background-color 0.3s ease;
          }
          .custom-text-color {
              color: #030303;
              transition: color 0.3s ease;
          }
          .custom-bg-color:hover {
              background-color: #6fe6ce;
          }
          .custom-text-color:hover {
              color: #000000;
          }
          .custom-text-color .card-text {
              font-weight: 400;
              font-size: 16px;
              color: #000000;
          }
          .custom-column {
              margin-right: 80px;
              margin-left: 80px;
          }
          .demu {
              margin-top: 137px;
          }
          .email-address {
              margin-left: 36px;
          }
          .custom-img {
              width: 80px;
              height: 80px;
              object-fit: cover;  
              // display: block;
              margin: 0 auto 10px;            
          }
          .custom-bg-color {
              background-color: #fff;
              border-radius: 10px;
              box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
          }
          .custom-bg-color:hover {
              box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
          }
          .heading {
              font-size: 30px;
              color: #072f5f;
             
          }
                .card-title {
              font-size: 20px;
              font-weight: bold;
              margin-bottom: 15px;
          }
          .card-text {
              font-size: 16px;
              color: #000000;
          }
        `}
      </style>

      <div className="col p-0 m-0">
        <div
          className="d-flex justify-content-center align-items-center banner-image"
          style={{
            background: `url('${trustcenterbanner}') no-repeat center`,
            backgroundSize: 'cover',
            height: '430px'
          }}
        >
          <div className="d-flex flex-column justify-content-center align-items-center"></div>
        </div>
        <div className="row p-4 pb-10 pe-lg-0 pt-lg-5 align-items-center rounded-3 border shadow-lg">
          <div className="col-lg-7 p-3 p-lg-5 pt-lg-3">
            <div className="lc-block mb-3">
              <div>
                <div className="mb-30">
                  <h5 style={{ lineHeight: 1.5, fontWeight:'700' }}>
                    Strengthen your infrastructure resilience, security, compliance,&nbsp; admin
                    control, and more with Boston Financial Advisory Group
                  </h5>
                </div>
              </div>
            </div>
            <div className="lc-block mb-3">
              <div>
                <p className="" style={{ color: '#000000', lineHeight: 1.5 }}>
                  Our goal is to create the most trusted platform, and your trust is at the center of
                  everything we do. Our products, policies, processes and systems are designed to protect
                  you, your rights and your data; creating a safe place for you to share your valuable
                  information.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 offset-lg-1 p-0 overflow-hidden ml-2">
            <div className="lc-block">
              <img
                className="rounded-start "
                src={Boston}
                alt="Boston Financial Advisory Group"
                // width="720"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="privacy-policy-area pt-100 pb-70">
        <div className="container background-cr">
          <div className="single-content">
            <div className="privacy-policy-area pt-20 pb-70">
              <div className="container">
                <div className="row">
                  <div className="col-md-4 mb-4">
                    <div className="card custom-bg-color custom-text-color" style={{width:'358px', height:'294px'}}>
                      <div className="card-body text-center">
                        <img src={file} alt="Privacy Policy" className="custom-img" /><br/>
                        <h5 className="card-title">Privacy Policy</h5>
                        <p className="card-text text-center">
                          We protect the confidentiality of information and the privacy of its clients, candidates, and
                          other users of the website.
                        </p>
                        <p className="card-text text-center">
                          <Link to="/privacypolicy">Read More</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mb-4">
                    <div className="card custom-bg-color custom-text-color" style={{width:'358px', height:'294px'}}>
                      <div className="card-body text-center">
                        <img src={isosymbol} alt="ISO 27001" className="custom-img" /><br/>
                        <h5 className="card-title">ISO 27001</h5>
                        <p className="card-text text-center">
                          We are ISO 27001 Compliant Company to ensure your data is stored securely and following all
                          secure methods.
                        </p>
                        <br />
                        <p className="card-text text-center">
                          <Link to="/isopage">Read More</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mb-4">
                    <div className="card custom-bg-color custom-text-color" style={{width:'358px', height:'294px'}}>
                      <div className="card-body text-center">
                        <img src={law} alt="CCPA" className="custom-img" /><br/>
                        <h5 className="card-title">CCPA</h5>
                        <p className="card-text text-center">
                          We do not sell your personal information or share your information under the California
                          Consumer Privacy Act of 2018 (“CCPA”).
                        </p>
                        <p className="card-text text-center">
                          <Link to="/ccpapage">Read More</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                  <div className="col-md-4 mb-4">
                            <div className="card custom-bg-color custom-text-color" style={{width:'358px', height:'294px'}}>
                           
                                <div className="card-body text-center">
                                <img src={endpoint} alt="Image 3" className="custom-img" /><br/>
                                    <h5 className="card-title">End Point Security</h5>
                                    <p className="card-text text-center">We are using world class End-Point Security to protect our devices from cyber-attacks and malicious activity.
                                    </p>
                                    <p className="card-text text-center">
                                    <Link to="/endpointsecuritypage">Read More</Link>
                                      </p>
                                </div>
                            </div>
                        </div>
                        </div>
                </div>
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrustCenter;
