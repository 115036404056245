import React from "react";
import "./ourteam.css";
import { Link } from "react-router-dom";
import prafulla from "../../../assets/images/teams/prafulla.png";
import sameer from "../../../assets/images/teams/sameer.png";
import sanjay from "../../../assets/images/teams/sanjay.png";
import leftvector from "../../../assets/images/home-vector-left.png";
import rightvector from "../../../assets/images/home-vector-right.png";
import Anoop from "../../../assets/images/teams/AMehta.jpg";
import ketan from "../../../assets/images/teams/KetanChoksi.jpg";

const OurTeam = () => {
  return (
    <>
      <img
        src={leftvector}
        alt="shape"
        style={{
          position: "absolute",
          overflow: "clip",
          paddingTop: "55px",
          maxWidth: "21%",
        }}
      />
      <img
        src={rightvector}
        alt="shape"
        style={{
          position: "absolute",
          overflow: "clip",
          right: "0%",
          paddingTop: "55px",
          maxWidth: "21%",
        }}
      />

      <div
        className="services-plans-dark-area custom-css-services dec-spacing mt-50"
        style={{ background: "#f4fbff" }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="pb-2 mt-5">
              <p
                className="text-center"
                style={{ fontWeight: "bold", fontSize: "16px", color:'#072f5f' }}
              >
                OUR TEAM
              </p>
            </div>
          </div>
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-12 col-md-12">
              <div className="services-title mb-50 text-center">
                <h3
                  className="title"
                  style={{ display: "inline", fontWeight: "bold", color:'#072f5f' }}
                >
                  Advisory Board
                </h3>
              </div>
            </div>
          </div>
          <div className="tab-content" id="pills-tabContent">
            <div
              className="row d-flex justify-content-center align-items-center"
              style={{ gap: "3rem" }}
            >
              {/* * */}
              <Link
                to="/Anoopmehta"
                className="carrd cursor-pointer border-0 text-white shadeCSS mb-5"
                style={{
                  height: "280px",
                  width: "200px",
                  position: "relative",
                }}
              >
                <div
                  className="card cursor-pointer border-0 text-white shadeCSS"
                  style={{
                    height: "280px",
                    width: "200px",
                    position: "relative",
                  }}
                >
                  <img
                    src={Anoop}
                    className="card-img img-fluid"
                    alt="..."
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                      borderRadius: "10px",
                    }}
                  />
                  <div className="teamTital" style={{ marginLeft: "6%" }}>
                    <h3
                      className="text-uppercase text-center text-white m-0"
                      style={{ margin: 0, color: "white", fontSize: "16px",fontWeight:'bold' }}
                    >
                      Anoop Natwar Mehta
                    </h3>
                    <div style={{ marginLeft: "22%" }}>
                      <span
                        className="text-white"
                        style={{
                          margin: 0,
                          transition: "all 0.3s",
                          fontSize: "16px",
                        }}
                      >
                        Advisor
                      </span>
                      <i
                        className="fab fa-linkedin-in"
                        style={{
                          marginLeft: "25px",
                          backgroundColor: "#0072b1",
                          padding: "4px",
                          borderRadius: "5px",
                          fontSize: "14px",
                          color: "#fff",
                        }}
                      ></i>
                    </div>
                  </div>
                </div>
              </Link>
              {/* * */}

              {/* Team Member 1 */}
              <Link
                to="/prafullachajed"
                className="carrd cursor-pointer border-0 text-white shadeCSS mb-5"
                style={{
                  height: "280px",
                  width: "200px",
                  position: "relative",
                }}
              >
                <div
                  className="card cursor-pointer border-0 text-white shadeCSS"
                  style={{
                    height: "280px",
                    width: "200px",
                    position: "relative",
                  }}
                >
                  <img
                    src={prafulla}
                    className="card-img img-fluid"
                    alt="..."
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                  <div className="teamTital" style={{ marginLeft: "6%" }}>
                    <h3
                      className="text-uppercase text-center text-white m-0"
                      style={{ margin: 0, color: "white", fontSize: "16px", fontWeight:'bold' }}
                    >
                      Prafulla Chhajed
                    </h3>
                    <div style={{ marginLeft: "22%" }}>
                      <span
                        className="text-white"
                        style={{
                          margin: 0,
                          transition: "all 0.3s",
                          fontSize: "16px",
                        }}
                      >
                        Advisor
                      </span>
                      <i
                        className="fab fa-linkedin-in"
                        style={{
                          marginLeft: "25px",
                          backgroundColor: "#0072b1",
                          padding: "4px",
                          borderRadius: "5px",
                          color: "#fff",
                          fontSize: "14px",
                        }}
                      ></i>
                    </div>
                  </div>
                </div>
              </Link>

              {/* Team Member 2 */}
              <Link
                to="/sanjaykapadia"
                className="carrd cursor-pointer border-0 text-white shadeCSS mb-5"
                style={{
                  height: "280px",
                  width: "200px",
                  position: "relative",
                }}
              >
                <div
                  className="card border-0 cursor-pointer rounded text-white shadeCSS"
                  style={{
                    height: "280px",
                    width: "200px",
                    position: "relative",
                  }}
                >
                  <img
                    src={sanjay}
                    className="card-img img-fluid"
                    alt="..."
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                  <div className="teamTital" style={{ marginLeft: "10%" }}>
                    <h3
                      className="text-uppercase text-center text-white m-0"
                      style={{ margin: 0, color: "white", fontSize: "16px",fontWeight:'bold' }}
                    >
                      {" "}
                      Sanjay Kapadia
                    </h3>
                    <div >
                      <span
                        style={{
                          margin: 0,
                          color: "white",
                          transition: "all 0.3s",
                          fontSize: "16px",
                        }}
                      >
                        Advisor
                      </span>
                      <i
                        className="fab fa-linkedin-in"
                        style={{
                          marginLeft: "25px",
                          backgroundColor: "#0072b1",
                          color: "#fff",
                          padding: "4px",
                          borderRadius: "5px",
                          fontSize: "14px",
                        }}
                      ></i>
                    </div>
                  </div>
                </div>
              </Link>

              {/* Team Member 3 */}
              <Link
                to="/ketanchokshi"
                className="carrd cursor-pointer border-0 text-white shadeCSS mb-5"
                style={{
                  height: "280px",
                  width: "200px",
                  position: "relative",
                }}
              >
                <div
                  className="card border-0 rounded cursor-pointer text-white shadeCSS"
                  style={{
                    height: "280px",
                    width: "200px",
                    position: "relative",
                  }}
                >
                  <img
                    src={ketan}
                    className="card-img img-fluid"
                    alt="..."
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                  <div className="teamTital" style={{ marginLeft: "11%" }}>
                    <h3
                      className="text-uppercase text-center text-white m-0"
                      style={{ margin: 0, fontSize: "16px",fontWeight:'bold' }}
                    >
                      Ketan Chokshi
                    </h3>
                    <div style={{ marginLeft: "15%" }}>
                      <span
                        className="text-white"
                        style={{
                          margin: 0,
                          transition: "all 0.3s",
                          fontSize: "16px",
                        }}
                      >
                        Advisor
                      </span>
                      <i
                        className="fab fa-linkedin-in"
                        style={{
                          marginLeft: "25px",
                          color: "#fff",
                          backgroundColor: "#0072b1",
                          padding: "4px",
                          borderRadius: "5px",
                          fontSize: "14px",
                        }}
                      ></i>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurTeam;
