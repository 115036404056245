import React from 'react';
import casestudy from '../../../assets/images/case-studies/case-study-main-banner.png'
import law from '../../../assets/images/case-studies/law.png'

const Lawfirmcasestudy = () => {
 
  return (
    <>
      <style jsx>{`
      h4 {
        font-size: 18px;
      }
      h3 {
        font-size: 28px;
      }
      span {
        font-size: 16px;
      }
      p {
        font-size: 16px;
      }
      li {
        font-size: 16px;
        font-weight: 500;
      }
      .mainCard {
        height: 20rem;
      }
      .circle {
        width: 100px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        overflow: hidden;
      }
      i {
        color: #072f5f;
      }
      ul {
        padding-left: 17px;
      }
      @media (max-width: 574px) {
        .mTop {
          margin-top: 30px;
        }
        p {
          font-size: 18px;
        }
        .img-fluid {
          min-height: 100px;
        }
      }
      @media (min-width: 992px) {
        /* for banner image */
        .bmTop {
          margin-top: 130px;
        }
      }
    `}</style>

      <section className=""
      
      
      >
        <div
          className="d-flex justify-content-center align-items-center banner-image"
          style={{
            background: `url(${casestudy}) no-repeat center`,
            backgroundSize: 'cover',
            height: '430px'
          }}
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h3 className="text-white text-uppercase text-center" style={{fontWeight:'bold'}}>
              Law Firm Case <br />
              Study
            </h3>
          </div>
        </div>
        <div className="container-fluid">
          <div className="d-flex row justify-content-center align-items-start pl-4 pr-4" style={{ gap: '2rem' }}>
            <div className="col-lg-8 mt-50 mb-50 border shadow" style={{ padding: '2rem' }}>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <img className="img-fluid" src={law} alt="Law Firm" style={{ marginBottom: '2rem' }} />
                <div>
                  <div
                    style={{
                      background: '#072f5f',
                      display: 'inline',
                      width: 'fit-content',
                      padding: '0.2rem 1rem 0.2rem 1rem'
                    }}
                  >
                    <small className="text-uppercase text-white" style={{ fontWeight: '600', fontSize: '15px' }}>
                      case details
                    </small>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span className="text-capitalize" style={{ fontSize: '1.5rem', fontWeight: '700', color: '#002249' }}>
                        overview
                      </span>
                    </div>
                    <p className="text-dark servicep" style={{textAlign:'justify'}}>
                      Law Firm specialized in real estate closing support services and advisory services. The team, The team comprises of best of the
                      lawyers each heading a different expert area.
                    </p>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span className="text-capitalize" style={{ fontSize: '1.5rem', fontWeight: '700', color: '#002249' }}>
                        Client
                      </span>
                    </div>
                    <ul className="text-dark servicep" style={{ listStyleType: 'disc',textAlign:'justify' }}>
                      <li>Washburn Law PLLC</li>
                      <li>
                        Law firm having offices in North & South Carolina, Georgia. Expertise in providing legal services in areas such as real estate
                        Closing support services.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span className="text-capitalize" style={{ fontSize: '1.5rem', fontWeight: '700', color: '#002249' }}>
                        Strategies Described
                      </span>
                    </div>
                    <ul className="text-dark servicep" style={{ listStyleType: 'disc',textAlign:'justify' }}>
                      <li>Defining the proper off-site Accounting organization structure and providing capable resources.</li>
                      <li>Defining the SLA’s for keeping the F & A function online, defining the roadmap for ongoing accounting activity.</li>
                    </ul>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span className="text-capitalize" style={{ fontSize: '1.5rem', fontWeight: '700', color: '#002249' }}>
                        Client's Challenges
                      </span>
                    </div>
                    <ul className="text-dark servicep" style={{ listStyleType: 'disc', textAlign:'justify' }}>
                      <li>No proper Books of Accounts, QB was used to only print checks.</li>
                      <li>Everything was done manually, Lack of proper systems.</li>
                      <li>Lack of information, if at all information was available it was in scattered form.</li>
                      <li>No accounting policies in place. AP, AR reimbursements were carried out blindly without any supporting and based on the demand from vendors and employees.</li>
                      <li>Lack of co-ordination with the offshore operations team.</li>
                    </ul>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span className="text-capitalize" style={{ fontSize: '1.5rem', fontWeight: '700', color: '#002249' }}>
                        Solution Provider
                      </span>
                    </div>
                    <ul className="text-dark servicep" style={{ listStyleType: 'disc', textAlign:'justify' }}>
                      <li>Initially, Accounting organization structure with onsite and offsite mix was put in place with capable & experienced resources. Once function stabilized, the accounting organization structure was changed to offsite, this resulted in huge savings in payroll but without compromise on quality.</li>
                      <li>Complete understanding of business was sought and accounting policies, internal controls were put in place. System of payments was put in place.</li>
                      <li>AR reconciliation, Monthly/Daily AR reporting was put in place.</li>
                      <li>AP reconciliation, Monthly/Daily AP reporting was put in place.</li>
                      <li>Monthly/quarterly MIS reports with emphasis on KPI’s was put in place.</li>
                    </ul>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span className="text-capitalize" style={{ fontSize: '1.5rem', fontWeight: '700', color: '#002249' }}>
                        Outcome
                      </span>
                    </div>
                    <ul className="text-dark servicep" style={{ listStyleType: 'disc', textAlign:'justify' }}>
                      <li>Better coordination between operations and Accounts departments.</li>
                      <li>Proper recording of vendor invoices after approvals and processing payments as per schedule. Excess payments were stopped, instances of overdue reduced to a large extent.</li>
                      <li>Excellent coordination between operations and Accounting function.</li>
                      <li>Increased collections from customers resulting in reduced collection time. Complete stop to revenue leakages.</li>
                      <li>Accurate &Timely MIS reporting.</li>
                    </ul>
                  </div>
                  <div>
                    <div className="mt-15">
                      <span className="text-capitalize" style={{ fontSize: '1.5rem', fontWeight: '700', color: '#002249' }}>
                        Client Testimonial
                      </span>
                    </div>
                    <p className="text-dark servicep" style={{ fontStyle: 'italic' }}>
                      Law Firm specialized in real estate closing support services and advisory services. The team, The team comprises of best of the
                      lawyers each heading a different expert area.
                    </p>
                    <p className="text-dark servicep font-weight-light mt-5" style={{ fontStyle: 'italic' }}>
                      – Jack Munsey (CEO)
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="mt-50 border d-flex flex-column justify-content-start align-items-start" style={{ padding: '2rem' }}>
              <div className="mt-15 mb-10 d-flex justify-content-center align-items-center" style={{ gap: '5px' }}>
                <div style={{ border: '3px solid #072f5f', width: '1.5rem', height: '1px' }}></div>
                <div style={{ border: '3px solid #072f5f', width: '0.5rem', height: '1px' }}></div>
                <div>
                  <span className="text-capitalize" style={{ fontSize: '1.5rem', fontWeight: '700', color: '#002249' }}>
                    Case Studies
                  </span>
                </div>
              </div>
              <div className="d-flex justify-content-start align-items-start" style={{ width: '18rem', gap: '1rem', padding: '1rem', borderBottom: '2px solid #e3eeff' }}>
                <img className="img-fluid" src={img1} alt="Card image cap" />
                <div>
                  <small className="text-capitalize text-dark" style={{ fontWeight: '600', fontSize: '15px' }}>
                    Emirates Facilities <br />
                    Management
                  </small>
                  <div>
                    <small className="text-capitalize" style={{ fontWeight: '600', fontSize: '13px', color: '#69a1bb' }}>
                      <i className="fas fa-calendar-alt"></i> Jul 19, 2020
                    </small>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-start align-items-start" style={{ width: '18rem', gap: '1rem', padding: '1rem', borderBottom: '2px solid #e3eeff' }}>
                <img className="img-fluid" src={img2} alt="Card image cap" />
                <div>
                  <small className="text-capitalize text-dark" style={{ fontWeight: '600', fontSize: '15px' }}>
                    Emirates Specialities Co. <br />
                    L.L.C
                  </small>
                  <div>
                    <small className="text-capitalize" style={{ fontWeight: '600', fontSize: '13px', color: '#69a1bb' }}>
                      <i className="fas fa-calendar-alt"></i> Jul 7, 2020
                    </small>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-start align-items-start" style={{ width: '18rem', gap: '1rem', padding: '1rem', borderBottom: '2px solid #e3eeff' }}>
                <img className="img-fluid" src={img3} alt="Card image cap" />
                <div>
                  <small className="text-capitalize text-dark" style={{ fontWeight: '600', fontSize: '15px' }}>
                    Specon LLC
                  </small>
                  <div>
                    <small className="text-capitalize" style={{ fontWeight: '600', fontSize: '13px', color: '#69a1bb' }}>
                      <i className="fas fa-calendar-alt"></i> Jun 17, 2020
                    </small>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-start align-items-start" style={{ width: '18rem', gap: '1rem', padding: '1rem', borderBottom: '2px solid #e3eeff' }}>
                <img className="img-fluid" src={img4} alt="Card image cap" />
                <div>
                  <small className="text-capitalize text-dark" style={{ fontWeight: '600', fontSize: '15px' }}>
                    Al Ahmadiah <br />
                    Contracting & Trading
                  </small>
                  <div>
                    <small className="text-capitalize" style={{ fontWeight: '600', fontSize: '13px', color: '#69a1bb' }}>
                      <i className="fas fa-calendar-alt"></i> Jun 13, 2020
                    </small>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Lawfirmcasestudy;
