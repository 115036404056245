import React, { useState } from 'react';
import dsarbanner from '../../assets/images/NewIcon/dsarBanner.jpg'
import { Button } from '@mui/material'; 

const Dsarpage = () => {
  const [formData, setFormData] = useState({
    userName: '',
    emailAddress: '',
    requestAs: '',
    ccpaOhters: '',
    comment: '',
    confirmed: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    // Handle form submission logic here
  };

  return (
    <>
      <style jsx>{`
        .form-container {
          width: 90%;
          margin: 0 auto;
          background-color: #f0f0f0;
          padding: 25px;
          overflow-y: auto;
        }
        .form-group {
          margin-bottom: 20px;
        }
        label {
          display: block;
        }
        .button {
          width: 200px;
        }
        .form-control {
          width: 100%;
        }
        .btn-container {
          text-align: center;
        }
        .button {
          width: 210px;
        }
        .head {
          margin-top: 30px;
          margin-bottom: 20px;
        }
        .select-wrapper select {
          max-height: 200px;
          overflow-y: auto;
        }
        .inner-banner {
          background: url('assets/images/NewIcon/dsarBanner.jpg') no-repeat center;
          background-size: cover;
          height: 430px;
        }
      `}</style>

      <div className="col p-0 m-0">
        <div
          className="d-flex justify-content-center align-items-center banner-image"
          style={{
            background: `url(${dsarbanner}) no-repeat center`,
            backgroundSize: 'cover',
            height: '430px',
          }}
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            {/* <h3 className="text-white">TRUST CENTER</h3> */}
            {/* <h4 className="cursor-pointer pt-2 shade" style={{ color: '#69a1bb' }}>Home | Services</h4> */}
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-lg-8 mx-auto text-center">
            <div className="lc-block mb-4">
              <div>
                <p className="badge bg-dark mb-0 mt-1">DSAR</p>
                <h3 className="head" style={{color: '#072f5f' , fontSize:'25px'}}>DSAR (data subject access request)</h3>
              </div>
            </div>
            <div className="lc-block">
              <div>
                {/* Content goes here */}
              </div>
            </div>
          </div>
        </div>

        <div className="form-container" style={{fontSize:'15px'}}>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="website">Website:</label>
              <input
                type="url"
                className="form-control"
                name="websiteUrl"
                value="https://www.bostonfagroup.com/"
                disabled
              />
            </div>

            <div className="form-group">
              <label htmlFor="userName">Your Name :</label>
              <input
                type="text"
                className="form-control"
                id="userName"
                name="userName"
                value={formData.userName}
                onChange={handleChange}
                placeholder="Enter Your Name"
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">What email address do you use to access the above website/portal</label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="emailAddress"
                value={formData.emailAddress}
                onChange={handleChange}
                placeholder="Enter Your Email"
              />
            </div>

            <div className="form-group">
              <label>You are submitting this request as</label>
              <div className="form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  name="requestAs"
                  value="The person or the parent/guardian of the person, whose name appears above"
                  checked={formData.requestAs === 'The person or the parent/guardian of the person, whose name appears above'}
                  onChange={handleChange}
                />
                <label className="form-check-label">
                  The person or the parent/guardian of the person, whose name appears above
                </label>
              </div>
              <div className="form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  name="requestAs"
                  value="An agent authorized by the consumer to make this request on their behalf"
                  checked={formData.requestAs === 'An agent authorized by the consumer to make this request on their behalf'}
                  onChange={handleChange}
                />
                <label className="form-check-label">
                  An agent authorized by the consumer to make this request on their behalf
                </label>
              </div>
            </div>

            <div className="form-group">
              <label>CCPA/Other:</label>
              <div className="form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  name="ccpaOhters"
                  value="Know what information is being collected from me"
                  checked={formData.ccpaOhters === 'Know what information is being collected from me'}
                  onChange={handleChange}
                />
                <label className="form-check-label">
                  Know what information is being collected from me
                </label>
              </div>
              <div className="form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  name="ccpaOhters"
                  value="Have my information deleted"
                  checked={formData.ccpaOhters === 'Have my information deleted'}
                  onChange={handleChange}
                />
                <label className="form-check-label">
                  Have my information deleted
                </label>
              </div>
              <div className="form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  name="ccpaOhters"
                  value="Access my personal information"
                  checked={formData.ccpaOhters === 'Access my personal information'}
                  onChange={handleChange}
                />
                <label className="form-check-label">
                  Access my personal information
                </label>
              </div>
              <div className="form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  name="ccpaOhters"
                  value="Fix Inaccurate information"
                  checked={formData.ccpaOhters === 'Fix Inaccurate information'}
                  onChange={handleChange}
                />
                <label className="form-check-label">
                  Fix Inaccurate information
                </label>
              </div>
              <div className="form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  name="ccpaOhters"
                  value="Receive a copy of my personal information"
                  checked={formData.ccpaOhters === 'Receive a copy of my personal information'}
                  onChange={handleChange}
                />
                <label className="form-check-label">
                  Receive a copy of my personal information
                </label>
              </div>
              <div className="form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  name="ccpaOhters"
                  value="Opt out of having my data shared for cross-context behavioral advertising"
                  checked={formData.ccpaOhters === 'Opt out of having my data shared for cross-context behavioral advertising'}
                  onChange={handleChange}
                />
                <label className="form-check-label">
                  Opt out of having my data shared for cross-context behavioral advertising
                </label>
              </div>
              <div className="form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  name="ccpaOhters"
                  value="Limit the use and disclosure of my sensitive personal information"
                  checked={formData.ccpaOhters === 'Limit the use and disclosure of my sensitive personal information'}
                  onChange={handleChange}
                />
                <label className="form-check-label">
                  Limit the use and disclosure of my sensitive personal information
                </label>
              </div>
              <div className="form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  name="ccpaOhters"
                  value="Other (Please specify in comment box below)"
                  checked={formData.ccpaOhters === 'Other (Please specify in comment box below)'}
                  onChange={handleChange}
                />
                <label className="form-check-label">
                  Other (Please specify in comment box below)
                </label>
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="additionalComments">Additional Comments:</label>
              <textarea
                className="form-control"
                id="additionalComments"
                name="comment"
                value={formData.comment}
                onChange={handleChange}
                rows="4"
                placeholder="Enter your additional comments here"
              ></textarea>
            </div>

            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="confirmCheck"
                name="confirmed"
                checked={formData.confirmed}
                onChange={handleChange}
                required
              />
              <label className="form-check-label" htmlFor="confirmCheck">
                I confirm that under penalty of perjury, I declare all the above information to be true and accurate.
              </label>
            </div>

            <p>&nbsp;</p>
            <div className="btn-container">
            <Button
              className='buttonUnique'
              variant="contained"
              color="primary"
              style={{ backgroundColor: '#072f5f', color: 'white', width:'15%' }}
            >
              Submit
            </Button>
            </div>
          </form>
        </div>
        <p>&nbsp;</p>
      </div>
    </>
  );
};



export default Dsarpage