import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import videoBackground from "../../../assets/video/boston baneer video.mp4";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ContactForm from "../../Service/ContactForm";
import rectangle1 from "../../../assets/images/case-studies/Rectangle1.png";
import rectangle2 from "../../../assets/images/case-studies/Rectangle 2.png";
import rectangle3 from "../../../assets/images/case-studies/Rectangle 3.png";
import FlipCardRow from "../../../FlipCard/FlipCard";
import Tagline from "../../../Tagline";
import services from "../../../assets/images/service/service.png";
import Flipcardforservicesonly from "../../../FlipCard/Flipcardforservicesonly";

const Services = () => {
  
  const [selectedCountry, setSelectedCountry] = useState(null);

  useEffect(() => {
    const country = localStorage.getItem("selectedCountry");
    setSelectedCountry(country);
    console.log("Selected Country:", country); // Debugging: check the value
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "none" }}
        onClick={onClick}
      />
    );
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "none" }}
        onClick={onClick}
      />
    );
  }

  return (
    <>
      <style>
        {`
        .case-card {
          position: relative;
          overflow: hidden;
        }
        
        .case-card img {
          object-fit: cover;
          height: 13rem;
          width: 22rem;
        }
        
        .case-card .gradient {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(to top, rgba(7, 47, 95, 0.8), transparent);
          opacity: 0;
          transition: opacity 0.3s ease;
        }
        
        .case-card:hover .gradient {
          opacity: 1;
        }
        
        .case-card .text {
          position: absolute;
          bottom: 20px;
          left: 20px;
          z-index: 1;
          color: white;
          width: calc(100% - 40px);
          transition: bottom 0.3s ease;
        }
        
        .case-card:hover .text {
          bottom: 40px;
        }
        
        .case-card .text p {
          font-size: 1.2rem;
          margin: 0;
          padding: 0;
        }
        `}
      </style>
      <div>
        <div
          className="d-flex justify-content-center align-items-center banner-image"
          style={{
            background: `url(${services}) no-repeat center`,
            backgroundSize: "cover",
            height: "430px",
          }}
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h2
              className="text-white text-uppercase"
              style={{ fontWeight: "bold", color:'#072f5f' }}
            >
              Services
            </h2>
          </div>
        </div>

        {/* <FlipCardRow /> */}
        <Flipcardforservicesonly/>

        <div className="container-fluid pt-80 pb-50 ">
          <div className="row justify-content-center">
            {" "}
            {/* Center align the row */}
            <div className="col-lg-10 col-md-6">
              <div className="text-center mb-4">
                <h3
                  className="title p-0 m-0"
                  style={{
                    textDecorationColor: "#072f5f",
                    fontSize: "28px",
                    fontWeight: "bold",
                    color: "#072f5f",
                  }}
                >
                  FAQs For CPA Back Office
                </h3>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            {" "}
            {/* Center align the cards */}
            <div className="col-md-6 col-lg-3 mb-3">
              <div
                className="border-0 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".2s"
                style={{ cursor: "pointer", width: "100% !important" }}
              >
                <Link
                  to="/auditsupport"
                  className="shadow case-card-link"
                  style={{ textDecoration: "none" }}
                >
                  <div className="case-card">
                    <img src={rectangle1} alt="Image" />
                    <div className="gradient"></div>
                    <div className="text">
                      <p>Audit Support</p>
                    </div>
                    {/* <i className="fas fa-arrow-right case-icon"></i> */}
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 mb-3">
              <div
                className="border-0 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".4s"
                style={{ cursor: "pointer", width: "100% !important" }}
              >
                <Link
                  to="/taxreturnpreparation"
                  className="shadow"
                  style={{ textDecoration: "none" }}
                >
                  <div className="case-card">
                    <img src={rectangle2} alt="Image" />
                    <div className="gradient"></div>
                    <div className="text">
                      <p>Tax Preparation</p>
                    </div>
                    {/* <i className="fas fa-arrow-right case-icon"></i> */}
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 mb-3">
              <div
                className="border-0 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".6s"
                style={{ cursor: "pointer", width: "100% !important" }}
              >
                <Link
                  to="/accounting"
                  className="shadow"
                  style={{ textDecoration: "none" }}
                >
                  <div className="case-card">
                    <img src={rectangle3} alt="Image" />
                    <div className="gradient"></div>
                    <div className="text">
                      <p>Accounting</p>
                    </div>
                    {/* <i className="fas fa-arrow-right case-icon"></i> */}
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* FAQ */}
        <ContactForm />
      </div>
      {/* <Tagline/> */}
    </>
  );
};

export default Services;
