import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import videoBackground from "./assets/video/boston baneer video.mp4"; // Add your video file here
import Service from "./component/Service/Service";
import TestimonialSection2 from "./component/Service/TestimonialSection2";
import CaseStudy from "./component/Service/CaseStudy/Casestudy";
import OurTeam from "./component/Service/OurTeam/Ourteam";
import ContactForm from "./component/Service/ContactForm";
import ProductPage from "./component/Service/Product/ProductPage";
import BlogOne from "./component/blogOne";
import BlogTwo from "./component/BlogTwo";
import aboutImage from "./assets/images/CPA back-office/CPAsolutions3.png";
import taxabt from "./assets/images/CPA back-office/CPA3.png";
import cpaabt from "./assets/images/CPA back-office/CPAsolutions4.png";
import FlipCardRow from "./FlipCard/FlipCard";
import VissionMisson from "./component/OurVissionMission/VissionMisson";
import Visionmissiongoal from "./component/OurVissionMission/Visionmissiongoal";
import { Button } from "@mui/material";
import TestimonialSlider from "./testimonial";
import Tagline from "./Tagline";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Archwell from "./assets/images/Client logos/Archwell.png";
import Avanze from "./assets/images/Client logos/Avanze.png";
import Berkower from "./assets/images/Client logos/Berkower.png";
import BOAT from "./assets/images/Client logos/BOAT.png";
import Diligent from "./assets/images/Client logos/Diligent.png";
import JioSaavn from "./assets/images/Client logos/JioSaavn.png";
import KIC from "./assets/images/Client logos/KIC.png";
import MSRDC from "./assets/images/Client logos/MSRDC.png";
import SourcePhotonics from "./assets/images/Client logos/Source Photonics.png";
import Sproxil from "./assets/images/Client logos/Sproxil.png";
import Univar from "./assets/images/Client logos/Univar.png";
import Washburn from "./assets/images/Client logos/Washburn.png";
import CardList from "./component/NewProductCard/CardList";
import CardRow from "./component/NewProductCard/CardRow";
import ProductComponent from "./component/ProductComponent/ProductComponent";
import { Carousel } from "react-bootstrap";

const Home = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "none" }}
        onClick={onClick}
      />
    );
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "none" }}
        onClick={onClick}
      />
    );
  }

  return (
    <>
     <style>{`
.unique-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.unique-row {
  display: flex;
  flex-wrap: wrap;
}

.unique-col-md-6 {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 30px;
}

.unique-display-4 {
  font-size: 2.5rem;
}

.unique-font-weight-bold {
  font-weight: 700;
}

.mt-3 {
  margin-top: 1rem;
}

.mt-4 {
  margin-top: 1.5rem;
}

.unique-btn {
  display: inline-block;
  font-weight: 400;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-color: #007bff;
  border: 1px solid #007bff;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.unique-btn:hover {
  background-color: #0056b3;
  border-color: #004085;
}

.unique-img-fluid {
  max-width: 100%;
  height: auto;
}

.unique-rounded {
  border-radius: 0.25rem;
}

.text-center {
  text-align: center;
}

.my-5 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

/* Custom font size */
.unique-display-4-custom {
  font-size: 36px !important;
}

.unique-text-custom {
  font-size: 20px !important;
}


@media (max-width: 768px) {
  .unique-col-md-6 {
     flex: 0 0 100% !important;
    max-width: 100% !important;
    padding: 10px;
  }

  /* Ensure text section comes first on mobile */
  .unique-row {
    flex-direction: column;
  }

  .unique-display-4 {
    font-size: 24px;
  }

  .unique-text-custom {
    font-size: 18px;
  }

  .mt-4 {
    margin-top: 1rem;
  }

  .text-center img {
    width: 100%;
    height: auto;
  }
}
`}</style>
      <div
        className="banner-wrapper"
        style={{ position: "relative", height: "500px" }}
      >
        <video
          className="banner-video"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
          src={videoBackground}
          autoPlay
          loop
          muted
        />
        <div
          className="banner-overlay"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <div className="container-fluid trdBannar h-100">
            <div className="row h-100 d-flex align-items-center">
              <div className="col-lg-6">
                <div className="banner-content text-white pl-3">
                  <h1
                    className="title "
                    style={{
                      animation: "fadeInLeft 0.5s",
                      animationDelay: "0.5s",
                    }}
                  >
                    <a style={{ fontSize: "40px", fontWeight: "bold" }}>
                      {" "}
                      Financial Accounting Solutions{" "}
                    </a>
                    <br />
                  </h1>
                  <li
                    className="pt-3"
                    style={{
                      listStyleType: "none",
                      animation: "fadeInLeft 1.3s",
                      animationDelay: "1.3s",
                    }}
                  >
                    <a> A course for financial success worldwide. </a>
                  </li>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="unique-container my-5">
  <div className="unique-row align-items-center p-4">
    <div className="unique-col-md-6">
      <h1 className="unique-display-4 unique-font-weight-bold unique-display-4-custom"
       style={{ color: "#072f5f" }}
      >
        Empowering Global Excellence
      </h1>
      <h3 className="unique-font-weight-bold unique-text-custom"
       style={{ color: "#072f5f" }}
      >
        Boston Financial Advisory Group 
      </h3>
      <p className="mt-1">
        Boston Financial Advisory Group stands as a leader in professional
        services. Global alliance of
        carefully selected, like-minded firms, we are committed to
        excellence and client-centric solutions. Boston network fosters
        a unique blend of independence and collaboration, enabling us to
        deliver exceptional service while maintaining our individual
        strengths and expertise. Our partnership ensures that you receive
        unparalleled support and innovative solutions tailored to your
        specific needs.
      </p>

      <Link to="/whybfg" style={{ textDecoration: "none" }}>
        <Button
          className="buttonUnique"
          variant="contained"
          color="primary"
          style={{ backgroundColor: "#072f5f" }}
        >
          Read More
        </Button>
      </Link>
    </div>
    <div className="unique-col-md-6 text-center ">
      <Carousel interval={2000} fade>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={aboutImage}
            alt="First slide"
            style={{ width: "100%", height: "350px" }}
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={taxabt}
            alt="Second slide"
            style={{ width: "555px", height: "350px" }}
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={cpaabt}
            alt="Third slide"
            style={{ width: "100%", height: "350px" }}
          />
        </Carousel.Item>
      </Carousel>
    </div>
  </div>
</div>

      <FlipCardRow />
      {/* <Service /> */}
      {/* <VissionMisson /> */}
      <Visionmissiongoal/>
      {/* <CardRow/> */}
      {/* <ProductComponent/> */}
      <ProductComponent />
      {/* <ProductPage/> */}
      <br />
      <br />
      <CaseStudy />
      <OurTeam />
      <br />
      <br />
      <BlogTwo />
      {/* <BlogOne /> */}
      {/* <TestimonialSection2 /> */}
      {/* <BlogTwo/> */}

      <div className="container pt-5 pb-5 mt-40 mb-40">
        <div className="row">
          <div className="col-lg-12">
            <div className=" d-flex justify-content-center align-items-center ">
              <h3
                className="text-center"
                style={{ fontWeight: "bold", color: "#072f5f" }}
              >
                OUR CLIENTS
              </h3>
            </div>
            <Slider {...settings} style={{ width: "100%", height: "100%" }}>
              <div className="item">
                <img
                  src={Archwell}
                  alt="Archwell"
                  className="img-fluid clientImg"
                  style={{ width: "10rem" }}
                />
              </div>
              <div className="item">
                <img
                  src={Avanze}
                  alt="Avanze"
                  className="img-fluid clientImg"
                  style={{ width: "10rem" }}
                />
              </div>
              <div className="item">
                <img
                  src={Berkower}
                  alt="Berkower"
                  className="img-fluid clientImg"
                  style={{ width: "8rem" }}
                />
              </div>
              <div className="item">
                <img
                  src={BOAT}
                  alt="BOAT"
                  className="img-fluid clientImg"
                  style={{ width: "8rem" }}
                />
              </div>
              <div className="item">
                <img
                  src={Diligent}
                  alt="Diligent"
                  className="img-fluid clientImg"
                  style={{ width: "8rem" }}
                />
              </div>
              <div className="item">
                <img
                  src={JioSaavn}
                  alt="JioSaavn"
                  className="img-fluid clientImg"
                  style={{ maxWidth: "10rem" }}
                />
              </div>
              <div className="item">
                <img
                  src={KIC}
                  alt="KIC"
                  className="img-fluid clientImg"
                  style={{ width: "8rem" }}
                />
              </div>
              <div className="item">
                <img
                  src={MSRDC}
                  alt="MSRDC"
                  className="img-fluid clientImg"
                  style={{ width: "3rem" }}
                />
              </div>
              <div className="item">
                <img
                  src={SourcePhotonics}
                  alt="SourcePhotonics"
                  className="img-fluid clientImg"
                  style={{ width: "8rem" }}
                />
              </div>
              <div className="item">
                <img
                  src={Sproxil}
                  alt="Sproxil"
                  className="img-fluid clientImg"
                  style={{ width: "10rem" }}
                />
              </div>
              <div className="item">
                <img
                  src={Univar}
                  alt="Univar"
                  className="img-fluid clientImg"
                  style={{ width: "10rem" }}
                />
              </div>
              <div className="item">
                <img
                  src={Washburn}
                  alt="Washburn"
                  className="img-fluid clientImg"
                  style={{ width: "7rem" }}
                />
              </div>
            </Slider>
          </div>
        </div>
      </div>



      <TestimonialSlider />
      <br />
      <ContactForm />
      <br />
      <Tagline />
    </>
  );
};

export default Home;
