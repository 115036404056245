import React from "react";
import Ci from "../../assets/images/Ci.png";
import Ei from "../../assets/images/Ei.png";
import Ai from "../../assets/images/Ai.png";
import { Link } from 'react-router-dom';

const ProductComponent = () => {
  const products = [
    {
      title: "Compliance Inbox",
      description:
        "An intuitive solution developed to simplify compliance management across the organization.",
      type: "compliance",
      imageUrl: Ci,
      link: "/product" 
    },
    {
      title: "Expense Inbox",
      description:
        "A highly scalable, out-of-the-box expense reporting solution that delivers powerful insights into company and departmental spending through automated data analysis.",
      type: "expense",
      imageUrl: Ei,
      link: "/product" 
    },
    {
      title: "Analytics Inbox",
      description:
        "Provides information at the click of a button with 24/7 availability from anywhere. Offers a bird's-eye view of the financial position of the enterprise.",
      type: "analytics",
      imageUrl: Ai,
      link: "/product" 
    },
  ];

  return (
    <div className="product-container">
      <style jsx>{`
        .product-container {
          text-align: center;
          font-family: Arial, sans-serif;
          padding: 20px;
          background-color: #f4f4f9;
        }

        .intro-text {
          margin-bottom: 40px;
        }

        .intro-text h1 {
          font-size: 2em;
          color: #333;
        }

        .intro-text p {
          font-size: 1.1em;
          color: #666;
          max-width: 800px;
          margin: 0 auto;
        }

        .product-list {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
        }

        .product-card {
          border: 1px solid #ddd;
          border-radius: 10px;
          padding: 20px;
          margin: 20px;
          max-width: 270px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          transition: transform 0.2s, box-shadow 0.2s;
          background-color: #fff;
          text-align: center;
        }

        .product-card:hover {
          transform: translateY(-10px);
          box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        }

        .product-card h2 {
          margin: 0.5em 0;
          font-size: 1.5em;
          color: #333;
        }

        .product-card h3 {
          margin: 0.5em 0;
          font-size: 1.2em;
          color: #666;
        }

        .product-card p {
          font-size: 1em;
          color: #444;
        }

        /* Image section styling */
        .product-card-image {
          margin-bottom: 20px;
        }

        .product-card-image img {
          max-width: 100%;
          height: auto;
          border-radius: 8px;
        }

        /* Specific styles for each type of product card */
        .product-card.compliance {
          border: 2px solid #4caf50;
          background-color: #fff;
        }

        .product-card.compliance h2 {
          color: #2e7d32;
        }

        .product-card.compliance h3 {
          color: #388e3c;
        }

        .product-card.expense {
          border: 2px solid #4caf50;
          background-color: #fff;
        }

        .product-card.expense h2 {
          color: #2e7d32;
        }

        .product-card.expense h3 {
          color: #f57c00;
        }

        .product-card.analytics {
          border: 2px solid #4caf50;
          background-color: #fff;
        }

        .product-card.analytics h2 {
          color: #2e7d32;
        }

        .product-card.analytics h3 {
          color: #1e88e5;
        }
      `}</style>

      {/* <div className="intro-text">
        <h1>TAKE A LOOK AT OUR PRODUCTS</h1>
        <p>We use an optimum blend of people and technology. We make efficient use of technology to handle routine financial activities.</p>
      </div> */}
      <div className="row text-center justify-content-center ">
        <div className="pb-2">
          <h3
            className="producth3"
            style={{ fontWeight: "bold", paddingTop: "17px", color: "#072f5f" }}
          >
            TAKE A LOOK AT OUR PRODUCTS
          </h3>
        </div>
        <div className="justify-content-center">
          <p className="text-center" style={{ color: "black" }}>
            We use an optimum blend of people and technology. we make efficient
            use of technology to handle routine financial activities.
          </p>
        </div>
      </div>
      <div className="product-list">
        {products.map((product, index) => (
          <Link to={product.link} key={index} className={`product-card ${product.type}`} style={{ textDecoration: 'none' }}>
            <div className="product-card-image">
              <img src={product.imageUrl} alt={product.title} />
            </div>
            <h2 style={{fontWeight:600, textAlign:'center'}}>{product.title}</h2>
            <h3>{product.code}</h3>
            <p style={{textAlign:'center'}}>{product.description}</p>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default ProductComponent;
