import React from 'react';
import { Carousel } from 'react-bootstrap';
import Archwell from '../../../assets/images/Client logos/Archwell.png';
import Avanze from '../../../assets/images/Client logos/Avanze.png';
import Berkower from '../../../assets/images/Client logos/Berkower.png';
import BOAT from '../../../assets/images/Client logos/BOAT.png';
import Diligent from '../../../assets/images/Client logos/Diligent.png';
import JioSaavn from '../../../assets/images/Client logos/JioSaavn.png';
import KIC from '../../../assets/images/Client logos/KIC.png';
import MSRDC from '../../../assets/images/Client logos/MSRDC.png';
import SourcePhotonics from '../../../assets/images/Client logos/Source Photonics.png';
import Sproxil from '../../../assets/images/Client logos/Sproxil.png';
import Univar from '../../../assets/images/Client logos/Univar.png';
import Washburn from '../../../assets/images/Client logos/Washburn.png';
import whatWeAreShape from '../../../assets/images/what-we-are-shape-2.png';

const TestimonialsAndClients = () => {
  return (
    <div className="">
      <style>{`
        .partner-logo {
          width: 100%;
          height: auto;
          max-width: 150px;
          max-height: 100px;
        }
        
        h1 {
          font-size: 48px !important;
          border-bottom: 3px solid white;
          display: inline-block;
        }
        
        img{
          width: 50%;
          aspect-ratio: 3/2;
          object-fit: contain;
        }
        
        .border{
          width: 10rem;
          height: 5rem;
        }
        
        .carousel-inner .card-text{
          color: #000000 !important;
        }
        
        @media (max-width: 956px) {
          .d-flex {
            flex-direction: column;
          }
          .border{
            min-width: 20rem;
            height: 7rem;
          }
        }
        
        h4 {
          font-size: 18px;
        }
        
        h3 {
          font-size: 28px;
        }
        
        span {
          font-size: 16px !important;
        }
        
        p{
          font-size: 16px;
        }
        
        @media (max-width: 426px) {
          .carousel-item div p{
            font-size: 15px !important;
          }
        
          h2{
            font-size: 1.5rem !important;
          }
        
          h3{
            font-size: 1.3rem !important;
          }
        
          h1{
            font-size: 2.5rem !important;
            padding: 0;
          }
        }
        
        @media(max-width:574px){
          .mTop{
            margin-top: 80px;
          }
          p{
            font-size: 18px;
          }
        }
        
        @media(min-width:576px){
          .mTop{
            margin-top: 80px;
          }
        }
        
        @media(min-width:976px){
          .mTop{
            margin-top: 140px;
          }
        }
      `}</style>
      <section
        className="pt-20 pb-30 mt-50"
        style={{
          position: 'relative',
          backgroundColor: '#002249',
          overflow: 'hidden'
        }}
      >
        <img
          src={whatWeAreShape}
          alt="shape"
          style={{
            position: 'absolute',
            transform: 'rotate(180deg)',
            top: 0,
            overflow: 'clip',
            left: '-30%',
            width: '75%'
          }}
        />
        <img
          src={whatWeAreShape}
          alt="shape"
          style={{
            position: 'absolute',
            overflow: 'clip',
            right: '-30%',
            width: '75%',
            bottom: '-10%'
          }}
        />

        <div className="container pt-40" style={{ minHeight: '18rem' }}>
          <div className="row text-center" style={{ marginBottom: '0.5rem' }}>
            <div className="col-lg-12">
              <h3
                className="title p-0 m-0"
                style={{
                  borderBottom: '3px solid #072F5F',
                  display: 'inline',
                  color: 'white', 
                  fontWeight: 'bold'
                }}
              >
                TESTIMONIAL
              </h3>
            </div>
          </div>
          <div className="container col-lg-12 d-flex flex-column justify-content-center align-items-center pt-4">
            <Carousel id="myCarousel" interval={5000}>
              <Carousel.Item>
                <div className="container d-flex flex-column justify-content-center align-items-center">
                  <p
                    className="card-title text-center text-white m-0 p-0 font-weight-bolder"
                    style={{ fontSize: '18px' }}
                  >
                    CEO
                  </p>
                  <span className="text-center text-white" style={{ fontSize: '16px' }}>
                    CEO of Leading Educational Institute in Southern India
                  </span>
                  <p
                    className="text-center text-white pt-3"
                    style={{
                      lineHeight: 'normal',
                      textAlign: 'justify',
                      color: 'white'
                    }}
                  >
                    Boston Financial has been our Outsourced Accounting & Regulatory
                    compliance partner from the beginning. Our association is more
                    than a decade long and I am still pretty much impressed by their
                    adherence to service levels. They have proactively solved issues
                    impacting the business and have been consistently providing
                    inputs on key performance indicators. This has helped me in
                    running the business more effectively. The problem solving
                    ability of the team continues to amaze.
                  </p>
                </div>
              </Carousel.Item>
              {/* Repeat Carousel.Item components for each testimonial */}
              {/* ... */}
            </Carousel>
          </div>
        </div>
      </section>

      <div className="container col-lg-12 pt-80 pb-80 d-flex flex-column justify-content-center align-items-center">
        <div className="d-flex justify-content-center align-items-center">
          <h3
            className="title p-0 m-0"
            style={{ borderBottom: '3px solid #072F5F', display: 'inline', fontWeight: 'bold', color: '#072F5F' }}
          >
            OUR ESTEEMED CLIENTS
          </h3>
        </div>
        <div className="pt-5 d-flex flex-column justify-content-center align-items-center">
          <div className="d-flex justify-content-center align-items-center">
            <div className="border p-0 m-0 d-flex justify-content-center align-items-center">
              <img src={Archwell} alt="Archwell" />
            </div>
            <div className="border d-flex justify-content-center align-items-center">
              <img src={Avanze} alt="Avanze" />
            </div>
            <div className="border d-flex justify-content-center align-items-center">
              <img src={Berkower} alt="Berkower" />
            </div>
            <div className="border d-flex justify-content-center align-items-center">
              <img src={BOAT} alt="BOAT" />
            </div>
            <div className="border d-flex justify-content-center align-items-center">
              <img src={Diligent} alt="Diligent" />
            </div>
            <div className="border d-flex justify-content-center align-items-center">
              <img src={JioSaavn} alt="JioSaavn" />
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <div className="border d-flex justify-content-center align-items-center">
              <img src={KIC} alt="KIC" />
            </div>
            <div className="border d-flex justify-content-center align-items-center">
              <img src={MSRDC} alt="MSRDC" />
            </div>
            <div className="border d-flex justify-content-center align-items-center">
              <img src={SourcePhotonics} alt="Source Photonics" />
            </div>
            <div className="border d-flex justify-content-center align-items-center">
              <img src={Sproxil} alt="Sproxil" />
            </div>
            <div className="border d-flex justify-content-center align-items-center">
              <img src={Univar} alt="Univar" />
            </div>
            <div className="border d-flex justify-content-center align-items-center">
              <img src={Washburn} alt="Washburn" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialsAndClients;
