import React, { useState } from 'react';
import { Modal, Button, TextField, Box, Checkbox, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Typography, Container, IconButton } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import Lottie from 'react-lottie';
import thankyouAnimation from '../../assets/images/NewIcon/Animation - 1720520813759.json';
import { FaUser, FaEnvelope, FaPhone, FaPen } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import bfglogo from '../../assets/images/BFAG_logo.png'
import CloseIcon from '@mui/icons-material/Close';

export function Model({ show, handleClose }) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset
  } = useForm();
  const [submitted, setSubmitted] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);

  const onSubmit = (data) => {
    console.log(data);
    setTimeout(() => {
      setSubmitted(true);
      setTimeout(() => {
        setSubmitted(false);
        setShowThankYou(true); // Show the thank you modal
        reset(); // Reset the form fields
      }, 1000);
    }, 1000);
  };

  const handleCloseThankYou = () => {
    setShowThankYou(false);
    handleClose();
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: thankyouAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const countryCodes = [
    { code: "+1", name: "United States" },
    { code: "+44", name: "United Kingdom" },
    { code: "+91", name: "India" },
    { code: "+971", name: "UAE"},
    { code: "+61", name: "Australia" },
    { code: "+49", name: "Germany" },
    { code: "+33", name: "France" },
    { code: "+81", name: "Japan" },
    { code: "+86", name: "China" },
    { code: "+55", name: "Brazil" },
    // Add more country codes as needed
  ];

  return (
    <>
      <Modal open={show && !showThankYou} onClose={handleClose}>
        <Container
          maxWidth="sm"
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 1,
            height: 500,
            overflow: 'auto'
          }}
        >
          <IconButton 
            onClick={handleClose} 
            sx={{
              position: 'absolute', 
              top: 8, 
              right: 8, 
              zIndex: 1
            }}>
            <CloseIcon />
          </IconButton>

          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '16px' }}>
            <Link to="/" style={{ display: 'block' }}>
              <img src={bfglogo} alt="Boston" style={{ maxWidth: '200px', height: 'auto' }} />
            </Link>
          </Box>
          <Typography variant="h6" component="h2" textAlign="center" sx={{ fontWeight: 'bold', color: '#072f5f' }}>
            Request Info
          </Typography>
          <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 2 }}>
            <TextField
              fullWidth
              variant="outlined"
              margin="dense"
              label="Full Name"
              {...register('fullName', { required: 'Full Name is required' })}
              error={!!errors.fullName}
              helperText={errors.fullName?.message}
              InputProps={{
                endAdornment: <FaUser />,
                style: { fontSize: '14px' }
              }}
              InputLabelProps={{ style: { fontSize: '14px' } }}
            />
            <TextField
              fullWidth
              variant="outlined"
              margin="dense"
              label="Email"
              type="email"
              {...register('email', { required: 'Email is required' })}
              error={!!errors.email}
              helperText={errors.email?.message}
              InputProps={{
                endAdornment: <FaEnvelope />,
                style: { fontSize: '14px' }
              }}
              InputLabelProps={{ style: { fontSize: '14px' } }}
            />
            <Box display="flex" className='mt-2' alignItems="center" gap="1rem" marginBottom="16px">
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="country-code-label">Code</InputLabel>
                <Controller
                  name="countryCode"
                  control={control}
                  defaultValue="+91"
                  rules={{ required: "Country code is required" }}
                  render={({ field }) => (
                    <Select
                      labelId="country-code-label"
                      label="Code"
                      {...field}
                    >
                      {countryCodes.map((country) => (
                        <MenuItem key={country.code} value={country.code}>
                          {country.code} ({country.name})
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
              <TextField
                fullWidth
                variant="outlined"
                margin="dense"
                label="Phone Number"
                className='mt-1'
                {...register('phone', { 
                  required: 'Phone Number is required',
                  pattern: {
                    value: /^[0-9]{10,14}$/,
                    message: "Invalid phone number"
                  }
                })}
                error={!!errors.phone}
                helperText={errors.phone?.message}
                InputProps={{
                  endAdornment: <FaPhone />,
                  style: { fontSize: '14px' }
                }}
                InputLabelProps={{ style: { fontSize: '14px' } }}
              />
            </Box>
            <TextField
              fullWidth
              variant="outlined"
              margin="dense"
              label="Subject"
              {...register('subject', { required: 'Subject is required' })}
              error={!!errors.subject}
              helperText={errors.subject?.message}
              InputProps={{
                endAdornment: <FaPen />,
                style: { fontSize: '14px' }
              }}
              InputLabelProps={{ style: { fontSize: '14px' } }}
            />
            <TextField
              fullWidth
              variant="outlined"
              margin="dense"
              label="Your Message"
              multiline
              rows={3}
              {...register('message', { required: 'Message is required' })}
              error={!!errors.message}
              helperText={errors.message?.message}
              InputProps={{
                endAdornment: <FaPen />,
                style: { fontSize: '14px' }
              }}
              InputLabelProps={{ style: { fontSize: '14px' } }}
            />
            <FormControlLabel
              control={<Checkbox {...register('terms', { required: 'You must accept the terms and conditions' })} />}
              label={
                <span style={{ fontSize: '14px' }}>
                  I accept the{' '}
                  <Link to="/privacypolicy" style={{textDecoration:'none'}}>
                    terms and conditions
                  </Link>
                </span>
              }
            />
            {errors.terms && <Typography color="error" variant="body2">{errors.terms.message}</Typography>}
            <Box textAlign="center" mt={2}>
              <Button type="submit" variant="contained" sx={{
                marginTop: "16px",
                backgroundColor: "rgb(7, 47, 95)",
                color: "white",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}>
                Send Message
              </Button>
            </Box>
          </Box>
        </Container>
      </Modal>

      <Modal open={showThankYou} onClose={handleCloseThankYou}>
        <Container
          maxWidth="xs"
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 1,
            textAlign: 'center',
          }}
        >
          <Typography variant="h6" component="h2">
            Thank You!
          </Typography>
          <Box sx={{ my: 2 }}>
            <Lottie options={defaultOptions} height={200} width={200} />
          </Box>
          <Typography>Thank You For Submitting Your Information</Typography>
        </Container>
      </Modal>
    </>
  );
}

export default Model;
