import React from 'react';
import { Link } from 'react-router-dom';
import rectangle1 from '../../../assets/images/case-studies/Rectangle1.png';
import rectangle2 from '../../../assets/images/case-studies/Rectangle 2.png';
import rectangle3 from '../../../assets/images/case-studies/Rectangle 3.png';
import rectangle4 from '../../../assets/images/case-studies/Rectangle4.png';

const CaseStudies = () => {
  return (
    <div className="pt-20 pb-20">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="section-title text-center mb-5">
              <h2 className="p-0 m-0" style={{ textDecorationColor: '#072f5f', fontWeight: 'bold', color: '#072f5f' }}>
                READ OUR RECENT CASE STUDIES
              </h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-3 mb-3">
            <Link to="/itcompanycasestudy" className="case-card-link">
              <div className="case-card">
                <img src={rectangle1} alt="IT Company Case Study" />
                <div className="gradient"></div>
                <div className="text">
                  <p>IT Company Case Study</p>
                </div>
                <i className="fas fa-arrow-right case-icon"></i>
              </div>
            </Link>
          </div>
          <div className="col-md-6 col-lg-3 mb-3">
            <Link to="/lawfirmcasestudy" className="case-card-link">
              <div className="case-card">
                <img src={rectangle2} alt="Law Firm Case Study" />
                <div className="gradient"></div>
                <div className="text">
                  <p>Law Firm Case Study</p>
                </div>
                <i className="fas fa-arrow-right case-icon"></i>
              </div>
            </Link>
          </div>
          <div className="col-md-6 col-lg-3 mb-3">
            <Link to="/CPAcasestudy" className="case-card-link">
              <div className="case-card">
                <img src={rectangle3} alt="CPA Case Study" />
                <div className="gradient"></div>
                <div className="text">
                  <p>CPA Case Study</p>
                </div>
                <i className="fas fa-arrow-right case-icon"></i>
              </div>
            </Link>
          </div>
          <div className="col-md-6 col-lg-3 mb-3">
            <Link to="/vcfirmcasestudy" className="case-card-link">
              <div className="case-card">
                <img src={rectangle4} alt="VC Firm Case Study" />
                <div className="gradient"></div>
                <div className="text">
                  <p>VC Firm Case Study</p>
                </div>
                <i className="fas fa-arrow-right case-icon"></i>
              </div>
            </Link>
          </div>
        </div>
      </div>

      {/* Internal CSS */}
      <style>
        {`
        .case-card {
          position: relative;
          overflow: hidden;
        }
        
        .case-card img {
          object-fit: cover;
          height: 13rem;
          width: 22rem;
        }
        
        .case-card .gradient {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(to top, rgba(7, 47, 95, 0.8), transparent);
          opacity: 0;
          transition: opacity 0.3s ease;
        }
        
        .case-card:hover .gradient {
          opacity: 1;
        }
        
        .case-card .text {
          position: absolute;
          bottom: 20px;
          left: 20px;
          z-index: 1;
          color: white;
          width: calc(100% - 40px);
          transition: bottom 0.3s ease;
        }
        
        .case-card:hover .text {
          bottom: 40px;
        }
        
        .case-card .text p {
          font-size: 1.2rem;
          margin: 0;
          padding: 0;
        }
        `}
      </style>
    </div>
  );
};

export default CaseStudies;
